import * as React from 'react';
import {FC} from 'react';
import {storage} from "utils";
import {RootRoutes} from "enums";
import {useNavigate} from "react-router-dom";

interface Props {
    userId?: string;
    isSuperAdmin?: boolean;
    onIdle: () => void
}

export const IdleMonitor: FC<Props> = ({userId, isSuperAdmin, onIdle}) => {
    let idleRef = React.useRef(0).current;

    const navigate = useNavigate();

    React.useEffect(() => {
        if (!userId) {
            return;
        }

        let idleInterval = setInterval(timerIncrement, 1000); // 1 minute

        function timerIncrement() {
            idleRef += 1;
            const minIdle = isSuperAdmin ? 10 : 3;
            if (idleRef > (60 * minIdle)) {
                onIdle();
                clearInterval(idleInterval);
                storage.clearAll();
                navigate(RootRoutes.Login);
                window.location.reload();
            }
        }

        function resetIdleRef() {
            idleRef = 0;
        }

        document.body.addEventListener('mousemove', resetIdleRef);
        document.body.addEventListener('keypress', resetIdleRef);

        return () => {
            document.body.removeEventListener('mousemove', resetIdleRef);
            document.body.removeEventListener('keypress', resetIdleRef);
            clearInterval(idleInterval);
        };
    }, [onIdle, isSuperAdmin, userId]);

    return null;
};




