import React from 'react';
import {settingsStore} from "stores";
import {observer} from "mobx-react";
import Select from 'react-select'

export const ModerationCountry = observer(() => {
    if (!settingsStore.countries.length) {
        return null
    }


    return <div className="row mb-2 w-100">
        <div className="col-2 col-md-9"/>
        <div className="col-10 col-md-3">
            <Select options={settingsStore.countriesSelect}
                    defaultValue={settingsStore.selectedCountry ? {
                        label: `${settingsStore.selectedCountry.flag} ${settingsStore.selectedCountry.name}`,
                        value: settingsStore.selectedCountry.countryCode
                    } : undefined}
                    isOptionSelected={i => i.value === settingsStore.selectedCountry?.countryCode}
                    placeholder="Все страны"
                    isClearable={true}
                    onChange={i => settingsStore.selectCountry(i?.value)}
            />
        </div>
    </div>
})

