import axios, {AxiosRequestConfig} from 'axios';
import toast from "react-hot-toast";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";

export const axiosInstance = axios.create({
    baseURL: 'https://api.instinctdate.app',
});

export const request = async <T>(config: AxiosRequestConfig) => {
    try {
        const response = await axiosInstance.request<T>(config).catch();
        return response.data;
    } catch (error: any) {
        LogRocket.error(error);
        Sentry.captureException(error);
        toast.error(`${config.url}\n ${error.message}`)
    }
};
