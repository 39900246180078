import {Button, Form, Modal} from 'react-bootstrap';
import {FC, useContext, useState} from 'react';
import {Apps, RootRoutes} from 'enums';
import {AuthContext} from 'contexts';
import {useNavigate} from "react-router-dom";

interface AppSwitchProps {
    selectedApp?: Apps;
    isVisible: boolean;
    onClose: () => void;
}

export const AppSwitch: FC<AppSwitchProps> = ({
                                                  isVisible,
                                                  onClose,
                                                  selectedApp,
                                              }) => {
    const {signin} = useContext(AuthContext);

    const [token, setToken] = useState('');
    const [isTest, setIsTest] = useState(false);

    const navigate = useNavigate()

    const handleSwitchSave = async () => {
        if (!selectedApp) {
            alert("No app selected")
            return
        }
        await signin(token, selectedApp, isTest);
        setToken('')
        onClose();
        navigate(RootRoutes.Moderation)
    };

    return (
        <Modal show={isVisible} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Поменять админастратора на {selectedApp}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className="form-check mb-3">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                               onChange={() => setIsTest(prev => !prev)}/>
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Тестовая среда
                        </label>
                    </div>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Токен</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Токен"
                            value={token}
                            onChange={e => setToken(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Отмена
                </Button>
                <Button variant="primary" onClick={handleSwitchSave}>
                    Поменять
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
