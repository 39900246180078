import React, {FC, useContext} from 'react';
import {Button} from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {AuthContext} from "contexts";
import {isDeleteProfileAndGiftsSupported} from "enums";
import {usersService} from "services";

const MySwal = withReactContent(Swal)

interface Props {
    userId: string;
    userName: string;
    onReload: () => Promise<void>;
}

export const ProfileDeleteButton: FC<Props> = ({userId, userName, onReload}) => {
    const {user: myUser, env} = useContext(AuthContext);
    const onDelete = async () => {
        const result = await MySwal.fire({
            title: `Удалить профиль ${userName}?`,
            text: "Это действие нельзя отменить",
            confirmButtonText: 'Да',
            confirmButtonColor: '#D9292E',
            cancelButtonText: 'Нет',
            showCancelButton: true,
            showConfirmButton: true
        })
        if (result.isConfirmed && env) {
            await usersService.delete(userId, env);
            onReload();
        }
    }

    const canBeDeleted = env && isDeleteProfileAndGiftsSupported(env) && myUser?.isSuperAdmin;

    if (!canBeDeleted) {
        return null
    }

    return <Button variant={'outline-danger'} className={'mx-1'} onClick={onDelete}>Удалить профиль</Button>;
}