import {Apps} from "../enums";
import {request} from "./request";
import {
    convertToSearchBloomToModerationUser,
    convertToSearchFlavaToModerationUser,
    convertToSearchInstinctToModerationUser,
    convertToSearchSparkToModerationUser,
    toDate
} from "./payloads";
import {VerificationUser, VerificationUserPayload} from "interfaces";
import {createUserIdParam, EnvSetup, getSupportUrl} from "enums/Envs";
import {VerificationRejectReasons} from "enums/VerificationRejectReasons";

export const verificationsService = {
    getPage: async (env: EnvSetup, skip: number) => {
        let users: VerificationUser[] | undefined = []
        if (env.app === Apps.Bloom) {
            const response = await request<{ verification: VerificationUserPayload[] }>({
                method: 'get',
                url: `${getSupportUrl(env)}/verifications?skip=${skip}`,
            });

            users = response?.verification.map(v => ({
                verificationType: v.verificationType,
                isPremium: v.isPremium,
                previousEndDate: v.previousEndDate ? new Date(v.previousEndDate) : null,
                startDate: v.startDate ? new Date(v.startDate) : null,
                photo: (v as any).photo.url,
                user: convertToSearchBloomToModerationUser(v.user as any)
            } as VerificationUser));
        } else if (env.app === Apps.Spark) {
            const response = await request<{ verification: VerificationUser[] }>({
                method: 'get',
                url: `${getSupportUrl(env)}/verifications?skip=${skip}`,
            });
            users = response?.verification.map(u => ({
                ...u,
                photo: (u as any).photo.url,
                user: convertToSearchSparkToModerationUser(u.user as any)
            }));
        } else if (env.app === Apps.Instinct) {
            const response = await request<{ verification: VerificationUser[] }>({
                method: 'get',
                url: `${getSupportUrl(env)}/verifications?skip=${skip}`,
            });
            users = response?.verification.map(u => ({
                ...u,
                startDate: toDate(u.startDate.toString(), Apps.Instinct),
                previousEndDate: u.previousEndDate ? toDate(u.previousEndDate.toString(), Apps.Instinct) : undefined,
                photo: (u as any).photo.url,
                user: convertToSearchInstinctToModerationUser(u.user as any)
            } as VerificationUser));
        } else if (env.app === Apps.Flava) {
            const response = await request<{ verification: VerificationUserPayload[] }>({
                method: 'get',
                url: `${getSupportUrl(env)}/verifications?skip=${skip}`,
            });
            users = response?.verification.map(v => ({
                verificationType: v.verificationType,
                isPremium: v.isPremium,
                previousEndDate: v.previousEndDate ? new Date(v.previousEndDate) : null,
                startDate: v.startDate ? new Date(v.startDate) : null,
                photo: (v as any).photo.url,
                user: convertToSearchFlavaToModerationUser(v.user as any)
            } as VerificationUser));
        } else {
            alert("verificationsService.getPage Not implemented!")
        }

        users?.forEach((user) => {
            const img = new Image();
            img.src = user.photo;

            user.user.photos?.forEach(photo => {
                const img = new Image();
                img.src = photo.image[0].link;
            })
        });

        return users;
    },
    verify: async (env: EnvSetup, id: string, isVerified: boolean, reason?: VerificationRejectReasons) => {
        return await request({
            method: 'put',
            url: `${getSupportUrl(env)}/verification`,
            data: {...createUserIdParam(env, id), isVerified, denyReason: reason}
        });
    },
    force: async (env: EnvSetup, id: string) => {
        const url = `/${getSupportUrl(env)}/verification/force`
        return await request({
            method: 'put',
            url: url,
            data: createUserIdParam(env, id)
        });
    }
}