import React, {FC, useContext, useState} from "react";
import {Accordion, Button, Modal} from "react-bootstrap";
import {TbCloudDownload} from "react-icons/tb";
import {UserChats, usersService} from "services";
import {AuthContext} from "contexts";
import {ModerationUser} from "interfaces";
import {isModerationPackAvailable, UserStatuses} from "enums";
import {ModerationChat} from "components/ModerationUserCard/components/ModerationChats/ModerationChat";

interface Props {
    user: ModerationUser
}

export const ModerationDownloadData: FC<Props> = ({user}) => {
    const {env} = useContext(AuthContext);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [data, setData] = useState<UserChats[]>([]);

    const getChats = async () => {
        if (!user || !env) {
            return;
        }

        const newData = await usersService.getAllChats(user.id, env)
        if (newData) {
            setData(newData)
            setIsModalVisible(true)
        }
    }

    const downloadFile = () => {
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", user.name + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();


    }

    const downloadData = async () => {
        if (!user || !env) {
            return;
        }

        const newData = await usersService.downloadProfile(user.id, env)
        if (newData) {
            setData(newData.chats.map(c => ({
                status: UserStatuses.Archived,
                name: '',
                key: c.matchId,
                messages: (c.messages ?? c.chatMessages ?? c.signals)
                    .map(m => ({
                        message: m.message ?? m.content ?? m.subject,
                        date: m.date ?? m.time,
                        isMyMessage: m.isMyMessage
                    }))
            })))
            setIsModalVisible(true)
        }
    }

    const handleClose = () => {
        setData([])
        setIsModalVisible(false)
    }

    return <>
        <Button variant={'link'} className="mx-2 d-flex align-items-center text-black-50"
                onClick={isModerationPackAvailable(env) ? getChats : downloadData}>
            <TbCloudDownload size={16} className="mx-1"/>
            Чаты
        </Button>
        <Modal show={isModalVisible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Чаты</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {data?.map((chat, i) => {
                            const messages = chat.messages;
                            const matchKey = chat.key;
                            return <Accordion key={matchKey} className="mb-1">
                                <Accordion.Item eventKey={matchKey}>
                                    <Accordion.Header>Чат {i + 1} ({messages?.length ?? 0})</Accordion.Header>
                                    <Accordion.Body>
                                        {isModerationPackAvailable(env) &&
                                            <div className="fw-normal mb-3">
                                                Пользователь:{' '}
                                                <a href={`profile/${matchKey}`} target="_blank"
                                                   rel="noreferrer">{chat.name}</a> ({chat.status})
                                            </div>}
                                        <ModerationChat userName={user.name} messages={messages}/>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        }
                    )}
                    {data?.length === 0 && "У пользователя пока нет чатов"}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={downloadFile}>
                    Скачать все данные
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}