import React, {useState} from "react";
import {AbuseWord} from "services/abuseWordsService";
import {GenderName, Genders} from "enums";
import {ColDef, ColGroupDef} from 'ag-grid-community';
import {CustomCellRendererProps} from "ag-grid-react";
import {Button} from "react-bootstrap";
import {AbuseWordsTagFilter} from "pages/AbuseWords/filters/AbuseWordsTagFilter";
import {AbuseGenderFilter} from "pages/AbuseWords/filters/AbuseGenderFilter";
import {AbuseActionTypesFilter} from "pages/AbuseWords/filters/AbuseActionTypesFilter";
import {AbuseActionTypeTag} from "pages/AbuseWords/components/AbuseActionTypeTag";
import {AbusePatternTypeTag} from "pages/AbuseWords/components/AbusePatternTypeTag";
import {AbusePatternTypesFilter} from "pages/AbuseWords/filters/AbusePatternTypesFilter";
import {AbuseWordsLanguageFilter} from "pages/AbuseWords/filters/AbuseWordsLanguageFilter";


export const useAbuseWordsTable = (onEdit: (id: number) => void, onDelete: (id: number) => void) => {
    const ActionButtonsCell = (params: CustomCellRendererProps) => {
        return <div className="d-flex flex-row align-items-center h-100" style={{gap: 8}}>
            <Button size="sm" variant='secondary' onClick={() => onEdit(params.value)}>Редактировать</Button>
            <Button size="sm" variant='outline-danger' onClick={() => onDelete(params.value)}>Удалить</Button>
        </div>
    };

    const [columnDefs, setColumnDefs] = useState<(ColDef<AbuseWord> | ColGroupDef<AbuseWord>)[]>([
        {
            headerName: 'Тег',
            cellRenderer: (params: CustomCellRendererProps) => {
                return (
                    <span className="badge bg-dark">{params.data?.tag}</span>
                );
            },
            flex: 1.3,
            filter: AbuseWordsTagFilter,
        },
        {
            headerName: 'Язык',
            cellRenderer: (params: CustomCellRendererProps) => {
                return (
                    <span className="badge bg-secondary">{params.data?.language}</span>
                );
            },
            filter: AbuseWordsLanguageFilter,
            flex: 0.7,
        },
        {
            headerName: 'Название',
            field: 'ruleName',
            flex: 2,
            filter: true,
        },
        {
            headerName: 'Гендеры',
            valueGetter: (v) => {
                return v.data?.genders.sort((a, b) => +a - +b).map(g => GenderName[g.toString() as Genders]).filter(g => g)
            },
            filter: AbuseGenderFilter,
            flex: 1.5
        },
        {
            headerName: 'Паттерн',
            field: 'pattern',
            flex: 1,
            filter: true,
        },
        {
            headerName: 'Тип',
            field: 'patternType',
            cellRenderer: (params: CustomCellRendererProps) => {
                return <AbusePatternTypeTag patternType={params.value}/>
            },
            flex: 1,
            filter: AbusePatternTypesFilter,
        },
        {
            headerName: 'Действие',
            field: 'actionType',
            cellRenderer: (params: CustomCellRendererProps) => {
                return <AbuseActionTypeTag action={params.value}/>
            },
            flex: 1.2,
            filter: AbuseActionTypesFilter,
        },
        {
            headerName: 'Пользователей',
            field: 'stats.userCount',
        },
        {
            headerName: '% бана',
            valueGetter: (v) => {
                return v.data?.stats ? Math.round(((v.data?.stats?.bannedByReviewCount ?? 0) / (v.data?.stats?.userCount ?? 1)) * 100) : 0
            },
        },
        {
            headerName: 'Замечания',
            valueGetter: (v) => {
                const tags = [];
                if (v.data?.allowDelimiters) {
                    tags.push('Разделители')
                }
                if (v.data?.allowIgnoreCase) {
                    tags.push('Игнорировать регистр')
                }
                if (v.data?.allowMultiline) {
                    tags.push('Многострочный')
                }
                if (v.data?.allowSeparateWord) {
                    tags.push('Отдельное слово')
                }
                return tags.join(', ')
            },
            flex: 2
        },
        {
            field: 'id',
            headerName: "Действия",
            cellRenderer: ActionButtonsCell,
            flex: 2
        },
    ]);

    return {columnDefs}
}