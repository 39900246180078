import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "contexts";
import {AbuseStats, statsService} from "services";
import {CSVLink} from 'react-csv';

export const AbuseStatsPage = () => {
    const {env} = useContext(AuthContext);

    const [abuseStats, setAbuseStats] = useState<AbuseStats[]>([]);


    const reload = () => {
        env && statsService.getAbuse(env).then(as => setAbuseStats(as))
    }

    useEffect(() => {
        reload()
    }, [env]);

    return <div className="d-flex flex-column align-items-center w-100 mt-2">
        <div className="w-75 d-flex flex-column align-items-end">
            <div>
                <CSVLink data={abuseStats} filename={"abuse-stats.csv"} className="btn btn-primary">Скачать
                    данные</CSVLink>
            </div>
            <div className="overflow-auto mt-2 w-100" style={{height: '90vh'}}>
                <table className="table table-light table-hover">
                    <thead>
                    <tr>
                        <th>Категория</th>
                        <th>Пользователей</th>
                        <th>Заблокировано модераторами</th>
                        <th>Пропущено модераторами</th>
                        <th>Заблокировано после ревью</th>
                    </tr>
                    </thead>
                    <tbody>
                    {abuseStats.map(a => <tr key={a.category}>
                        <td>{a.category}</td>
                        <td>{a.userCount}</td>
                        <td>{a.bannedByReviewCount}</td>
                        <td>{a.deletedByReviewCount}</td>
                        <td>{a.bannedAfterReviewCount}</td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}