import {Outlet} from 'react-router-dom';
import {AppNavbar} from 'components';
import {useContext} from "react";
import {AuthContext} from "../contexts";

export const Layout = () => {
    const {user} = useContext(AuthContext);
    if (!user) {
        return <Outlet/>;
    }

    return (
        <>
            <AppNavbar/>
            <Outlet/>
        </>
    );
}
