import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {AdminStat, userStatsService} from "services";
import {Apps, Genders} from "enums";

class StatsStore {
    @observable
    admins: AdminStat[] = []

    @observable
    selectedAdmin: AdminStat | undefined

    @observable
    selectedGender: Genders | undefined

    constructor() {
        makeAutoObservable(this)
    }

    @computed
    get adminsSelect() {
        return this.admins?.map(c => ({value: c.userKey, label: `${c.name} (${c.reviewedUserCount})`}))
    }

    @action
    selectAdmin = (id?: string) => {
        this.selectedAdmin = id ? this.admins.find(c => c.userKey === id) : undefined;
    }

    @action
    selectGender = (id?: Genders) => {
        this.selectedGender = id;
    }

    loadAdmins = async (app: Apps) => {
        const allItems = await userStatsService.getAllAdmins(app);
        runInAction(() => {
            this.admins = allItems ?? [];
        });
    }
}

export const statsStore = new StatsStore();