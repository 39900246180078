import "bootswatch/dist/zephyr/bootstrap.min.css";
import 'react-photo-view/dist/react-photo-view.css';
import {AuthProvider} from 'contexts';
import {Router} from './Router';
import {Toaster} from "react-hot-toast";
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from "@sentry/react";
import {sessionReplayPlugin} from '@amplitude/plugin-session-replay-browser';
import {useEffect, useState} from "react";
import {AllCommunityModule, ModuleRegistry} from 'ag-grid-community';

// Register all Community features
ModuleRegistry.registerModules([AllCommunityModule]);

Sentry.init({
    dsn: "https://74dfe4fe7e55231ef95401924c5b2c5f@o4507132016787456.ingest.us.sentry.io/4508281289048064",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/admin\.brightou\.com/],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const sessionReplayTracking = sessionReplayPlugin();
amplitude.add(sessionReplayTracking);
amplitude.init('1a277387e7b5c23ffa75accd5ee6b2be', {
    autocapture: {
        elementInteractions: false,
        sessions: true,
        pageViews: true
    }
});

const App = () => {
    const [hasAdBlocker, setHasAdBlocker] = useState(false);
    const detectAdBlock = async () => {
        try {
            const eventName = "login";
            const result = await amplitude.logEvent(eventName).promise;
            if (result.code !== 200) {
                setHasAdBlocker(true);
            }
        } catch (e) {
            alert('Ad blocker detected');
        }
    }

    useEffect(() => {
        detectAdBlock()
    }, []);

    if (hasAdBlocker) {
        return <div style={{padding: 50}}>
            <h5>К сожалению, для дальнейший работы с приложением нужно отключить Ad Blocker и перезагрузить
                страницу.</h5>
        </div>
    }


    return (
        <>
            <AuthProvider>
                <Router/>
            </AuthProvider>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </>
    );
};

export default App;
