import {toDate, UserChatMessage} from "services";
import {Apps} from "enums";
import {toDateString} from "utils";
import React, {FC, useContext} from "react";
import {AuthContext} from "contexts";

interface Props {
    userName: string
    messages: UserChatMessage[]
    isMyMessageInverted?: boolean
}

export const ModerationChat: FC<Props> = ({userName, messages, isMyMessageInverted}) => {
    const {env} = useContext(AuthContext);

    return <>
        {messages?.map((message, i) => {
                const content = message.message
                const date = toDate(message.date, env?.app ?? Apps.Instinct)
                const dateString = date ? `(${toDateString(date, false)})` : ''
                const isMyMessage = isMyMessageInverted ? !message.isMyMessage : message.isMyMessage
                return <div key={message.date + i}
                            className={`mb-1 ${isMyMessage ? 'text-dark' : 'text-body'}`}>
                    <span className={isMyMessage ? `fw-semibold` : ''}>
                        {isMyMessage ? userName : 'Другой'} {dateString}:{' '}

                        {content}
                                    </span>
                </div>
            }
        )}
    </>
}