import React, {CSSProperties, FC} from "react";
import ClipLoader from "react-spinners/ClipLoader";

const override: CSSProperties = {
    marginRight: 4,
};

interface Props {
    isLoading: boolean
}

export const Loader: FC<Props> = ({isLoading}) => {
    return isLoading
        ? <ClipLoader color="white" size={15} cssOverride={override}/>
        : null
}