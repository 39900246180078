import React, {FC, useContext} from 'react'
import {VerificationImage} from "pages/Verifications/components/VerificationImage";
import {Apps, GenderBackground, GenderName, isWatermarkSupported, RootRoutes, UserReviewTags} from "enums";
import {ModerationHint, ModerationRow} from "components";
import {AuthContext} from "contexts";
import {VerificationUser} from "interfaces";
import {PendingCountsByGender} from "services";
import {useNavigate} from "react-router-dom";
import {getGuid} from "utils/getGuid";


const getWaterMarkPosition = (type: number, app: Apps) => {
    if (app === Apps.Instinct) {
        return type === 2 ? "вверху справа ↗️️" : "↖️️ вверху слева";
    } else if (app === Apps.Flava) {
        switch (type) {
            case 0:
                return "↖️️ вверху слева";
            case 1:
                return "вверху справа ↗️️";
            case 2:
                return "↙️ внизу слева️";
            case 3:
            case 4:
                return "↘️ внизу справа";
            default:
                return type;
        }
    } else {
        return ''
    }
}

interface Props {
    verification: VerificationUser
    count?: PendingCountsByGender | null
}

export const VerificationInfo: FC<Props> = ({verification, count}) => {
    const {app} = useContext(AuthContext);

    const navigate = useNavigate();

    const user = verification.user;
    const waterMarkDesc = app && verification &&
        <span>Должен быть <span
            className="text-danger">{getWaterMarkPosition(verification.verificationType, app)}</span>!</span>
    const multipleVerificationsTag = user.reviewTags?.find(t => t.tag === UserReviewTags.OnePersonInMultipleVerifications);

    const goToStats = () => {
        navigate(RootRoutes.UsersStats);
    }

    return <>
        <div className={`d-flex flex-column flex-md-row w-100`}>
            <VerificationImage verification={verification}/>
            <div className="flex flex-column mx-3 my-2 my-md-0 p-2 rounded" style={GenderBackground[user.gender]}>
                {app && isWatermarkSupported(app, user.version) && user.platform !== 'Android' &&
                    <ModerationRow label="Вотермарк"
                                   value={waterMarkDesc}
                                   isInsideTable/>}
                <ModerationRow label="Пол" value={GenderName[user.gender]} isInsideTable/>
                <ModerationRow label="Есть премиум"
                               valueConfig={{isBadge: verification.isPremium, type: 'warning'}}
                               value={verification.isPremium}
                               isInsideTable/>
                <ModerationRow label="Отправлено" value={verification.startDate} isInsideTable/>
                <ModerationRow label="Предыдущая верификация" value={verification.previousEndDate}
                               isInsideTable/>
                {multipleVerificationsTag &&
                    <ModerationHint className={'alert-danger'}>В системе верификаций найдено несколько пользователей с
                        этим лицом: <div>  {multipleVerificationsTag.info?.split('\n').map((m, i) => {
                            const guid = getGuid(m)
                            const isCurrent = user.id === guid;
                            if (isCurrent) {
                                return null
                            }
                            return <div key={m + i}>{guid ?
                                <a href={`profile/${guid}`} target="_blank"
                                   rel="noreferrer">{m}</a> : m}</div>
                        })}</div></ModerationHint>}
            </div>
            <div className="d-flex flex-row justify-content-end flex-grow-1">
                <div>
                    {count &&
                        <div className={`alert alert-light border`}>
                            <div><strong>В ожидании Premium: {count?.premiumVerifications}</strong></div>
                            <div><strong>В ожидании обычные: {count?.verifications}</strong></div>
                            <a onClick={goToStats} className="alert-link user-select-none">Перейти в статистику</a>.
                        </div>}
                </div>
            </div>
        </div>
        <hr className="w-100 my-5"/>
    </>
}