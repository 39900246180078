export enum UserStateChangeReason {
    Unknown = "Unknown",
    Registration = "Registration",
    Pause = "Pause",
    ResetPause = "ResetPause",
    DetectAbuseInAbout = "DetectAbuseInAbout",
    DetectAbuseInAboutQuestion = "DetectAbuseInAboutQuestion",
    DetectAbuseInCustomIcebreaker = "DetectAbuseInCustomIcebreaker",
    BanByBlockedCountry = "BanByBlockedCountry",
    BanByComplaints = "BanByComplaints",
    BanByExpiredTemporaryUnban = "BanByExpiredTemporaryUnban",
    BanByLinkToBannedUser = "BanByLinkToBannedUser",
    BanByMultiLogin = "BanByMultiLogin",
    BanByScamPremiumTransaction = "BanByScamPremiumTransaction",
    BanBySupport = "BanBySupport",
    UnbanBySupport = "UnbanBySupport",
    TemporaryUnbanBySupport = "TemporaryUnbanBySupport",
    ForcedVerificationBySupport = "ForcedVerificationBySupport",
    ForcedVerificationByScamMessages = "ForcedVerificationByScamMessages",
    ForcedVerificationByScreenshots = "ForcedVerificationByScreenshots",
    ForcedVerificationByMatches = "ForcedVerificationByMatches",
    SuccessfulForcedVerification = "SuccessfulForcedVerification",
    SuspiciousWasReviewed = "SuspiciousWasReviewed",
    SuspiciousByScamMessages = "SuspiciousByScamMessages",
    SuspiciousByTooManyLikes = "SuspiciousByTooManyLikes",
    SuspiciousByDuplicateVerifications = "SuspiciousByDuplicateVerifications",
    AutomaticBanByDuplicateVerifications = "AutomaticBanByDuplicateVerifications",
    SuspiciousByIpAddressesReuse = "SuspiciousByIpAddressesReuse",
    SuspiciousByNickname = "SuspiciousByNickname",
    ArchivedByInactivity = "ArchivedByInactivity",
    RestoreFromArchivedByLogin = "RestoreFromArchivedByLogin",
    CreateSupportUser = "CreateSupportUser",
    BlockSupportUser = "BlockSupportUser",
    ForcedVerificationByCelebrity = "ForcedVerificationByCelebrity",
    ForcedVerificationByDifferentFace = "ForcedVerificationByDifferentFace",
    SuspiciousByQrCode = "SuspiciousByQrCode",
    DetectAbuseInPhoto = "DetectAbuseInPhoto",
    DetectAbuseInChat = "DetectAbuseInChat",
    DetectAbuseInName = "DetectAbuseInName",
    SuspiciousByComplaints = "SuspiciousByComplaints",
    WasReviewed = "WasReviewed",
    AutomaticReviewByFullProfile = "AutomaticReviewByFullProfile",
    AutomaticReviewBySensitivePhotoDeleted = "AutomaticReviewBySensitivePhotoDeleted",
    AutomaticReviewByNormalPhotoAfterNoFace = "AutomaticReviewByNormalPhotoAfterNoFace",
    ResetReview = "ResetReview",
    ResetReviewByScamMessages = "ResetReviewByScamMessages",
    ResetReviewByTooManyLikes = "ResetReviewByTooManyLikes",
    ResetReviewByNoFacePhoto = "ResetReviewByNoFacePhoto",
    ResetReviewByAbuse = "ResetReviewByAbuse",
    ResetReviewByMultipleVerifications = "ResetReviewByMultipleVerifications",
    ResetReviewByMinorDetected = "ResetReviewByMinorDetected",
    ResetReviewByDuplicateFacePhoto = "ResetReviewByDuplicateFacePhoto",
    ResetReviewByMultiLogin = "ResetReviewByMultiLogin",
    PromoteSupportToSuperAdmin = "PromoteSupportToSuperAdmin",
    DemoteSuperAdminToSupport = "DemoteSuperAdminToSupport",
    ForcedVerificationByAbuse = "ForcedVerificationByAbuse",
    SuccessfulVerification = "SuccessfulVerification",
    ForcedVerificationByDuplicateFacePhoto = "ForcedVerificationByDuplicateFacePhoto",
    SuspiciousByDuplicateFacePhoto = "SuspiciousByDuplicateFacePhoto",
    ResetVerificationByPhotoDeleted = "ResetVerificationByPhotoDeleted",
    ResetVerificationBySupport = "ResetVerificationBySupport",
    ResetReviewBySensitivePhoto = "ResetReviewBySensitivePhoto",
    ResetReviewByDifferentFace = "ResetReviewByDifferentFace",
    ResetReviewByQrCode = "ResetReviewByQrCode",
    ResetReviewByCelebrity = "ResetReviewByCelebrity",
    SuspiciousDevice = "SuspiciousDevice"
}