import React, {FC} from 'react';
import {ModerationUser} from "interfaces";
import {Accordion} from "react-bootstrap";
import {ModerationTag} from "components/ModerationUserCard/components/ModerationTag";


interface Props {
    user: ModerationUser
}

export const ModerationTags: FC<Props> = ({user}) => {
    const hasReviewTags = !!user.reviewTags?.length;
    const newTags = hasReviewTags ? user.reviewTags.filter(t => !user.reviewedDate || (t.date && t.date > user.reviewedDate)) : [];
    const oldTags = hasReviewTags ? user.reviewTags.filter(t => (!newTags.find(nt => t.tag === nt.tag && t.date === nt.date))) : [];

    if (!newTags.length && !oldTags.length) {
        return null
    }

    return <div>
        <hr style={{opacity: 0.1}}/>
        <Accordion key={user.id} defaultActiveKey="0" className="mt-2" flush alwaysOpen>
            {!!newTags.length &&
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Непросмотренная история изменений</Accordion.Header>
                    <Accordion.Body>
                        <div className="container mx-2" style={{maxHeight: 600, overflow: 'auto'}}>
                            {newTags.map((tag, i) =>
                                <ModerationTag
                                    key={tag.tag + tag.date?.toString() + user.id + 'new' + i}
                                    user={user}
                                    tag={tag}/>
                            )}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>}
            {!!oldTags.length &&
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Просмотренная история изменений</Accordion.Header>
                    <Accordion.Body>
                        <div className="container mx-2" style={{maxHeight: 600, overflow: 'auto'}}>
                            {oldTags.map((tag, i) =>
                                <ModerationTag
                                    key={tag.tag + tag.date?.toString() + user.id + i + 'old'}
                                    user={user}
                                    tag={tag}/>
                            )}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            }
        </Accordion>
    </div>
}
