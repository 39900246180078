import React, {KeyboardEvent, useContext, useEffect, useState} from 'react';
import {Button, Container, Form, Row} from 'react-bootstrap';
import {usersService} from 'services';
import {AuthContext} from 'contexts';
import {ModerationUser} from 'interfaces';
import {ModerationUserCard} from 'components';
import {PremiumGiftButton, UserUpdateModal} from './components';
import {BoostSparkGiftButton} from "pages/Profile/components/BoostSparkGiftButton";
import {ProfileDeleteButton} from "components/ModerationUserCard/components";
import {useNavigate, useParams} from "react-router-dom";
import {UserStatuses} from "enums";
import {EventsPerModerationSource, ModerationSources} from "enums/ModerationSources";
import {Events, eventTracker, ReviewTypes} from "utils/eventTracker";

export const ProfileSearchByIDPage = () => {
    const {env} = useContext(AuthContext);
    const {userId: userIdParam} = useParams();
    const navigate = useNavigate();

    const [userId, setUserId] = useState(userIdParam);
    const [user, setUser] = useState<ModerationUser | undefined | null>();

    const resetUser = async (id: string) => {
        if (!env) {
            return;
        }

        const user = await usersService.getById(id, env);
        setUser(user);
    }

    const handleSubmit = async () => {
        navigate(`/profile/${userId}`);
    };

    const unblock = async () => {
        if (!env || !user) {
            return;
        }

        eventTracker.track(EventsPerModerationSource[ModerationSources.Profile], eventTracker.getReviewParams(user, ReviewTypes.Unban, ModerationSources.Profile))
        user?.id && (await usersService.unban(user.id, env));
        setUser(u => (u ? {
            ...u,
            wasReviewed: true,
            reviewedDate: new Date(),
            isBanned: false,
            status: UserStatuses.Active
        } : null));
    };

    const onReload = async () => {
        if (user?.id && env) {
            const newUser = await usersService.getById(user.id, env);
            setUser(newUser);
        }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && userId) {
            handleSubmit()
        }
    }


    useEffect(() => {
        userIdParam && resetUser(userIdParam)
    }, [env, userIdParam]);


    useEffect(() => {
        if (user?.id) {
            eventTracker.track(Events.profile_open, eventTracker.getReviewParams(user, null, ModerationSources.Profile))
        }
    }, [user?.id]);

    return (
        <div className="m-2 m-lg-3">
            <Container>
                <Row>
                    <Form.Label className="col-sm-2 col-form-label">
                        Пользователь
                    </Form.Label>
                    <div className="col-sm-10 d-flex flex-row">
                        <Form.Control name="username" placeholder="ID профиля" value={userId}
                                      onChange={e => setUserId(e.currentTarget.value)} onKeyDown={handleKeyDown}/>
                        <Button variant="primary" className="mx-2" onClick={handleSubmit} disabled={!userId}>
                            Искать
                        </Button>
                    </div>
                </Row>
            </Container>
            {user && (
                <div className="mt-2">
                    <ModerationUserCard
                        user={user}
                        onUserChange={setUser}
                        greenButton={{text: 'Разблокировать', onClick: unblock}}
                        topButtons={<ProfileDeleteButton userId={user.id} userName={user.name} onReload={onReload}/>}
                        moderationSource={ModerationSources.Profile}
                        leftButtons={
                            <>
                                <UserUpdateModal user={user} onChange={setUser}/>
                                <PremiumGiftButton userKey={user.id} onUserChange={setUser}/>
                                <BoostSparkGiftButton userKey={user.id}/>
                            </>
                        }/>
                </div>
            )}

        </div>
    );
};
