import React, {FC, useContext} from 'react';
import {PhotoPayloadBloom} from 'services';
import {RiErrorWarningLine, RiExternalLinkLine} from "react-icons/ri";
import {ModerationUserTag, UserActor} from "interfaces";
import {PhotoView} from "react-photo-view";
import {usePhotoManagement} from "components/ModerationUserCard/ModerationPhotos/usePhotoManagement";
import {FaRegTrashAlt} from "react-icons/fa";
import {getPhotoInfoFromTag, isNoFaceButtonSupported, isReviewPhoto} from "enums";
import {
    ModerationPhotoTags
} from "components/ModerationUserCard/ModerationPhotos/ModerationPhotoTags/ModerationPhotoTags";
import {MdFaceRetouchingOff} from "react-icons/md";
import {AuthContext} from "contexts";

interface Props {
    userId?: string;
    featureId?: number;
    pic: PhotoPayloadBloom;
    hasNoFace?: boolean;
    height?: number;
    className?: string;
    onPhotoDeleted?: (key: string) => void;
    onSensitiveTagChanged?: (key: string, isSensitive: boolean) => void;
    onNoFaceChanged?: (key: string, hasFace: boolean) => void;
    objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down";
    reviewTags?: ModerationUserTag[]
    isInTable?: boolean;
    duplicateHashes?: UserActor[]
}

export const ModerationPic: FC<Props> = ({
                                             reviewTags,
                                             objectFit,
                                             pic: p,
                                             height,
                                             className,
                                             userId,
                                             featureId,
                                             onPhotoDeleted,
                                             onSensitiveTagChanged,
                                             onNoFaceChanged,
                                             isInTable = false,
                                             hasNoFace,
                                             duplicateHashes = []
                                         }) => {
    const photoManager = usePhotoManagement()
    const photoTags = reviewTags?.filter(rt => isReviewPhoto(rt.tag) && rt.info && getPhotoInfoFromTag(rt.info, rt.tag)?.key === p.key)
    const {env} = useContext(AuthContext);

    const searchLinks = [
        {label: 'Google', link: `https://www.google.com/searchbyimage?client=app&image_url=${p.image[0].link}`},
        {label: 'G. Image', link: `https://lens.google.com/uploadbyurl?url=${p.image[0].link}`},
        {label: 'Yandex', link: `https://yandex.com/images/search?rpt=imageview&url=${p.image[0].link}`}
    ]

    if (!p || !p.image || !p.image.length) {
        return null;
    }

    const toggleSensitive = async () => {
        const result = await photoManager.toggleSensitive(p, userId, {objectFit, withAlert: true});
        if (result === true || result === false) {
            onSensitiveTagChanged && onSensitiveTagChanged(p.key, !p.isSensitive);
        }
    };

    const toggleNoFace = async () => {
        const result = await photoManager.toggleNoFace(p, !!hasNoFace, userId, {objectFit, withAlert: true});
        if (result === true || result === false) {
            onNoFaceChanged && onNoFaceChanged(p.key, !hasNoFace);
        }
    };

    const searchImage = () => {
        searchLinks.forEach(({link}, index) => {
            const newWindow = window.open(link);
            window.focus();
            if (!newWindow) {
                alert('Некоторые ссылки не открылись. Проверьте pop-up blocker и попробуйте снова.');
            }
        });
        window.focus();
        return false
    }

    return (
        <div className={`m-1 rounded bg-secondary`}>
            {photoTags &&
                <div
                    className="d-flex flex-column mx-2 pt-2 pb-2"
                    style={{top: 12, right: 12, gap: 4}}>
                    <ModerationPhotoTags reviewTags={photoTags} isSensitivePhoto={p.isSensitive}/>
                </div>
            }
            <PhotoView src={p.image[0].link}>
                <div className="position-relative">
                    <img
                        src={p.image[0].link}
                        alt={`Pic ${p.key}`}
                        style={{objectFit: objectFit ?? 'scale-down'}}
                        height={height ?? 400}
                        className={`w-100 rounded ${className}`}
                    />
                    <div
                        className="position-absolute d-flex flex-row flex-wrap mx-2 pt-2 pb-2"
                        style={{top: 12, right: 48, gap: 4}}>
                        {p.isPrivate && (
                            <span className="badge bg-secondary rounded-pill">Private</span>
                        )}
                    </div>
                </div>
            </PhotoView>
            {!isInTable &&
                <div className="d-flex flex-row flex-wrap align-items-start pt-1 pb-1 mx-1"
                     style={{bottom: 0, right: 0, gap: 4}}>
                    {onNoFaceChanged && isNoFaceButtonSupported(env) &&
                        <button type="button"
                                className="btn btn-outline-secondary btn-sm d-flex flex-row align-items-center"
                                onClick={toggleNoFace}>
                            <MdFaceRetouchingOff size={14}
                                                 style={{marginRight: 4}}/>
                            {hasNoFace ? 'Удалить NoFace' : 'Добавить NoFace'}
                        </button>}
                    {onSensitiveTagChanged &&
                        <button type="button"
                                className="btn btn-outline-secondary btn-sm d-flex flex-row align-items-center"
                                onClick={toggleSensitive}>
                            <RiErrorWarningLine size={14} style={{marginRight: 4}} color={p.isSensitive ? 'red' : ''}/>
                            {p.isSensitive ? "Удалить Sensitive" : "Добавить Sensitive"}
                        </button>}
                    <button type="button"
                            className="btn btn-outline-secondary btn-sm d-flex flex-row align-items-center"
                            onClick={searchImage}>
                        <RiExternalLinkLine size={14} style={{marginRight: 4}}/>Проверить
                    </button>
                    <button type="button"
                            className="btn btn-outline-secondary btn-sm d-flex flex-row align-items-center"
                            onClick={async () => {
                                const result = await photoManager.onPicPreview(p, userId, featureId)
                                result && onPhotoDeleted && onPhotoDeleted(result.toString())
                            }}>
                        <FaRegTrashAlt size={14} style={{marginRight: 4}}/>
                        Удалить
                    </button>
                </div>
            }
        </div>
    );
};
