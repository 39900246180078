import React, {FC} from "react";

interface Props {
    link: string
    onClick: (link: string) => void
}

export const ModerationVerificationPhoto: FC<Props> = ({link, onClick}) => {
    return <img
        src={link}
        alt={`Pic ${link}`}
        style={{objectFit: 'cover'}}
        height={160}
        width={120}
        className="rounded"
        onClick={() => onClick(link)}
    />
}