import {isReviewPhotoDanger, UserReviewTags} from "enums";
import React, {FC} from "react";

export interface Tag {
    tag: UserReviewTags,
    info: string
}

export interface TagProps {
    tag: Tag;
    isSensitivePhoto: boolean;
    textClassName?: string
}

export const ModerationPhotoTag: FC<TagProps> = ({tag, isSensitivePhoto, textClassName}) => {
    return <div className="d-flex align-items-start w-100 flex-wrap">
        <span className="badge"
              style={{
                  fontWeight: 400,
                  backgroundColor: isReviewPhotoDanger(tag.tag) && isSensitivePhoto ? '#D9292E' : '#373A40'
              }}
        >
            #{tag.tag}
        </span>
        <span key={tag.tag}
              title={tag.info}
              className={`badge text-black text-wrap text-start ${textClassName}`}
              style={{fontWeight: 400}}>{tag.info}</span>
    </div>
}