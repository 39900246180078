export enum AdminActivityActions {
    Feedback = 'Feedback',
    Ban = 'Ban',
    Unban = 'Unban',
    Verification = 'Verification',
    BanBySuperAdmin = 'BanBySuperAdmin',
    UnbanBySuperAdmin = 'UnbanBySuperAdmin',
    VerificationChangeBySuperAdmin = 'VerificationChangeBySuperAdmin'
}

export const AdminActivityActionName = {
    [AdminActivityActions.Feedback]: 'Просмотры',
    [AdminActivityActions.Ban]: 'Бан',
    [AdminActivityActions.Unban]: 'Разбан',
    [AdminActivityActions.Verification]: 'Верификации',
    [AdminActivityActions.BanBySuperAdmin]: 'Суперадмин Бан',
    [AdminActivityActions.UnbanBySuperAdmin]: 'Суперадмин Разбан',
    [AdminActivityActions.VerificationChangeBySuperAdmin]: 'Суперадмин Смена Верификации',
};