import {ModerationSection} from "components/ModerationSection";
import {toDayDateString} from "utils";
import {ModerationUserActors} from "components/ModerationUserCard/components/ModerationUserActors";
import React, {FC, useState} from "react";
import {ModerationUser, UserActor} from "interfaces";
import {ModerationHint} from "components/ModerationHint";

interface Props {
    user: ModerationUser
}

export const ModerationIPAddresses: FC<Props> = ({user}) => {
    const [isVisible, setIsVisible] = useState(false);


    if (!user.ipAddresses) {
        return null;
    }

    const ipAddressReuses = user.ipAddresses.reduce((prev, curr) => [...prev, ...curr.users.filter(u => prev.findIndex(pu => pu.key === u.key) < 0)], [] as UserActor[]);
    const isSuspect = user.countryCode && user.ipCountryCode && user.ipAddresses.findIndex(ip => ip.countryCode !== user.countryCode) >= 0;


    return <ModerationSection label="IP Адреса">
        {isSuspect &&
            <ModerationHint className="alert-warning mt-0">У юзера отличается страна по IP адресу и
                координатам</ModerationHint>}
        {!!ipAddressReuses.length &&
            <div className="mb-2">Количество пользователей с такими же IP: {ipAddressReuses.length}</div>}
        <a href="#"
           className="text-black-50 text-decoration-underline"
           style={{cursor: 'pointer'}}
           onClick={async (event) => {
               event.preventDefault();
               setIsVisible(prev => !prev)
           }}>
            {isVisible ? 'Скрыть' : 'Показать'}
        </a>
        {isVisible &&
            <div className="table-responsive mt-1" style={{maxHeight: 500, overflow: 'auto'}}>
                <table className="table-light w-100">
                    <thead className="">
                    <tr>
                        <td className="fw-semibold" style={{paddingRight: 10}}>Страна</td>
                        <td className="fw-semibold" style={{paddingRight: 10}}>Адрес</td>
                        <td className="fw-semibold" style={{paddingRight: 10}}>Дата</td>
                    </tr>
                    </thead>
                    <tbody>
                    {user.ipAddresses.map((ip) =>
                        <tr key={ip.ipAddress} style={{verticalAlign: 'top'}}>
                            <td>{ip.countryCode}</td>
                            <td>{ip.ipAddress}</td>
                            <td>{ip.lastUsageDate && toDayDateString(ip.lastUsageDate)}</td>
                            <td>
                                <ModerationUserActors users={ip.users}/>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>}
    </ModerationSection>
}