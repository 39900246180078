import React, {FC, useContext, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import {usersService} from "services";
import {AuthContext} from "contexts";
import {isBoostAndSparksGiftsSupported} from "enums";

enum Gifts {
    Spark = 'Spark',
    Boost = 'Boost'
}

interface Props {
    userKey: string;
}

export const BoostSparkGiftButton: FC<Props> = ({userKey}) => {
    const {user, env} = useContext(AuthContext);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [count, setCount] = useState(0);
    const [gift, setGift] = useState(Gifts.Spark);


    const handleClose = () => {
        setCount(0);
        setIsModalVisible(false);
    }

    const handleGift = async () => {
        if (!env) {
            return;
        }
        await usersService.gift(userKey, gift === Gifts.Spark ? 'greeting' : gift.toLowerCase(), env, count)
        handleClose();
    }

    if (!user?.isSuperAdmin || !env?.app || !isBoostAndSparksGiftsSupported(env.app)) {
        return null;
    }

    return <>
        <Button
            onClick={() => setIsModalVisible(true)}
            variant="outline-info">
            Подарить Spark/Boost
        </Button>
        <Modal show={isModalVisible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Подарки</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Что</Form.Label>
                        <Form.Select value={gift} onChange={e => setGift(e.target.value as Gifts)}>
                            {Object.values(Gifts).map(v => <option key={v}>{v}</option>)}
                        </Form.Select>
                    </Form.Group>
                </Form>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Количество</Form.Label>
                        <Form.Control type="number" placeholder="0" onChange={e => setCount(+e.target.value)}/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Закрыть
                </Button>
                <Button disabled={count < 1} variant="primary" onClick={handleGift}>
                    Подарить
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

