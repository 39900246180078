import {Button} from "react-bootstrap";
import React from "react";

interface Props<T extends string> {
    all: T[]
    labels: Record<T, string>
    onSelect: (id: T) => void
}

export const ReasonsSelect = <T extends string, >({all, labels, onSelect}: Props<T>) => {
    return <div className="d-flex flex-column">
        {all.map(r => <Button
            key={r}
            onClick={() => onSelect(r)}
            variant="outline-dark"
            className="my-1">
            {labels[r]}
        </Button>)}
    </div>
}