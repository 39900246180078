import React, {useContext, useEffect, useState} from 'react';
import {AdminActivityStatsProfilesParams, statsService} from 'services';
import {AuthContext} from 'contexts';
import {ModerationUser} from 'interfaces';
import {Genders, RootRoutes} from "enums";
import {Pager} from "pages/ProfileSearch/components/Pager";
import {useNavigate, useSearchParams} from "react-router-dom";
import {DateTime} from "luxon";
import {AdminActivityActions} from "enums/AdminActivityAction";
import {ModerationUsersTable} from "components/ModerationUsersTable/ModerationUsersTable";


const PAGE_SIZE = 20


const getParamDate = (value: string | null) => value ? new Date(value) : DateTime.now().toJSDate()

export const AdminActivityProfilesPage = () => {
    const {env} = useContext(AuthContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [users, setUsers] = useState<ModerationUser[]>([]);

    const [params, setParams] = useState<AdminActivityStatsProfilesParams>({
        startDate: getParamDate(searchParams.get('startDate')),
        endDate: getParamDate(searchParams.get('endDate')),
        action: (searchParams.get('activity') as AdminActivityActions) ?? AdminActivityActions.Feedback,
        adminUserKey: searchParams.get('adminId') ?? '',
        gender: +(searchParams.get('gender') as Genders),
        skip: +(searchParams.get('skip') ?? 0),
    });

    const displayedUsers = users.slice(PAGE_SIZE * currentPage, PAGE_SIZE * (currentPage + 1))

    const onNext = async () => {
        if (!env || isLoading || !hasMore) {
            return;
        }

        setIsLoadingMore(true);
        if ((currentPage + 1) * PAGE_SIZE >= users.length) {
            const newUsers = await statsService.getActivityStatsProfiles({...params, skip: users.length}, env)
            setHasMore(!!newUsers?.length && newUsers.length === PAGE_SIZE);
            setUsers(prev => [...prev, ...(newUsers ?? [])]);
        }
        setIsLoadingMore(false);
        setCurrentPage(prev => prev + 1)
    };

    const onPrevious = () => {
        setHasMore(true);
        setCurrentPage(prev => prev - 1)
    }

    const reload = async () => {
        if (!env) {
            return;
        }

        const newUsers = await statsService.getActivityStatsProfiles(params, env)
        newUsers && setUsers(newUsers);
        setIsLoading(false);
        setCurrentPage(0)
    }

    const onClick = (id: string) => {
        window.open(`${RootRoutes.Profile}/${id}`, '_blank', 'rel=noopener noreferrer')
    }


    useEffect(() => {
        env && reload()
    }, [env]);


    if (isLoading) {
        return <div className="m-2 m-lg-3">Загружается...</div>
    }

    return (
        <div className="d-flex flex-column align-items-center m-2 m-lg-3" style={{maxHeight: '90vh'}}>
            {!users.length && 'Результатов нет'}
            <div className="w-75 d-flex flex-row justify-content-end flex-rowsticky-top mt-2">
                <Pager page={currentPage} hasMore={hasMore} onNext={onNext} onPrevious={onPrevious}/>
            </div>

            <ModerationUsersTable users={displayedUsers} isLoadingMore={isLoadingMore} onClick={onClick}/>
        </div>
    );
};
