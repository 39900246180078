import {request} from "./request";
import {Apps} from "enums";
import {EnvSetup, getSupportUrl} from "enums/Envs";

export interface PendingCount {
    users: number;
    verifications: number;
    premiumVerifications: number;
    temporaryUnbannedUsers: number;
}

export interface PendingCountByGender extends PendingCount {
    gender: number;
}

export interface PendingCountsByGender extends PendingCount {
    countByGenders: PendingCountByGender[]
}


export interface AdminStat {
    userKey: string;
    name: string;
    reviewedUserCount: number;
}

export const userStatsService = {
    getAllAdmins: async (app: Apps) => {
        if (app === Apps.Instinct || app === Apps.Bloom) {
            return (await request<{ users: AdminStat[] }>({
                method: 'post',
                url: `/manage/admins`,
                data: {}
            }))?.users
        }
        if (app === Apps.Flava) {
            return (await request<{ profiles: AdminStat[] }>({
                method: 'post',
                url: `/manage/admins`,
                data: {}
            }))?.profiles
        }
        if (app === Apps.Spark) {
            return (await request<{ sparkers: AdminStat[] }>({
                method: 'post',
                url: `/manage/admins`,
                data: {}
            }))?.sparkers
        }

        alert('getAllAdmins not implemented!')

    },
    getPendingCount: async (env: EnvSetup) => {
        return await request<PendingCountsByGender>({
            method: 'get',
            url: `/${getSupportUrl(env)}/users/count`,
        })
    }
}