import React, {FC} from 'react';
import {CountPayload, ModerationUser} from "interfaces";
import {Genders} from "enums";
import {ModerationHint, ModerationRow} from "components";
import {ModerationSection} from "components/ModerationSection";


interface Props {
    user: ModerationUser
}

export const ModerationStats: FC<Props> = ({user}) => {
    const totalLikes = user.outgoingLikesCount.allTime + user.outgoingDislikesCount.allTime;
    const isSuspectLikes = user.gender === Genders.Female && user.outgoingLikesCount.allTime >= user.outgoingDislikesCount.allTime && user.outgoingLikesCount.allTime >= 40;


    const getPercentage = (current: number, tot: number) => {
        if (tot === 0) {
            return '(0%)'
        }

        return `(${Math.round((current / tot) * 100)}%)`;
    }

    const getValue = (count: CountPayload) => {
        return count.allTime
    }

    return <ModerationSection label="Статистика">
        <ModerationRow label="🔍 Показов" value={user.displaysCount.allTime} isInsideTable/>
        <ModerationRow label="👍 Входящих Лайков"
                       value={`${user.likesCount.allTime} ${getPercentage(user.likesCount.allTime, user.displaysCount.allTime)}`}
                       isInsideTable/>
        <ModerationRow label="👍 Входящих Лайков сейчас" value={user.likesCount.current} isInsideTable/>
        <hr/>
        <ModerationRow label="👍 Исходящих лайков"
                       value={`${getValue(user.outgoingLikesCount)} ${getPercentage(user.outgoingLikesCount.allTime, totalLikes)} ${isSuspectLikes ? '🔺' : ''}`}
                       isInsideTable/>
        <ModerationRow label="👎 Исходящих дизлайков"
                       value={`${getValue(user.outgoingDislikesCount)} ${getPercentage(user.outgoingDislikesCount.allTime, totalLikes)}`}
                       isInsideTable/>
        {isSuspectLikes &&
            <ModerationHint>Юзер выставляет подозрительно много лайков</ModerationHint>
        }
        <hr/>
        <ModerationRow label="💑 Матчей" value={getValue(user.matchesCount)} isInsideTable/>
        <ModerationRow label="💑 Матчей сейчас" value={user.matchesCount.current} isInsideTable/>
        <ModerationRow label="💬 Чатов" value={getValue(user.chatsCount)} isInsideTable/>
        <ModerationRow label="💬 Чатов сейчас" value={user.chatsCount.current} isInsideTable/>
    </ModerationSection>
}
