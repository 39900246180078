const API_KEY = 'AIzaSyBows5bRpeo5Z1kB_4RJ3q79uDvc2-CX5o';
const API_URL = 'https://translation.googleapis.com/language/translate/v2';

async function translateText(text: string, targetLanguage: string) {
    const encodedText = encodeURIComponent(text);
    const url = `${API_URL}?q=${encodedText}&target=${targetLanguage}&key=${API_KEY}`;

    try {
        const response = await fetch(url, {method: 'POST'});
        const data = await response.json();

        if (data.data && data.data.translations && data.data.translations.length > 0) {
            return data.data.translations[0].translatedText;
        } else {
            throw new Error('Translation failed');
        }
    } catch (error) {
        console.error('Error during translation:', error);
        return 'Translation error';
    }
}

// Usage example
export const handleTranslation = async (textToTranslate: string) => {
    const targetLang = 'EN';
    return translateText(textToTranslate, targetLang);
}

