import React, {FC} from 'react';
import {settingsStore} from "stores";
import {observer} from "mobx-react";
import Select from 'react-select'
import {statsStore} from "stores/statsStore";
import {GenderName, Genders} from "enums";

interface Props {
    onChange: (id?: string, gender?: Genders) => void;
    children?: React.ReactNode
}

export const AdminsSelect: FC<Props> = observer(({children, onChange}) => {
    if (!statsStore.admins.length) {
        return null
    }

    const allGenders = Object.values(Genders).map(g => ({value: g, label: GenderName[g]}));

    return <div className="row mb-2">
        <div className="col-6">
            {children}
        </div>
        <div className="col-3">
            <Select options={allGenders}
                    defaultValue={undefined}
                    isOptionSelected={i => i?.value === statsStore.selectedGender}
                    placeholder="Все гендеры"
                    isClearable={true}
                    onChange={i => {
                        i?.value && onChange(statsStore.selectedAdmin?.userKey, i.value)
                        statsStore.selectGender(i?.value)
                    }}
            />
        </div>
        <div className="col-3">
            <Select options={statsStore.adminsSelect}
                    defaultValue={statsStore.selectedAdmin ? {
                        label: `${statsStore.selectedAdmin.name} (${statsStore.selectedAdmin.reviewedUserCount})`,
                        value: statsStore.selectedAdmin.userKey
                    } : undefined}
                    isOptionSelected={i => i.value === settingsStore.selectedCountry?.countryCode}
                    placeholder="Все админы"
                    isClearable={true}
                    onChange={i => {
                        i?.value && onChange(i.value, statsStore.selectedGender)
                        statsStore.selectAdmin(i?.value)
                    }}
            />
        </div>
    </div>
})

