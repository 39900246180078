import React, {FC} from 'react';
import {ModerationUser} from "interfaces";
import {ModerationRow} from "components";
import {ModerationPic} from "components/ModerationUserCard/ModerationPhotos/ModerationPic";
import {ModerationPhotoProvider} from "components/ModerationUserCard/ModerationPhotos/PhotoProvider";
import {PhotoPayloadBloom} from "services";

interface Props {
    user: ModerationUser;
    onUserChange: (user: ModerationUser) => void;
}

export const ModerationAttractiveFeatures: FC<Props> = ({user, onUserChange}) => {
    const allFeatures = user.attractiveFeatures?.filter(f => !f.photo).map(f => f.text) ?? []
    const allImages = user.attractiveFeatures?.filter(f => f.photo) ?? []

    const onPhotoDeleted = (id: string) => {
        onUserChange({
            ...user,
            attractiveFeatures: user.attractiveFeatures?.map(a => a.id.toString() === id.toString() ? {
                ...a,
                photo: undefined
            } : a)
        })
    }

    if (!allFeatures?.length && !allImages?.length) {
        return null
    }

    return <div className="mt-4 mx-1">
        <h5 className="mb-2">Привлекательные части</h5>
        <ModerationRow label="Части без фото" value={allFeatures?.join(', ')} isInsideTable/>
        {!!allImages?.length &&
            <ModerationPhotoProvider userId={user.id} reviewTags={user.reviewTags}
                                     photos={allImages?.map(p => p.photo) as PhotoPayloadBloom[]}>
                <div className="mt-2 container-fluid">
                    <div className="row mt-1">
                        {allImages?.map(f => (
                            <div key={f.text} className="col-12 col-md-6 g-0">
                                <h6 className="my-1">{f.text}</h6>
                                {f.photo && <ModerationPic pic={f.photo}
                                                           reviewTags={user.reviewTags}
                                                           userId={user.id}
                                                           featureId={f.id}
                                                           className="w-100 bg-light"
                                                           onPhotoDeleted={onPhotoDeleted}/>}
                            </div>))}
                    </div>
                </div>
            </ModerationPhotoProvider>
        }
    </div>
};

