import React, {FC} from "react";
import {PhotoProvider} from "react-photo-view";
import {getPhotoInfoFromTag, isReviewPhoto} from "enums";
import {ModerationUserTag} from "interfaces";
import {PhotoPayloadBloom} from "services";
import {Button} from "react-bootstrap";
import {usePhotoManagement} from "components/ModerationUserCard/ModerationPhotos/usePhotoManagement";
import {
    ModerationPhotoTags
} from "components/ModerationUserCard/ModerationPhotos/ModerationPhotoTags/ModerationPhotoTags";

interface Props {
    userId: string
    reviewTags: ModerationUserTag[]
    children: React.ReactNode
    photos: PhotoPayloadBloom[]

    onPhotoDeleted?: (key: string) => void
    onSensitiveTagChanged?: (key: string, isSensitive: boolean) => void
}

export const ModerationPhotoProvider: FC<Props> = ({
                                                       userId,
                                                       photos,
                                                       reviewTags,
                                                       children,
                                                       onPhotoDeleted,
                                                       onSensitiveTagChanged
                                                   }) => {
    const photoManager = usePhotoManagement()

    return <PhotoProvider
        maskOpacity={0.93}
        speed={() => 200}
        bannerVisible={true}
        overlayRender={props => {
            const photo = photos[props.index]
            if (!photo) {
                return null
            }
            const photoTags = reviewTags.filter(rt => isReviewPhoto(rt.tag) && rt.info && getPhotoInfoFromTag(rt.info, rt.tag)?.key === photo.key)
            return <div className="mx-4 d-flex flex-row" style={{gap: 10, position: 'absolute', bottom: 0}}>
                <ModerationPhotoTags reviewTags={photoTags} isSensitivePhoto={photo.isSensitive}
                                     textClassName="text-white"/>
            </div>
        }}
        toolbarRender={props => {
            return <div className="mx-4 d-flex flex-row" style={{gap: 10}}>
                {onPhotoDeleted &&
                    <Button variant="danger" onClick={async () => {
                        if (!photos[props.index]) {
                            return
                        }
                        const deletedKey = await photoManager.onPicDelete(photos[props.index], userId)
                        deletedKey && onPhotoDeleted(deletedKey)
                    }}>Удалить фото</Button>}
                {onSensitiveTagChanged &&
                    <Button variant="secondary" onClick={async () => {
                        const photo = photos[props.index]
                        if (!photo) {
                            return
                        }
                        const isSensitive = await photoManager.toggleSensitive(photo, userId)
                        photo && isSensitive !== undefined && onSensitiveTagChanged(photo.key, isSensitive)
                    }}>{photos[props.index]?.isSensitive ? "Удалить Sensitive" : "Добавить Sensitive"}</Button>}
            </div>
        }}>
        {children}
    </PhotoProvider>
}