import React, {useCallback, useEffect, useState} from "react";

import type {IAfterGuiAttachedParams, IDoesFilterPassParams,} from "ag-grid-community";
import type {CustomFilterProps} from "ag-grid-react";
import {useGridFilter} from "ag-grid-react";
import {Button} from "react-bootstrap";
import {PatternTypes} from "services/abuseWordsService";
import {AbusePatternTypeTag} from "pages/AbuseWords/components/AbusePatternTypeTag";

export const AbusePatternTypesFilter = ({model, onModelChange}: CustomFilterProps) => {
    const [closeFilter, setCloseFilter] = useState<(() => void) | undefined>();
    const [selectedValues, setSelectedValues] = useState<PatternTypes[] | null>(model);

    const doesFilterPass = useCallback((params: IDoesFilterPassParams) => {
        return !!selectedValues && selectedValues.some(item => params.data.patternType === item);
    }, [selectedValues]);

    const afterGuiAttached = useCallback(
        ({hidePopup}: IAfterGuiAttachedParams) => {
            setCloseFilter(() => hidePopup);
        },
        [],
    );

    // register filter handlers with the grid
    useGridFilter({
        doesFilterPass,
        afterGuiAttached,
    });

    useEffect(() => {
        setSelectedValues(model ?? []);
    }, [model]);

    const onClick = () => {
        if (closeFilter) {
            closeFilter();
        }
    };

    const sortedValues = Object.values(PatternTypes)
        .filter(v => typeof v === 'number')
        .sort((a, b) => PatternTypes[+a].toString().localeCompare(PatternTypes[+b].toString()));
    return (
        <div className="p-3 d-flex flex-column bg-white">
            <label className={'fw-bold'}>Типы паттерна</label>
            <div className="col-sm-10 mt-2">
                {sortedValues.map(value =>
                    <div key={value} className="form-check">
                        <input className="form-check-input"
                               type="checkbox" value="" id={value.toString()}
                               checked={selectedValues?.includes(value as PatternTypes)}
                               onChange={() => {
                                   const newValue = selectedValues?.includes(value as PatternTypes) ? selectedValues.filter(pg => pg !== value) : [...(selectedValues ?? []), value as PatternTypes];
                                   const agNewValue = newValue.length ? newValue : null;
                                   setSelectedValues(agNewValue);
                                   onModelChange(agNewValue);
                               }}/>
                        <AbusePatternTypeTag patternType={+value}/>
                    </div>)}
            </div>
            <Button size={'sm'} variant={'secondary'} onClick={onClick} className={'mt-2'}>Готово</Button>
        </div>
    );
};