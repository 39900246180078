import {useContext, useEffect, useRef, useState} from 'react';
import {usersService} from 'services';
import {ModerationUser} from 'interfaces';
import {AuthContext} from 'contexts';
import {ModerationUserCard} from 'components';
import {Button} from "react-bootstrap";
import {UserStatuses} from "enums";
import {ModerationCountry} from "./components";
import {observer} from "mobx-react";
import {settingsStore} from "stores";
import {useHotkeys} from "react-hotkeys-hook";
import {Tooltip} from 'react-tooltip'
import {AiOutlineInfoCircle} from "react-icons/ai";
import {Events, eventTracker, ReviewTypes} from "utils/eventTracker";
import {ModerationSources} from "enums/ModerationSources";

export const ModerationPage = observer(() => {
    const hasMore = useRef(true);

    const {env} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [users, setUsers] = useState<ModerationUser[]>([]);

    const user = users[currentIndex];

    const onBan = () => {
        setUsers(prevUsers => prevUsers.map(u => u.id === user.id ? ({
            ...u,
            status: UserStatuses.Banned,
            isBanned: true
        }) : u))
        next(false)
    }

    const next = async (withFeedback: boolean = true) => {
        if (!env) {
            return;
        }

        if (withFeedback) {
            usersService.dislike(user.id, env);
            eventTracker.track(Events.review, eventTracker.getReviewParams(user, ReviewTypes.Approve, ModerationSources.Moderation))
        }
        setCurrentIndex(prev => prev + 1)

        if (users.length - currentIndex < 10 && hasMore.current && !isLoading) {
            setIsLoading(true);
            const newUsers = await usersService.getPage(
                env,
                users.map(u => u.id),
                {mode: 1, countryCode: settingsStore.selectedCountry?.countryCode}
            );
            hasMore.current = !!newUsers?.length && newUsers.length > 10;
            setUsers(prev => [...prev, ...(newUsers ?? [])]);
            setIsLoading(false);
        }
    };

    const back = () => {
        setCurrentIndex(prev => prev - 1)
    }

    const onUserChange = (u: ModerationUser) => {
        if (user.status !== u.status && u.status !== UserStatuses.Active) {
            next(false)
        }
        setUsers(pus => pus.map(pu => pu.id === u.id ? u : pu))

    }

    useEffect(() => {
        if (!env) {
            return;
        }


        setIsLoading(true);
        setTimeout(async () => {
            const newUsers = await usersService.getPage(env, [], {
                mode: 1,
                countryCode: settingsStore.selectedCountry?.countryCode
            });
            newUsers && setUsers(newUsers);
            setIsLoading(false);
            setCurrentIndex(0)
        }, 300);

    }, [env, settingsStore.selectedCountry?.countryCode]);

    useEffect(() => {
        if (user?.id) {
            eventTracker.track(Events.profile_open, eventTracker.getReviewParams(user, null, ModerationSources.Moderation))
        }
    }, [user?.id]);


    useHotkeys('shift+c', () => next(true), [user?.id])

    if (isLoading || !user) {
        return <div className="m-2 m-lg-3">
            <ModerationCountry/>
            {isLoading ? 'Загружается...' : 'Это все'}
        </div>
    }

    return <div className="m-2 m-lg-3">
        <ModerationUserCard
            user={user}
            header={<ModerationCountry/>}
            onUserChange={(u) => u.isBanned ? onBan() : onUserChange(u)}
            greenButton={{text: 'Следующий', onClick: () => next(true)}}
            onPostponeReview={() => next(false)}
            moderationSource={ModerationSources.Moderation}
            leftButtons={
                <div className="d-flex flex-row align-items-center">
                    <AiOutlineInfoCircle
                        size={22}
                        className="mx-2 mx-lg-4"
                        data-tooltip-id="hotkeys-tooltip"
                        data-tooltip-place="bottom"/>
                    <Button disabled={currentIndex === 0} variant="secondary" onClick={back}>
                        Назад
                    </Button>
                </div>
            }
        />
        <Tooltip id="hotkeys-tooltip">
            shift+c - Следующий <br/>
            shift+x - Заблокировать
        </Tooltip>
    </div>;
});
