import React, {useContext, useEffect, useState} from 'react';
import {usersService} from 'services';
import {AuthContext} from 'contexts';
import {ModerationUser} from 'interfaces';
import {RootRoutes} from "enums";
import {settingsStore} from "stores";
import {Pager} from "pages/ProfileSearch/components/Pager";
import {
    ProfileSearchParameters,
    ProfileSearchSearchParams,
    SearchParams
} from "pages/ProfileSearch/components/ProfileSeachParameters";
import {ModerationUsersTable} from "components/ModerationUsersTable/ModerationUsersTable";

const PAGE_SIZE = 27


export const AutoBannedProfilesPage = () => {
    const {env} = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [users, setUsers] = useState<ModerationUser[]>([]);
    const [params, setParams] = useState<ProfileSearchSearchParams>({gender: 0});

    const displayedUsers = users.slice(PAGE_SIZE * currentPage, PAGE_SIZE * (currentPage + 1))

    const onNext = async () => {
        if (!env || isLoading || !hasMore) {
            return;
        }

        setIsLoadingMore(true);
        if ((currentPage + 1) * PAGE_SIZE >= users.length) {
            const newUsers = await usersService.getPage(env, users.map(u => u.id), {...params, searchMode: 'AutoBan'})
            setHasMore(!!newUsers?.length && newUsers.length === PAGE_SIZE);
            setUsers(prev => [...prev, ...(newUsers ?? [])]);
        }
        setIsLoadingMore(false);
        setCurrentPage(prev => prev + 1)
    };

    const onPrevious = () => {
        setHasMore(true);
        setCurrentPage(prev => prev - 1)
    }

    const reload = async () => {
        if (!env) {
            return;
        }

        const newUsers = await usersService.getPage(env, [], {...params, searchMode: 'AutoBan'});
        newUsers && setUsers(newUsers);
        setIsLoading(false);
        setCurrentPage(0)
    }

    const handleSearch = async () => {
        if (!env) {
            return;
        }
        reload()
    };

    const onClick = (id: string) => {
        window.open(`${RootRoutes.Profile}/${id}`, '_blank', 'rel=noopener noreferrer')
    }


    useEffect(() => {
        if (!env) {
            return;
        }


        setIsLoading(true);
        setTimeout(reload, 300);

    }, [env, settingsStore.selectedCountry?.countryCode]);


    if (isLoading) {
        return <div className="m-2 m-lg-3">Загружается...</div>
    }

    return (
        <div className="d-flex flex-column align-items-center m-2 m-lg-3" style={{maxHeight: '90vh'}}>
            <ProfileSearchParameters visibleParams={[SearchParams.Gender, SearchParams.CountryCode]}
                                     onSearch={handleSearch} params={params} setParams={setParams}/>

            {!users.length && 'Результатов нет'}
            <div className="w-75 d-flex flex-row justify-content-end flex-rowsticky-top mt-2">
                <Pager page={currentPage} hasMore={hasMore} onNext={onNext} onPrevious={onPrevious}/>
            </div>

            <ModerationUsersTable users={displayedUsers} isLoadingMore={isLoadingMore} onClick={onClick}/>
        </div>
    );
};
