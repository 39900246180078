import React, {useContext, useState} from 'react';
import {AdminActivityGenderStats, statsService} from "services";
import {AuthContext} from "contexts";
import {DatePicker} from "components";
import {statsStore} from "stores/statsStore";
import {settingsStore} from "stores";
import Select from "react-select";
import {DateTime} from "luxon";
import {GenderName, Genders, RootRoutes} from "enums";
import {observer} from "mobx-react";
import {Loader} from "components/Loader";
import {useNavigate} from "react-router-dom";
import {AdminActivityActionName, AdminActivityActions} from "enums/AdminActivityAction";


export const AdminStatsTable = observer(() => {
    const now = new Date(Date.now());
    const navigate = useNavigate();
    const {env} = useContext(AuthContext);

    const [stats, setStats] = useState<AdminActivityGenderStats[]>([]);
    const [startDate, setStartDate] = useState(
        new Date(now.getFullYear(), now.getMonth(), 1),
    );
    const [endDate, setEndDate] = useState(now);
    const [isLoading, setIsLoading] = useState(false);

    const getActivity = async () => {
        if (!statsStore.selectedAdmin?.userKey || !env) {
            return
        }
        const newStartDate = DateTime.fromJSDate(startDate).plus({day: 1}).toJSDate();
        const newEndDate = DateTime.fromJSDate(endDate).plus({day: 2}).toJSDate();
        setIsLoading(true)
        const stats = await statsService.getActivityStats(statsStore.selectedAdmin?.userKey, newStartDate, newEndDate, env)
        setStats(stats)
        setIsLoading(false)
    }

    const goToActivity = (g: Genders, a?: AdminActivityActions) => {
        const newEndDate = DateTime.fromJSDate(endDate).plus({day: 2}).toJSDate();
        navigate(`${RootRoutes.AdminActivityProfiles}?adminId=${statsStore.selectedAdmin?.userKey}&gender=${g}&startDate=${startDate}&endDate=${newEndDate}&activity=${a}`)
    }

    return <div className="d-flex flex-column align-items-center m-2 m-lg-3 overflow-auto" style={{height: '90vh'}}>
        <div className="w-75 d-flex flex-column align-items-end mt-4">
            <div className="row gy-2 gx-3 align-items-center">
                <div className="col-auto">
                    <DatePicker
                        label="Начало периода"
                        value={startDate}
                        onChange={setStartDate}
                    />
                </div>
                <div className="col-auto">
                    <DatePicker
                        label="Конец периода"
                        value={endDate}
                        onChange={setEndDate}
                    />
                </div>
                <div className="col-auto">
                    <Select
                        key={`select-${statsStore.admins.length}`}
                        options={statsStore.adminsSelect}
                        defaultValue={statsStore.selectedAdmin ? {
                            label: `${statsStore.selectedAdmin.name} (${statsStore.selectedAdmin.reviewedUserCount})`,
                            value: statsStore.selectedAdmin.userKey
                        } : undefined}
                        isOptionSelected={i => i.value === settingsStore.selectedCountry?.countryCode}
                        placeholder="Все админы"
                        isClearable={true}
                        onChange={i => statsStore.selectAdmin(i?.value)}
                        styles={{input: s => ({...s, minWidth: 200})}}
                    />
                </div>
                <div className="col-auto">
                    <button disabled={!statsStore.selectedAdmin || isLoading}
                            className="btn btn-primary d-flex flex-row align-items-center"
                            onClick={getActivity}><Loader isLoading={isLoading}/> Показать
                    </button>
                </div>
            </div>
            <div className="mb-4 mt-1">
                <small className="text-muted">Стартовая и конечная даты всегда включены</small>
            </div>
            {statsStore.selectedAdmin ?
                <table className="table table-hover table-bordered table-responsive">
                    <thead className="bg-white border-bottom">
                    <tr>
                        <th scope="col">Гендер</th>
                        {Object.keys(AdminActivityActions).map(a => <th key={`th-col-${a}`}
                                                                        scope="col">{AdminActivityActionName[a as AdminActivityActions]}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {stats.map(s => (
                        <tr key={s.gender}>
                            <td>{GenderName[s.gender]}</td>
                            {Object.keys(AdminActivityActions).map(a => {
                                const propertyName = a[0].toLowerCase() + a.slice(1)
                                return <td key={`td-col-${a}`}
                                           onClick={() => goToActivity(s.gender, a as AdminActivityActions)}
                                           className="text-decoration-underline" style={{cursor: 'pointer'}}>
                                    {(s as any)[propertyName]}
                                </td>
                            })}
                        </tr>
                    ))}
                    <tr>
                        <td className="fw-bold">Всего</td>
                        {Object.keys(AdminActivityActions).map(a => {
                            const propertyName = a[0].toLowerCase() + a.slice(1)
                            return <td key={`td-col-${a}`}>
                                {stats.reduce((prev, curr) => (curr as any)[propertyName] + prev, 0)}
                            </td>
                        })}
                    </tr>
                    </tbody>
                </table> :
                <p className="text-danger fw-bold">Нужно выбрать админа</p>
            }
        </div>
    </div>;
})