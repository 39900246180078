import {request} from "./request";
import {CountryData} from "interfaces";
import countries from 'assets/countries.json'
import {EnvSetup, getSupportUrl} from "enums/Envs";

export type Countries = typeof countries;

export const countriesService = {
    getAll: async (env: EnvSetup): Promise<CountryData[]> => {
        const response = await request<{ countries: CountryData[] }>({
            url: `${getSupportUrl(env)}/countries`,
            method: 'get',
        });
        return response?.countries?.map(c => {
            const code = c.countryCode as keyof Countries;
            const country = countries[code];
            return {
                countryCode: c.countryCode,
                name: country?.name ?? c.name,
                flag: country?.emoji ?? ''
            }
        }) ?? []
    }
}