import React, {FC, useContext, useEffect, useState} from "react";
import Select from "react-select";
import {settingsStore} from "stores";
import {GenderName, Genders, toStatusString, UserReviewTags, UserStatuses} from "enums";
import {Form} from "react-bootstrap";
import {DateTime} from "luxon";
import {setupServices} from "services";
import {AuthContext} from "contexts";

export enum SearchParams {
    Name,
    CountryCode,
    Gender,
    Age,
    Status,
    Tags
}

export interface ProfileSearchSearchParams {
    name?: string;
    countryCode?: string;
    gender?: number
    minBirthday?: Date
    maxBirthday?: Date
    status?: UserStatuses
    reviewTags?: UserReviewTags[]
}

interface Props {
    visibleParams?: SearchParams[],
    params: ProfileSearchSearchParams
    setParams: React.Dispatch<React.SetStateAction<ProfileSearchSearchParams>>
    onSearch: () => void
}

export const ProfileSearchParameters: FC<Props> = ({visibleParams, onSearch, params, setParams}) => {
    const {env} = useContext(AuthContext);
    const [reviewTags, setReviewTags] = useState<string[]>([]);
    const setAgeParams = (age: number) => {
        const now = DateTime.now();

        // Calculate the start and end years for the birthday
        const minBirthday = now.minus({years: age + 1}).year;
        const maxBirthday = now.minus({years: age}).year;

        // Create start and end dates for the birthday range
        const minBirthdayDate = now.set({year: minBirthday}).toJSDate();
        const maxBirthdayDate = now.set({year: maxBirthday}).toJSDate();

        return setParams(pr => ({...pr, minBirthday: minBirthdayDate, maxBirthday: maxBirthdayDate}))
    }

    const isParamVisible = (p: SearchParams) => {
        return !visibleParams || visibleParams?.includes(p)
    }

    useEffect(() => {
        if (env) {
            setupServices.getAllReviewTags(env).then(tags => tags && setReviewTags(tags))
        }
    }, [env])


    return <div className="row gy-2 gx-3 align-items-center">
        {isParamVisible(SearchParams.Name) &&
            <div className="col">
                <label className="visually-hidden" htmlFor="autoSizingInput">Имя</label>
                <input type="text" className="form-control" id="autoSizingInput"
                       placeholder="Имя пользователя" value={params.name}
                       onChange={e => setParams(pr => ({...pr, name: e.target.value}))}/>
            </div>}
        {isParamVisible(SearchParams.Gender) &&
            <div className="col">
                <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
                <select className="form-select"
                        onChange={e => setParams(pr => ({...pr, gender: +(e.target.value)}))}
                        defaultValue={0}
                >
                    <option value={0}>Все гендеры</option>
                    {Object.values(Genders).map(gender => (
                        <option key={`gender-${gender}`} value={gender}>{GenderName[gender]}</option>))}
                </select>
            </div>}
        {isParamVisible(SearchParams.Status) &&
            <div className="col">
                <label className="visually-hidden" htmlFor="autoSizingSelect">Статус</label>
                <select className="form-select"
                        defaultValue="all"
                        onChange={e => setParams(pr => ({
                            ...pr,
                            status: e.target.value === "all" ? undefined : e.target.value as UserStatuses
                        }))}>
                    <option value={"all"}>Все статусы</option>
                    {Object.values(UserStatuses).map(s => (
                        <option key={`status-${s}`} value={s}>{toStatusString(s)}</option>))}
                </select>
            </div>}
        {isParamVisible(SearchParams.CountryCode) &&
            <div className="col">
                <label className="visually-hidden" htmlFor="autoSizingSelect">Страна</label>
                <Select options={settingsStore.countriesSelect}
                        isOptionSelected={i => i.value === params.countryCode}
                        placeholder="Все страны"
                        isClearable={true}
                        onChange={i => setParams(pr => ({...pr, countryCode: i?.value}))}
                        styles={{input: s => ({...s, minWidth: 150})}}
                />
            </div>}
        {isParamVisible(SearchParams.Age) &&
            <div className="col">
                <Form.Control type="number" placeholder="Возраст" onChange={e => setAgeParams(+e.target.value)}/>
            </div>}
        {isParamVisible(SearchParams.Tags) &&
            <div className="col-3">
                <label className="visually-hidden" htmlFor="autoSizingSelect">Тег</label>
                <Select
                    isMulti
                    options={reviewTags.map(t => ({label: t, value: t}))}
                    placeholder="Все теги"
                    isClearable={true}
                    isOptionSelected={i => params.reviewTags?.includes(i.value as UserReviewTags) ?? false}
                    onChange={i => setParams(p => ({...p, reviewTags: i.map(v => v.value as UserReviewTags)}))}
                    styles={{input: s => ({...s, minWidth: 150})}}
                />
            </div>
        }
        <div className="col-auto">
            <button className="btn btn-primary" onClick={onSearch}>Submit</button>
        </div>
    </div>
}