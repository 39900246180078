import {useEffect, useState} from 'react';
import {axiosInstance, getRefreshUrl, loginService} from 'services';
import {storage} from "utils";
import {useNavigate} from "react-router-dom";
import {Apps, RootRoutes} from "../enums";


export const useInterceptors = (reset: () => void, app?: Apps) => {
    const navigate = useNavigate();

    const [isInterceptorsConfigured, setIsInterceptorsConfigured] =
        useState(false);

    useEffect(() => {
        let isRefreshing = false;
        let isSuccessfullyRefreshed = false;

        const interceptor1 = axiosInstance.interceptors.request.use(async config => {
            const token = storage.getToken()
            config.headers = {
                ...config.headers,
                ...(token ? {Authorization: `Bearer ${token}`} : {}),
            };

            return config;
        });

        const interceptor2 = axiosInstance.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;
                isSuccessfullyRefreshed = false;

                if (error.response?.status === 401) {
                    if (isRefreshing) {
                        if (!app || error.response?.config.url === getRefreshUrl(app)) {
                            return Promise.reject(error);
                        }

                        while (isRefreshing) {
                            await new Promise(resolve =>
                                setTimeout(() => resolve(true), 1000),
                            );
                        }

                        if (isSuccessfullyRefreshed) {
                            return axiosInstance(originalRequest);
                        }
                    } else {
                        isRefreshing = true;
                        const token = storage.getRefresh();
                        const userId = storage.getUserId();
                        const app = storage.getApp();
                        if (token && userId && app) {
                            const response = await loginService.refresh(token, userId, app);
                            if (response && response.refresh && response.token && response.user) {
                                storage.setToken(response.token)
                                storage.setRefresh(response.refresh)
                                isSuccessfullyRefreshed = true;
                                isRefreshing = false;
                                return axiosInstance(originalRequest);
                            }
                        }
                        
                        isRefreshing = false;
                        storage.clearAll();
                        reset();
                        navigate(RootRoutes.Login)
                    }
                }

                return Promise.reject(error);
            },
        );

        setIsInterceptorsConfigured(true);
        return () => {
            axiosInstance.interceptors.request.eject(interceptor1);
            axiosInstance.interceptors.request.eject(interceptor2);
        };
    }, [app]);

    return {isInterceptorsConfigured, setIsInterceptorsConfigured};
};
