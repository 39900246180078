import React, {FC} from 'react';
import {ModerationUser} from "interfaces";
import {ModerationHint, ModerationRow} from "components";
import {ModerationSection} from "components/ModerationSection";

interface Props {
    user: ModerationUser
}

export const ModerationGeo: FC<Props> = ({user}) => {
    const isSuspect = user.countryCode && user.ipCountryCode && user.countryCode !== user.ipCountryCode;
    const country = user.countryFlag && user.countryName ? `${user.countryFlag} ${user.countryName}` : user.countryCode
    const city = user.countryName && user.city ? `, ${user.city ?? ''}` : user.city ?? ''
    const hasNewIP = user.ipAddresses?.length > 0;

    return <ModerationSection label="Гео">
        <div className="row">
            <div className="col">
                <ModerationRow label="Локация" value={country + city} isInsideTable/>
                {!hasNewIP && <ModerationRow label="Страна по IP" value={user.ipCountryCode} isInsideTable/>}
                {isSuspect && !hasNewIP &&
                    <ModerationHint className="alert-warning">У юзера отличается страна по IP адресу и
                        координатам</ModerationHint>}
                <ModerationRow label="Радиус поиска" value={user.maxDistanceFilter ?? 'Весь мир'} isInsideTable/>
                {user.discoveryCity && <ModerationRow label="Discovery город"
                                                      value={`${user.discoveryCity.countryFlag} ${user.discoveryCity.countryName}, ${user.discoveryCity.cityName}`}
                                                      isInsideTable/>}
            </div>
            <div className="col">
                <ModerationRow label="Latitude" value={user.latitude} isInsideTable/>
                <ModerationRow label="Longtitude" value={user.longitude} isInsideTable/>
            </div>
        </div>
    </ModerationSection>

}
