export enum UserStatuses {
    Active = 'Active',
    Paused = 'Paused',
    Banned = 'Banned',
    Test = 'Test',
    Support = 'Support',
    Deleted = 'Deleted',
    TemporaryUnbanned = 'TemporaryUnbanned',
    NeedsVerification = 'NeedsVerification',
    Suspicious = 'Suspicious',
    Archived = 'Archived',
    BanArchived = 'BanArchived',
    ShadowBan = 'ShadowBan'
}

export const UserStatusEmojis: Record<UserStatuses, string> = {
    [UserStatuses.Active]: '⚪',
    [UserStatuses.Test]: '🟡',
    [UserStatuses.Support]: '👮',
    [UserStatuses.Deleted]: '🗑️',
    [UserStatuses.Banned]: '🛑',
    [UserStatuses.Suspicious]: '☢️️',
    [UserStatuses.Paused]: '⏸️️',
    [UserStatuses.TemporaryUnbanned]: '❓',
    [UserStatuses.NeedsVerification]: '🥷',
    [UserStatuses.Archived]: '🗃️',
    [UserStatuses.BanArchived]: '🗃️🛑',
    [UserStatuses.ShadowBan]: '⚫',
}

export const UserStatusDesc: Record<UserStatuses, string> = {
    [UserStatuses.Active]: 'Активный',
    [UserStatuses.Test]: 'Тестовый',
    [UserStatuses.Support]: 'Админ',
    [UserStatuses.Deleted]: 'Удаленный',
    [UserStatuses.Banned]: 'Заблокированный',
    [UserStatuses.Suspicious]: 'Под️озрительный',
    [UserStatuses.Paused]: 'На паузе',
    [UserStatuses.TemporaryUnbanned]: 'Временно разблокированный',
    [UserStatuses.NeedsVerification]: 'Требует верификации',
    [UserStatuses.Archived]: 'В архиве',
    [UserStatuses.BanArchived]: 'Бан в архиве',
    [UserStatuses.ShadowBan]: 'Shadow Бан',
}

export const toStatusString = (status: UserStatuses) => `${UserStatusEmojis[status]} ${UserStatusDesc[status]}`