import React, {FC, useContext, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import {usersService} from "services";
import {AuthContext} from "contexts";
import {ModerationUser} from "interfaces";
import {isDeleteProfileAndGiftsSupported} from "enums";

interface Props {
    userKey: string;
    onUserChange: (user: ModerationUser) => void;
}

export const PremiumGiftButton: FC<Props> = ({userKey, onUserChange}) => {
    const {env, user} = useContext(AuthContext);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [days, setDays] = useState(0);


    const handleClose = () => {
        setDays(0);
        setIsModalVisible(false);
    }

    const handleGift = async () => {
        if (!env) {
            return;
        }
        await usersService.giftSubscription(userKey, env, days)
        const newUser = await usersService.getById(userKey, env)
        newUser && onUserChange(newUser);
        handleClose();
    }

  
    if (!user?.isSuperAdmin || !env || !isDeleteProfileAndGiftsSupported(env)) {
        return null;
    }


    return <>
        <Button
            onClick={() => setIsModalVisible(true)}
            variant="outline-warning">
            Подарить Premium
        </Button>
        <Modal show={isModalVisible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Подарить Премиум</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Количество дней</Form.Label>
                        <Form.Control type="number" placeholder="0" onChange={e => setDays(+e.target.value)}/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Закрыть
                </Button>
                <Button disabled={days < 1} variant="primary" onClick={handleGift}>
                    Подарить
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

