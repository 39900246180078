import React, {useCallback, useEffect, useState} from "react";

import type {IAfterGuiAttachedParams, IDoesFilterPassParams,} from "ag-grid-community";
import type {CustomFilterProps} from "ag-grid-react";
import {useGridFilter} from "ag-grid-react";
import {GenderName, Genders} from "enums";
import {Button} from "react-bootstrap";

export const AbuseGenderFilter = ({model, onModelChange}: CustomFilterProps) => {
    const [closeFilter, setCloseFilter] = useState<(() => void) | undefined>();
    const [selectedGenders, setSelectedGenders] = useState<Genders[] | null>(model);

    const doesFilterPass = useCallback((params: IDoesFilterPassParams) => {
        return !!selectedGenders && selectedGenders.some(item => params.data.genders.includes(item));
    }, [selectedGenders]);

    const afterGuiAttached = useCallback(
        ({hidePopup}: IAfterGuiAttachedParams) => {
            setCloseFilter(() => hidePopup);
        },
        [],
    );

    // register filter handlers with the grid
    useGridFilter({
        doesFilterPass,
        afterGuiAttached,
    });

    useEffect(() => {
        setSelectedGenders(model ?? []);
    }, [model]);

    const onClick = () => {
        if (closeFilter) {
            closeFilter();
        }
    };

    return (
        <div className="p-3 d-flex flex-column bg-white">
            <label className={'fw-bold'}>Гендеры</label>
            <div className="col-sm-10 mt-2">
                {Object.values(Genders).map(gender =>
                    <div key={gender} className="form-check">
                        <input className="form-check-input"
                               type="checkbox" value="" id={gender}
                               checked={selectedGenders?.includes(gender)}
                               onChange={() => {
                                   const newValue = selectedGenders?.includes(gender) ? selectedGenders.filter(pg => pg !== gender) : [...(selectedGenders ?? []), gender];
                                   const agNewValue = newValue.length ? newValue : null;
                                   setSelectedGenders(agNewValue);
                                   onModelChange(agNewValue);
                               }}/>
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            {GenderName[gender]}
                        </label>
                    </div>)}
            </div>
            <Button size={'sm'} variant={'secondary'} onClick={onClick} className={'mt-2'}>Готово</Button>
        </div>
    );
};