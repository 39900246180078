import * as semver from 'semver'
import {EnvSetup} from "enums/Envs";

export enum Apps {
    Instinct = 'Instinct',
    Bloom = 'Bloom',
    Flava = 'Flava',
    Spark = 'Spark',
}

export const AppsUrls = {
    [Apps.Instinct]: 'https://api.instinctdate.app',
    [Apps.Bloom]: 'https://api.bloomdates.com/',
    [Apps.Flava]: 'https://api.flava.app/',
    [Apps.Spark]: 'https://api.sparkdate.app/',
};

export const AppUrlsTest = {
    [Apps.Instinct]: 'https://api-test.instinctdate.app',
    [Apps.Bloom]: 'https://api-test.bloomdates.com/',
    [Apps.Flava]: 'https://api-test.flava.app/',
    [Apps.Spark]: 'https://api-test.sparkdate.app/',
}

export const areQuestionsSupported = (app: Apps) => app === Apps.Spark;

export const isRequiredVerificationSupprted = (app: Apps, version: string) => {
    if (app === Apps.Flava) {
        return version && semver.gt(version, '1.6.42')
    } else if (app === Apps.Instinct) {
        return version && semver.gt(version, '7.2.3')
    } else {
        return false
    }
}

export const isWatermarkSupported = (app: Apps, version: string) => {
    if (app === Apps.Flava) {
        return version && semver.gt(version, '1.6.42')
    } else if (app === Apps.Instinct) {
        return version && semver.gte(version, '7.2.8')
    } else {
        return false
    }
}

export const isBoostAndSparksGiftsSupported = (app: Apps) => {
    return app === Apps.Flava || app === Apps.Instinct || app === Apps.Spark;
}

export const isDeleteProfileAndGiftsSupported = (env: EnvSetup) => {
    return true;
}

export const areBoostsAvailable = (app: Apps) => {
    return app === Apps.Instinct || app === Apps.Flava;
}


export const areGreetingsAvailable = (app: Apps) => {
    return app === Apps.Instinct;
}

export const isModerationPackAvailable = (env?: EnvSetup) => {
    return env?.app === Apps.Instinct || env?.app === Apps.Flava || env?.app === Apps.Spark
}

export const isShadowBanSupported = (env?: EnvSetup) => {
    return env?.app === Apps.Flava || env?.app === Apps.Instinct || env?.app === Apps.Spark;
}

export const isNoFaceButtonSupported = (env?: EnvSetup) => {
    return env?.app === Apps.Instinct || env?.app === Apps.Flava
}

export const isDeleteAllAvailable = (env?: EnvSetup) => {
    return env?.app === Apps.Instinct || env?.app === Apps.Flava || env?.app === Apps.Spark;
}

export const isPatternsManagementSupported = (env?: EnvSetup) => {
    return (env?.app === Apps.Flava || env?.app === Apps.Instinct || env?.app === Apps.Spark);
}

