import React, {FC} from "react";
import {Loader} from "components/Loader";

import {isReviewTagSuspest, toStatusString, UserStatuses} from "enums";
import {toDateString} from "utils";
import {ModerationUser} from "interfaces";
import {ModerationPic} from "components/ModerationUserCard/ModerationPhotos/ModerationPic";
import {PhotoProvider} from "react-photo-view";
import {AboutInTable} from "components/ModerationUsersTable/AboutInTable";

interface Props {
    isLoadingMore: boolean
    users: ModerationUser[]
    onClick: (id: string) => void
}

export const ModerationUsersTable: FC<Props> = ({isLoadingMore, users, onClick}) => {
    return <div className="overflow-auto w-100 d-flex flex-column align-items-center mt-2">
        <Loader isLoading={isLoadingMore}/>
        <div className="w-75">
            <table className="table table-hover">
                <thead className="bg-white border-bottom">
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Фото</th>
                    <th scope="col">Информация</th>
                    <th scope="col">Теги</th>
                    <th scope="col">Гео</th>
                    <th scope="col">Описание</th>
                </tr>
                </thead>
                <tbody>
                {users.map(u => {
                    const photo = u.photos?.[0];
                    return (
                        <tr key={u.id} onClick={() => onClick(u.id)} style={{cursor: 'pointer'}}>
                            <td><small>{u.id}</small></td>
                            <td className="flex flex-column" style={{width: 120}}>
                                {photo &&
                                    <PhotoProvider>
                                        <ModerationPic
                                            key={photo.key}
                                            height={100}
                                            userId={u.id}
                                            pic={photo}
                                            className={'rounded'}
                                            objectFit={"cover"}
                                            isInTable={true}/>
                                    </PhotoProvider>
                                }
                            </td>
                            <td>
                                <p>
                                    {u.name}, {u.age} {u.isVerified && <small>✅</small>} {u.hasPremium &&
                                    <small>⭐</small>} <br/>
                                    <small>{toStatusString(u.status)} {u.statusHistory && u.status === UserStatuses.Banned ? u.statusHistory[u.statusHistory.length - 1].reason : ''}</small><br/>
                                </p>
                                <small>Регистрация: {u.registrationDate && toDateString(u.registrationDate, true)}</small><br/>
                                <small>Онлайн: {u.onlineDate && toDateString(u.onlineDate, true)}</small>
                            </td>
                            <td> {u.reviewTags.map((tag, i) =>
                                <div key={u.id + tag + i}
                                     className={`mx-1 badge ${isReviewTagSuspest(tag.tag) ? 'bg-danger' : 'bg-dark'}`}>#{tag.tag}</div>
                            )}</td>
                            <td>{u.countryFlag} {u.countryName}{u.city && <br/>}{u.city}</td>
                            <td><AboutInTable user={u}/></td>
                        </tr>)
                })}
                </tbody>
            </table>
        </div>
    </div>
}