import React, {useContext, useState} from 'react';
import {AdminDayReviewStats, statsService} from "services";
import {AuthContext} from "contexts";
import {DatePicker} from "components";
import {DateTime} from "luxon";
import {GenderName, Genders} from "enums";
import {observer} from "mobx-react";
import {Loader} from "components/Loader";
import {toDayDateString} from "utils";
import Select from "react-select";


export const ReviewStatsPage = observer(() => {
    const now = new Date(Date.now());
    const {env} = useContext(AuthContext);

    const [genders, setGenders] = useState([Genders.Male, Genders.Female]);
    const [stats, setStats] = useState<AdminDayReviewStats[]>([]);
    const [startDate, setStartDate] = useState(
        new Date(now.getFullYear(), now.getMonth(), 1),
    );
    const [endDate, setEndDate] = useState(now);
    const [isLoading, setIsLoading] = useState(false);

    const allGenders = Object.values(Genders).map(g => ({value: g, label: GenderName[g]}));
    const genderValues = genders.map(g => Object.keys(Genders)[Object.values(Genders).indexOf(g)] as Genders)

    const getActivity = async () => {
        if (!env) {
            return
        }
        const newEndDate = DateTime.fromJSDate(endDate).plus({day: 2}).toJSDate();
        setIsLoading(true)
        const stats = await statsService.getReviewStats(startDate, newEndDate, env)
        setStats(stats)
        setIsLoading(false)
    }

    return <div className="d-flex flex-column align-items-center m-2 m-lg-3 overflow-auto" style={{height: '90vh'}}>
        <div className="w-75 d-flex flex-column align-items-center mt-4">
            <div className="row gy-2 gx-3 align-items-center mb-4">
                <div className="col-auto">
                    <DatePicker
                        label="Начало периода"
                        value={startDate}
                        onChange={setStartDate}
                    />
                </div>
                <div className="col-auto">
                    <DatePicker
                        label="Конец периода"
                        value={endDate}
                        onChange={setEndDate}
                    />
                </div>
                <div className="col-auto">
                    <Select
                        defaultValue={allGenders.filter(g => genders.includes(g.value))}
                        isMulti
                        options={allGenders}
                        placeholder="Все гендеры"
                        isClearable={false}
                        onChange={i => setGenders(i.map(v => v.value))}
                    />
                </div>
                <div className="col-auto">
                    <button disabled={isLoading}
                            className="btn btn-primary d-flex flex-row align-items-center"
                            onClick={getActivity}><Loader isLoading={isLoading}/> Показать
                    </button>
                </div>
            </div>
            <table className="table table-bordered table-responsive">
                <thead className="bg-white border-bottom">
                <tr>
                    <th scope="col">Дата</th>
                    <th scope="col">Гендер</th>
                    <th scope="col">Отправлено на ревью</th>
                    <th scope="col">По тегам</th>
                    <th scope="col">Регистраций</th>
                    <th scope="col">Удалено</th>
                    <th scope="col">Отправлено на потом</th>
                    <th scope="col">Автобан</th>
                </tr>

                </thead>
                <tbody>
                {stats.map(s => (
                    <React.Fragment key={s.date?.toString()}>
                        {genders.map((g, i) => {
                            const value = Object.keys(Genders)[Object.values(Genders).indexOf(g)] as Genders;
                            const tagKeys = Object.keys(s.sentToReviewByTags)
                            return <tr key={`td-col-${g}`}>
                                {i === 0 &&
                                    <td rowSpan={genders.length + 1}>{s.date && toDayDateString(s.date, false)}</td>}
                                <td>{GenderName[g]}</td>
                                <td>{s.sentToReview?.[value] ?? 0}</td>
                                <td>
                                    <div>Всего
                                        - {tagKeys.reduce((pr, curr) => (s.sentToReviewByTags?.[curr][value] ?? 0) + pr, 0)}</div>
                                    {tagKeys.map(tk => ({k: tk, v: s.sentToReviewByTags?.[tk][value]})).map(o => (
                                        <div key={o.k}>{o.k} - {o.v ?? 0}</div>))}</td>
                                <td>{s.registered?.[value] ?? 0}</td>
                                <td>{s.deleted?.[value] ?? 0}</td>
                                <td>{s.postponed?.[value] ?? 0}</td>
                                <td>{s.autoBanned?.[value] ?? 0}</td>
                            </tr>
                        })}
                        <tr className="table-info">
                            <td>Всего</td>
                            <td>{genderValues.reduce((prev, curr) => (s.sentToReview?.[curr] ?? 0) + prev, 0) ?? 0}</td>
                            <td>{genderValues.reduce((prev, curr) => {
                                const tagKeys = Object.keys(s.sentToReviewByTags)
                                return (tagKeys.reduce((pr, currt) => (s.sentToReviewByTags?.[currt][curr] ?? 0) + pr, 0) ?? 0) + prev
                            }, 0)}
                            </td>
                            <td>{genderValues.reduce((prev, curr) => (s.registered?.[curr] ?? 0) + prev, 0) ?? 0}</td>
                            <td>{genderValues.reduce((prev, curr) => (s.deleted?.[curr] ?? 0) + prev, 0) ?? 0}</td>
                            <td>{genderValues.reduce((prev, curr) => (s.postponed?.[curr] ?? 0) + prev, 0) ?? 0}</td>
                            <td>{genderValues.reduce((prev, curr) => (s.autoBanned?.[curr] ?? 0) + prev, 0) ?? 0}</td>
                        </tr>
                    </React.Fragment>
                ))}
                </tbody>
            </table>

        </div>
    </div>;
})