export enum VerificationStatus {
    None = 0,
    Pending = 1,
    Verified = 2,
    Denied
}

export const VerificationStatusEmoju: Record<VerificationStatus, string> = {
    [VerificationStatus.None]: '',
    [VerificationStatus.Pending]: '⏱️ В ожидании',
    [VerificationStatus.Verified]: '✅ Успешная',
    [VerificationStatus.Denied]: '❌ Отклонена',
}

export const VerificationStatusEvents: Record<VerificationStatus, string> = {
    [VerificationStatus.None]: 'none',
    [VerificationStatus.Pending]: 'in progress',
    [VerificationStatus.Verified]: 'verified',
    [VerificationStatus.Denied]: 'denied',
}
