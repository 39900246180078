import React, {FC} from 'react';
import {ModerationUser} from "interfaces";
import {ModerationRow} from "components";
import {SubscriptionsHistory} from "./SubscriptionsHistory";
import {ModerationSection} from "components/ModerationSection";

interface Props {
    user: ModerationUser
}

export const ModerationPremium: FC<Props> = ({user}) => {
    if (!user.hasPremium && !user.subscriptionHistory.length) {
        return null
    }
    return <ModerationSection label={`Премиум ${user.hasPremium ? '⭐' : ''}`}>
        <ModerationRow label="Есть премиум" value={user.hasPremium} isInsideTable/>
        <ModerationRow label="Тип премиума" value={user.premiumType} isInsideTable/>
        <ModerationRow label="Премиум активирован" value={user.premiumStartDate} isInsideTable/>
        {user.premiumStartDate &&
            <ModerationRow label="Количество циклов премиума" value={user.renewCount + 1} isInsideTable/>}
        {!!user.energy && <>
            <hr/>
            <ModerationRow label="Количество энергии" value={user.energy.amount} isInsideTable/>
            <ModerationRow label="Обновление энергии" value={user.energy.refillDate} isInsideTable/>
        </>}
        <SubscriptionsHistory user={user}/>
    </ModerationSection>
}
