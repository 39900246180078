import {Button, Container, Form, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {Apps, isSupportedRoute, RootRoutes} from 'enums';
import {AuthContext} from 'contexts';
import {useContext, useState} from 'react';
import {AppDropdown} from 'pages';

export const LoginPage = () => {
    let navigate = useNavigate();
    let auth = useContext(AuthContext);

    const [isTest, setIsTest] = useState(false);
    const [token, setToken] = useState('');
    const [app, setApp] = useState(Apps.Instinct);

    const handleSubmit = async () => {
        const user = await auth.signin(token, app, isTest);
        if (user) {
            navigate((isSupportedRoute(RootRoutes.Moderation, app) ? RootRoutes.Moderation : RootRoutes.Profile), {replace: true});
        }
    };

    return (
        <div className="bg-primary vh-100 d-flex align-items-center justify-content-center">
            <Container fluid="sm">
                <Row>
                    <div className="col-1 col-sm-2 col-lg-3 col-xl-4"/>
                    <div className="col-10 col-sm-8 col-lg-6 col-xl-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className="text-white-50">Приложение</Form.Label>
                                <AppDropdown app={app} onSelect={setApp}/>
                                <div className="form-check mt-2">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                           onChange={() => setIsTest(prev => !prev)}/>
                                    <label className="form-check-label text-white-50" htmlFor="flexCheckDefault">
                                        Тестовая среда
                                    </label>
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label className="text-white-50">Токен</Form.Label>
                                <Form.Control
                                    name="username"
                                    type="password"
                                    placeholder="Токен"
                                    value={token}
                                    onChange={e => setToken(e.target.value)}
                                />
                            </Form.Group>
                            <Button
                                variant="light"
                                className="w-100 mt-3"
                                onClick={handleSubmit}>
                                Войти
                            </Button>
                        </Form>
                        <div className="col-1 col-sm-2 col-lg-3 col-xl-4"/>
                    </div>
                </Row>
            </Container>
        </div>
    );
};
