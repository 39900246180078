import React, {FC, useContext} from 'react';
import {ModerationUser} from "interfaces";
import {AuthContext} from "contexts";
import {toDateString} from "utils";

interface Props {
    user: ModerationUser
}

export const SubscriptionsHistory: FC<Props> = ({user}) => {
    const {user: myUser} = useContext(AuthContext);

    if (!myUser?.isSuperAdmin || !user.subscriptionHistory?.length) {
        return null;
    }

    return <>
        <hr/>
        <table className="table-light w-100">
            <thead>
            <tr>
                <td className="fw-semibold" style={{paddingRight: 10}}>Тип</td>
                <td className="fw-semibold" style={{paddingRight: 10}}>Начало</td>
                <td className="fw-semibold" style={{paddingRight: 10}}>Окончание</td>
                <td className="fw-semibold">Количество дней</td>
            </tr>
            </thead>
            <tbody>
            {user.subscriptionHistory.map(h => {
                    const timeDiff = h.expiresDate.getTime() - h.date.getTime();
                    const daysDiff = Math.round(timeDiff / (1000 * 3600 * 24));
                    return <tr key={h.date.toString()}>
                        <td className="fw-semibold" style={{paddingRight: 10}}>{h.provider}</td>
                        <td style={{paddingRight: 10}}>{toDateString(h.date, false)}</td>
                        <td style={{paddingRight: 10}}>{toDateString(h.expiresDate, false)}</td>
                        <td>{daysDiff}</td>
                    </tr>
                }
            )}
            </tbody>
        </table>
    </>
}

