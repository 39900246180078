export enum BanReasons {
    LowQualityProfile = 'low_quality_profile',
    Sex = "sex",
    Scam = "scam",
    Nudes = "nudes",
    Rude = "rude",
    Underage = "underage",
    Fake = "fake",
    Duplicate = 'duplicate',
    InvalidGender = 'invalid_gender',
    Drugs = "drugs",
    Other = "other"

}

export const BanReasonsLabels: Record<BanReasons, string> = {
    [BanReasons.Nudes]: "🫣 Нюдсы",
    [BanReasons.Rude]: "🤬 Грубость",
    [BanReasons.Scam]: "🤑 Скам",
    [BanReasons.Sex]: "💋 Секс-услуги",
    [BanReasons.Underage]: "👶🏻 Несовершеннолетний",
    [BanReasons.Fake]: "🥸 Фейк",
    [BanReasons.Drugs]: "😵 Наркотики",
    [BanReasons.InvalidGender]: "🙅‍ Неправильный гендер",
    [BanReasons.Duplicate]: "🎭 Дубликат профиля",
    [BanReasons.LowQualityProfile]: "🫠 Плохое качество профиля",
    [BanReasons.Other]: "🤔 Другое",
}