export enum Moods {
    Friends = '1',
    Love = '2',
    Casual = '3',
    Chatting = '13',
    Secrets = '14',
    Support = '15',
    LanguageExchange = '17',
    Sexting = '9',
}

export const MoodName = {
    [Moods.Friends]: 'Друзья',
    [Moods.Love]: 'Любовь',
    [Moods.Casual]: 'Отношения',
    [Moods.Chatting]: 'Общение',
    [Moods.Secrets]: 'Секреты',
    [Moods.Support]: 'Поддержка',
    [Moods.LanguageExchange]: 'Языки',
    [Moods.Sexting]: 'Флирт',
};
