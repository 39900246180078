import React, {FC, useContext, useEffect, useState} from 'react';
import {Button, Container, Form, Modal, Row} from 'react-bootstrap';
import {DatePicker} from 'components';
import {ModerationUser} from 'interfaces';
import {AuthContext} from '../../../contexts';
import {usersService} from '../../../services';
import {GenderName, Genders} from "enums";

interface BirthdayChangeInputProps {
    user: ModerationUser;
    onChange?: (user: ModerationUser) => void;
}

export const UserUpdateModal: FC<BirthdayChangeInputProps> = ({
                                                                  user,
                                                                  onChange,
                                                              }) => {
    const {env} = useContext(AuthContext);

    const [isOpen, setIsOpen] = useState(false);
    const [birthday, setBirthday] = useState(user.birthday);
    const [name, setName] = useState(user.name);
    const [gender, setGender] = useState(user.gender);

    const onSave = async () => {
        if (!env) {
            return;
        }

        const newUser = await usersService.update(
            {id: user.id, name, birthday, gender},
            env,
        );
        if (newUser && onChange) {
            onChange(newUser);
            setIsOpen(false);
        }
    };

    useEffect(() => {
        setName(user.name);
        setBirthday(user.birthday);
    }, [user]);

    return (
        <>
            <Button onClick={() => setIsOpen(true)}
                    variant="outline-primary">
                Редактировать
            </Button>
            <Modal show={isOpen} onHide={() => setIsOpen(false)} dialogClassName='modal-50w'>
                <Modal.Header closeButton>
                    <Modal.Title>Редактировать профиль</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Form.Label className="col-sm-2 col-form-label">
                                Дата рождения
                            </Form.Label>
                            <div className="col-sm-10 d-flex flex-row">
                                <DatePicker
                                    type="control"
                                    label=""
                                    value={birthday}
                                    onChange={d => setBirthday(d)}
                                />
                            </div>
                        </Row>
                        <Row className="mt-1">
                            <Form.Label className="col-sm-2 col-form-label">Имя</Form.Label>
                            <div className="col-sm-10 d-flex flex-row">
                                <Form.Control
                                    placeholder="Имя"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </Row>
                        <Row className="mt-1">
                            <Form.Label className="col-sm-2 col-form-label">Пол</Form.Label>
                            <div className="col-sm-10 d-flex flex-row">
                                <Form.Select value={gender} aria-label="Пол"
                                             onChange={e => setGender(e.target.value as Genders)}>
                                    {Object.values(Genders).map(g => <option key={g}
                                                                             value={g}>{GenderName[g]}</option>)}
                                </Form.Select>
                            </div>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsOpen(false)}>
                        Закрыть
                    </Button>
                    <Button variant="primary" type="submit" onClick={onSave}>
                        Обновить
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
