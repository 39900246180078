import React, {FC, useContext, useState} from "react";
import {ModerationHint, ModerationRow} from "components";
import {ModerationReport} from "interfaces";
import {ModerationSection} from "components/ModerationSection";
import {Button, Modal} from "react-bootstrap";
import {isModerationPackAvailable, UserStatuses} from "enums";
import {AuthContext} from "contexts";
import {UserChatMessage, usersService} from "services";
import {ModerationChat} from "components/ModerationUserCard/components/ModerationChats/ModerationChat";

interface Props {
    userKey: string;
    userName: string;
    label: string;
    reports: ModerationReport[];
    reportsCount: number;
    warning: {
        minCount: number;
        label: string;
    }
}

export const ModerationReports: FC<Props> = ({label, userName, reportsCount, reports, warning, userKey}) => {
    const {env} = useContext(AuthContext);

    const hasReports = reportsCount > 0;
    const hasWarning = warning.minCount <= reportsCount;

    const [reportUser, setReportUser] = useState<{ key: string; status: UserStatuses } | null>();
    const [chatMessages, setChatMessages] = useState<UserChatMessage[] | null>(null);

    const showChat = async (reportUserKey: string, reportKey: string, reporterStatus: UserStatuses) => {
        if (!env) {
            return
        }
        const messages = await usersService.getChat(env, {user1: reportUserKey, user2: userKey, reportKey})
        setChatMessages(messages ?? null)
        setReportUser({key: reportUserKey, status: reporterStatus})
    }

    const onClose = () => {
        setChatMessages(null)
        setReportUser(null)
    }

    if (!hasReports) {
        return null
    }

    return <ModerationSection label={label}>
        <ModerationRow label="Количество" value={reportsCount} isInsideTable/>
        <ModerationRow label="Причины" isInsideTable value={
            <>
                {reports?.map(r =>
                    <div className="d-flex align-items-center" key={r.reporterKey}>
                        {r.source}: {r.reason}
                        <span className="fw-normal">
                            (<a href={`profile/${r.reporterKey}`} target="_blank"
                                rel="noreferrer">{r.reporterKey}</a>,{' '}
                            {r.reporterStatus})
                        </span>
                        {isModerationPackAvailable(env) && r.source === 'chat' &&
                            <a href="#"
                               className="mx-2 d-flex align-items-center text-black-50 text-decoration-underline"
                               style={{cursor: 'pointer'}}
                               onClick={(event) => {
                                   event.preventDefault();
                                   showChat(r.reporterKey, r.complaintKey, r.reporterStatus)
                               }}>
                                Открыть чат
                            </a>
                        }
                    </div>
                )}
            </>}/>
        {hasWarning && <ModerationHint>{warning.label}</ModerationHint>}
        <Modal show={!!chatMessages} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Чат</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {reportUser &&
                    <div className="fw-normal mb-3">
                        Пользователь:{' '}
                        <a href={`profile/${reportUser.key}`} target="_blank"
                           rel="noreferrer">{reportUser.key}</a> ({reportUser.status})
                    </div>
                }
                <ModerationChat userName={userName} messages={chatMessages ?? []} isMyMessageInverted={true}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    </ModerationSection>
}