import {Apps} from "./Apps";

export enum RootRoutes {
    Login = '/login',
    Moderation = '/moderation',
    Verifications = '/verifications',
    Profile = '/profile',
    AdminStatsGraph = '/stats/admin-graph',
    UsersStats = '/user-stats',
    TempUnbanned = '/temporary-unbanned',
    AutoBanned = '/auto-banned',
    Setup = '/setup',
    Admins = '/admins',
    BlockedCounties = '/blocked-countries',
    ReviewedUsers = '/reviewed',
    Search = '/search',
    AdminStatsTable = '/stats/admin-table',
    AdminActivityProfiles = '/admin-activity-profiles',
    AbuseStats = '/abuse-stats',
    ReviewStats = '/review-stats',
    AbuseWords = 'settings/abuse-words'
}

const supportedApps = {
    [RootRoutes.Login]: [Apps.Spark, Apps.Bloom, Apps.Instinct, Apps.Flava],
    [RootRoutes.Moderation]: [Apps.Spark, Apps.Bloom, Apps.Instinct, Apps.Flava],
    [RootRoutes.Verifications]: [Apps.Instinct, Apps.Flava],
    [RootRoutes.Profile]: [Apps.Spark, Apps.Bloom, Apps.Instinct, Apps.Flava],
    [RootRoutes.AdminStatsGraph]: [Apps.Spark, Apps.Bloom, Apps.Instinct, Apps.Flava],
    [RootRoutes.UsersStats]: [Apps.Spark, Apps.Bloom, Apps.Instinct, Apps.Flava],
    [RootRoutes.TempUnbanned]: [Apps.Spark, Apps.Bloom, Apps.Instinct, Apps.Flava],
    [RootRoutes.Setup]: [Apps.Spark, Apps.Bloom, Apps.Instinct, Apps.Flava],
    [RootRoutes.ReviewedUsers]: [Apps.Instinct, Apps.Flava, Apps.Bloom, Apps.Spark],
    [RootRoutes.BlockedCounties]: [Apps.Instinct, Apps.Flava, Apps.Spark],
    [RootRoutes.Search]: [Apps.Instinct, Apps.Flava, Apps.Spark],
    [RootRoutes.AdminStatsTable]: [Apps.Instinct, Apps.Flava, Apps.Spark],
    [RootRoutes.AdminActivityProfiles]: [Apps.Instinct, Apps.Flava, Apps.Spark],
    [RootRoutes.AutoBanned]: [Apps.Instinct, Apps.Flava, Apps.Spark],
    [RootRoutes.Admins]: [Apps.Instinct, Apps.Flava, Apps.Spark],
    [RootRoutes.AbuseStats]: [Apps.Instinct, Apps.Flava, Apps.Spark],
    [RootRoutes.ReviewStats]: [Apps.Instinct, Apps.Flava, Apps.Spark],
    [RootRoutes.AbuseWords]: [Apps.Instinct, Apps.Flava, Apps.Spark],
}

export const isSupportedRoute = (route: RootRoutes, app?: Apps) => {
    return supportedApps[route].length === Object.keys(Apps).length || (app && supportedApps[route].includes(app));
}

