import {PhotoPayloadBloom, usersService} from "services";
import React, {useContext} from "react";
import {AuthContext} from "contexts";
import {useImagePreview} from "utils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

const MySwal = withReactContent(Swal);

export const usePhotoManagement = () => {
    const {env} = useContext(AuthContext);
    const imagePreview = useImagePreview();

    const changePhoto = async (photo: PhotoPayloadBloom, userId: string = '', params: {
        objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down",
        withAlert?: boolean,
        title: string
    }) => {
        if (!env) {
            return;
        }
        const url = photo.image[0].link;
        let canToggle = !params?.withAlert;

        if (params?.withAlert) {
            const result = await MySwal.fire({
                html: (
                    <img
                        src={url}
                        alt={`Pic ${url}`}
                        style={{objectFit: params?.objectFit ?? 'scale-down'}}
                        className="w-100"
                        height={300}
                    />
                ),
                title: params?.title,
                confirmButtonText: 'Да',
                confirmButtonColor: '#D9292E',
                cancelButtonText: 'Нет',
                showCancelButton: true,
                showConfirmButton: true,
            });
            canToggle = result.isConfirmed;
        }

        return canToggle && userId;
    };

    const onDeleteAllPics = async (userId?: string, photoKeys?: string[]) => {
        if (!userId || !photoKeys || photoKeys.length < 1 || !env) {
            return;
        }

        const result = await MySwal.fire({
            title: "Удалить все фото пользователя?",
            confirmButtonText: 'Да',
            confirmButtonColor: '#D9292E',
            cancelButtonText: 'Нет',
            showCancelButton: true,
            showConfirmButton: true,
        });
        if (result.isConfirmed) {
            await usersService
                .deletePhoto({userKey: userId, key: photoKeys}, env);
            return true;
        }
    }

    const onPicDelete = async (photo: PhotoPayloadBloom, userId?: string, featureId?: number) => {
        const canDelete = !!(photo.key && userId) || !!(photo.key && featureId);
        if (!env || !userId || !canDelete) {
            alert("Can't delete this photo")
            return;
        }

        let deletedKey;
        if (!featureId && userId) {
            try {
                await usersService
                    .deletePhoto({userKey: userId, key: [photo.key]}, env)
                deletedKey = photo.key
            } catch (e) {
                return
            }
        }
        if (featureId && userId) {
            try {
                await usersService
                    .deleteFeaturePhoto({id: featureId, userKey: userId}, env);
                deletedKey = featureId.toString()
            } catch (e) {
                return
            }
        }

        if (deletedKey) {
            toast.success("Фотография удалена")
        }

        return deletedKey
    }

    const onPicPreview = async (photo: PhotoPayloadBloom, userId?: string, featureId?: number) => {
        if (!env || !userId) {
            return;
        }

        const canDelete = !!(photo.key && userId) || !!(photo.key && featureId);
        const result = await imagePreview.open(photo.image[1].link, canDelete);
        if (result.isConfirmed) {
            return onPicDelete(photo, userId, featureId)
        }
    };

    const toggleSensitive = async (photo: PhotoPayloadBloom, userId?: string, params?: {
        objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down",
        withAlert?: boolean
    }) => {
        const title = photo.isSensitive ? 'Удалить Sensitive?' : 'Добавить Sensitive?';
        const shouldChange = await changePhoto(photo, userId, {...params, title});
        if (shouldChange && userId && env) {
            try {
                await usersService.updatePhoto(
                    {userKey: userId, key: photo.key, isSensitive: !photo.isSensitive},
                    env,
                );
            } catch (e) {
                return
            }

            toast.success(photo.isSensitive ? 'Sensitive тег удален' : 'Sensitive тег добавлен')
            return !photo.isSensitive;
        }
    };

    const toggleNoFace = async (photo: PhotoPayloadBloom, hasNoface: boolean, userId?: string, params?: {
        objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down",
        withAlert?: boolean
    }) => {
        const shouldChange = await changePhoto(photo, userId, {
            ...params,
            title: hasNoface ? 'Удалить NoFace' : 'Добавить NoFace'
        });
        if (shouldChange && userId && env) {
            try {
                await usersService.updatePhoto(
                    {userKey: userId, key: photo.key, isSensitive: photo.isSensitive, hasFace: hasNoface},
                    env,
                );
            } catch (e) {
                return
            }

            toast.success(hasNoface ? 'NoFace тег удален' : 'NoFace тег добавлен')
            return !photo.isSensitive;
        }
    };

    return {onPicPreview, toggleSensitive, onPicDelete, toggleNoFace, onDeleteAllPics};
}