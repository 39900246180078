export enum VerificationRejectReasons {
    NotSelfie = "NotSelfie",
    AnotherFace = "AnotherFace",
    AnotherGender = "AnotherGender",
    AnotherPose = "AnotherPose",
    VerificationPhotoBadQuality = "VerificationPhotoBadQuality",
    ProfilePhotoBadQuality = "ProfilePhotoBadQuality",
    Other = "other",
    AutomaticAnotherFace = "AutomaticAnotherFace",
    Unknown = "Unknown",
    AutomaticManyFace = 'AutomaticManyFace',
    AutomaticNoFace = 'AutomaticNoFace'

}

export const VerificationRejectReasonsLabels: Record<VerificationRejectReasons, string> = {
    [VerificationRejectReasons.NotSelfie]: "🤳 Не селфи (фото экрана или другой фотографии)",
    [VerificationRejectReasons.AnotherFace]: "🥸 Другое лицо",
    [VerificationRejectReasons.AnotherGender]: "🧔‍♀️️ Неправильный гендер",
    [VerificationRejectReasons.AnotherPose]: "💁‍♀️ Неправильная поза",
    [VerificationRejectReasons.VerificationPhotoBadQuality]: "😶‍🌫️ Плохое качество фото верификации",
    [VerificationRejectReasons.ProfilePhotoBadQuality]: "🫠 Плохое качество фото профиля",
    [VerificationRejectReasons.Other]: "🤔 Другое",
    [VerificationRejectReasons.AutomaticAnotherFace]: "🎭 Автоматический отказ (другое лицо)",
    [VerificationRejectReasons.Unknown]: "❔ Неизвестна",
    [VerificationRejectReasons.AutomaticManyFace]: "🧑‍🤝‍🧑 Автоматический отказ (несколько лиц)",
    [VerificationRejectReasons.AutomaticNoFace]: '🙈 Автоматический отказ (нет лица)'
}