import React, {FC, useContext} from 'react';
import {ModerationUser} from "interfaces";
import {
    areBoostsAvailable,
    areGreetingsAvailable,
    GenderName,
    SexualityLabel,
    toStatusString,
    UserStatuses
} from "enums";
import {ModerationRow} from "components";
import {DateTime} from "luxon";
import {ModerationReports} from "./ModerationReports";
import {ModerationTags} from "./ModerationTags";
import {AuthContext} from "contexts";
import {ModerationAbout} from "components/ModerationUserCard/components/ModerationAbout";
import {ModerationStats} from "components/ModerationUserCard/components/ModerationStats";
import {ModerationDev} from "components/ModerationUserCard/components/ModerationDev";
import {ModerationGeo} from "components/ModerationUserCard/components/ModerationGeo";
import {ModerationPremium} from "components/ModerationUserCard/components/ModerationPremium";
import {ModerationStatus} from "components/ModerationUserCard/components/ModerationStatus";
import {ModerationBuckets} from "components/ModerationUserCard/components/ModerationBuckets";
import {ModerationSection} from "components/ModerationSection";
import {ModerationDownloadData} from "components/ModerationUserCard/components/ModerationDownloadData";
import {InterestGroupsName} from "enums/InterestGroups";
import {ModerationIPAddresses} from "components/ModerationUserCard/components/ModerationIPAddresses";

interface Props {
    user: ModerationUser
}

export const ModerationInfo: FC<Props> = ({user}) => {
    const {env, user: myUser} = useContext(AuthContext);
    const statusReason = user.statusHistory && user.status !== UserStatuses.Active ? user.statusHistory[user.statusHistory.length - 1] : ''

    return <>
        <div>
            <div className="d-flex flex-row align-items-center">
                <h5 className="mb-0">{user.name}{user.age ? ', ' : ''}{user.age}</h5>
                <ModerationDownloadData user={user}/>
            </div>
            <small>{toStatusString(user.status)} {statusReason ? `(${statusReason.reason})` : ''}</small><br/>
            {user.isVerified && <><small>✅ Верифицирован</small><br/></>}
            {user.hasPremium && <><small>⭐ Премиум</small><br/></>}
            <div className="mb-3"/>

        </div>
        <div className="bg-light p-2 small text-black rounded p-2">
            <ModerationSection label="Статус" location={'top'}>
                <ModerationRow label={user.wasReviewed ? "Изменение статуса" : "Попадание в ревью"}
                               value={user.reviewedDate} isInsideTable/>
                <ModerationRow label="Регистрация" value={user.registrationDate} isInsideTable/>
                <ModerationRow label="Онлайн" value={user.onlineDate} isInsideTable/>
                <ModerationRow label="Требуется ревью" value={!user.wasReviewed} isInsideTable/>
            </ModerationSection>
            <ModerationSection label="Общее">
                <ModerationRow label="Пол" value={GenderName[user.gender]} isInsideTable/>
                {user.isCouple && <ModerationRow label="Пара" value={user.isCouple} isInsideTable/>}
                <ModerationRow label="Ориентация" value={SexualityLabel[user.sexuality]} isInsideTable/>
                {user.metaTags.map(ig =>
                    <ModerationRow
                        key={ig.groupId}
                        label={InterestGroupsName[ig.groupId] ?? ig.groupId}
                        value={ig.interests.map(i => i.tag)?.join(', ')} isInsideTable/>
                )}
                {user.metaTags.length === 0 &&
                    <ModerationRow label="Интересы" value={user.interests?.join(', ')} isInsideTable/>}
            </ModerationSection>
            <ModerationAbout user={user}/>
            <ModerationRow label="Любит части тел" value={user.attractedToFeatures?.map(f => f.text).join(', ')}/>
            <ModerationGeo user={user}/>
            <ModerationIPAddresses user={user}/>
            <ModerationPremium user={user}/>
            <ModerationTags user={user}/>
            <ModerationStats user={user}/>
            <ModerationReports
                label="Входящие Жалобы"
                reports={user.reports}
                reportsCount={user.reportsCount}
                userName={user.name}
                userKey={user.id}
                warning={{minCount: 1, label: 'На юзера уже есть жалобы'}}
            />
            <ModerationReports
                label="Исходящие Жалобы" reports={user.outgoingReports}
                reportsCount={user.outgoingReportsCount}
                warning={{minCount: 10, label: 'Юзер выставляет много жалоб'}}
                userKey={user.id}
                userName={user.name}
            />
            {user.scamMessages.length > 0 &&
                <ModerationRow
                    label="Scam сообщения"
                    value={user.scamMessages?.map((m, i) =>
                        <p key={`${user.id}_${i}`} className="mb-2">{m}</p>)}/>}
            <ModerationStatus user={user}/>
            {myUser?.isSuperAdmin &&
                <>
                    {env && areBoostsAvailable(env.app) &&
                        <ModerationBuckets label="Boosts"
                                           buckets={user.boostBuckets}
                                           type={"boosts"}
                        />}
                    {env && areGreetingsAvailable(env.app) &&
                        <ModerationBuckets label="Sparks"
                                           buckets={user.greetingBuckets}
                                           type={'sparks'}
                        />}
                </>
            }
            <ModerationDev user={user}/>
            {user.temporaryUnbannedDate &&
                <ModerationRow
                    label="Дата временной разблокировки"
                    value={DateTime.fromJSDate(user.temporaryUnbannedDate).toLocaleString(DateTime.DATETIME_SHORT)}/>
            }
            <ModerationRow label="ID" value={user.id}/>
        </div>
    </>;
}

