import {CSSProperties} from "react";

export enum Genders {
    Male = '1',
    Female = '2',
    NonBinary = '3',
}

export const GenderName = {
    [Genders.Male]: '🚹 мужской',
    [Genders.Female]: '🚺️ женский',
    [Genders.NonBinary]: '⚧️ небинарный',
};

export const GenderBackground: Record<Genders, CSSProperties> = {
    [Genders.Male]: {backgroundColor: 'rgba(152, 210, 235, 0.5)'},
    [Genders.Female]: {backgroundColor: 'rgba(255, 166, 158, 0.5)'},
    [Genders.NonBinary]: {backgroundColor: 'rgba(237, 229, 128, 0.5)'},
};

export const GenderEvents = {
    [Genders.Male]: 'male',
    [Genders.Female]: 'female',
    [Genders.NonBinary]: 'non-binary',
}
