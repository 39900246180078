import React, {FC, useState} from "react";
import {ModerationUser} from "interfaces";
import {questionsStore} from "stores";
import {ModerationRow} from "components/ModerationRow";
import Highlighter from "react-highlight-words";
import {ModerationHint} from "components/ModerationHint";
import {UserReviewTags} from "enums";
import {handleTranslation} from "utils/translator";

interface Props {
    user: ModerationUser
}

const getAbuseWords = (info: string) => {
    if (!info) {
        return [];
    }

    return info.split(', text: ')[0].split('Abuse reason: ')[1].split(', ');
}

export const ModerationAbout: FC<Props> = ({user}) => {
    const [translatedVersion, setTranslatedVersion] = useState('');

    const aboutAbuseReviewTag = user.reviewTags.find(r => {
        const infoParts = r.info?.split(', text: ') ?? [];
        return infoParts.length > 0 ? infoParts[1] === user.about : false;
    });
    const isBannedAbuse = aboutAbuseReviewTag?.tag === UserReviewTags.BannedAbout;
    const abuseWords = getAbuseWords(aboutAbuseReviewTag?.info ?? '');

    const renderTranslator = () => {
        return <div className={`mt-2 ${translatedVersion ? 'rounded border border-dark p-1' : ''}`}>
            {translatedVersion ? <span className="fw-bold">Перевод:</span> :
                <a href="#"
                   className="text-black-50 text-decoration-underline"
                   style={{cursor: 'pointer'}}
                   onClick={async (event) => {
                       event.preventDefault();
                       const translatedText = await handleTranslation(user.about)
                       setTranslatedVersion(translatedText)
                   }}>
                    Перевести на английский
                </a>
            }

            <div> {translatedVersion}</div>
        </div>
    }

    const renderAbout = () => {
        if (!abuseWords.length) {
            return <div>{user.about}{renderTranslator()}</div>;
        }

        return <div>
            <Highlighter
                highlightClassName={isBannedAbuse ? "bg-danger text-white" : "bg-warning"}
                searchWords={abuseWords}
                autoEscape={true}
                textToHighlight={user.about}
            />
            <ModerationHint className={isBannedAbuse ? 'alert-danger fw-normal mt-3' : 'alert-warning fw-normal mt-3'}>Пользователь
                использовал триггерные
                слова: {<span className="fw-bold">{abuseWords.join(', ')}</span>}</ModerationHint>
            {renderTranslator()}
        </div>
    }

    if (!user.about && !user.aboutAnswers.length) {
        return null
    }

    if (user.aboutAnswers.length) {
        return <ModerationRow label={"O ceбе"} value={
            <div>
                {user.aboutAnswers.map(a => {
                    const question = questionsStore.questions.find(q => q.tag === a.tag)
                    return <div>
                        <small>{question?.text ?? a.tag}</small>
                        <p key={a.tag}>{a.text}</p>
                    </div>
                })}
            </div>}
        />
    }

    return <ModerationRow label={"O ceбе"} value={renderAbout()}/>
}