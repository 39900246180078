export enum UserReviewTags {
    ScamMessages = 'ScamMessages',
    TooManyLikes = 'TooManyLikes',
    SensitivePhoto = 'SensitivePhoto',
    AbusedAbout = 'AbusedAbout',
    UpdatedPhotos = 'UpdatedPhotos',
    UpdatedAbout = 'UpdatedAbout',
    OnePersonInMultipleVerifications = 'OnePersonInMultipleVerifications',
    BannedAbout = 'BannedAbout',
    AdminCheckRequiredAbout = 'AdminCheckRequiredAbout',
    NoFace = 'NoFace',
    Unknown = 'Unknown',
    HiddenAbout = 'HiddenAbout',
    PremiumScammers = 'PremiumScammers',
    RepeatedMessages = 'RepeatedMessages',
    BannedByMultiLogin = 'BannedByMultiLogin',
    IpAddressesReuseWithBanned = 'IpAddressesReuseWithBanned',
    IpAddressesReuseWithSuspicious = 'IpAddressesReuseWithSuspicious',
    IpAddressesReuse = 'IpAddressesReuse',
    SuspiciousNickname = 'SuspiciousNickname',
    BannedBySocialLink = 'BannedBySocialLink',
    AdminCheckRequiredName = 'AdminCheckRequiredName',
    CelebrityPhoto = 'CelebrityPhoto',
    DuplicateFacePhotos = 'DuplicateFacePhotos',
    NoFacesAfterPhotoDeleted = 'NoFacesAfterPhotoDeleted',
    DifferentPeopleInVerification = 'DifferentPeopleInVerification',
    MinorDetected = 'MinorDetected',
    QrCodeInPhoto = 'QrCodeInPhoto',
    DuplicateHashPhoto = 'DuplicateHashPhoto'
}


export const isReviewTagSuspest = (tag: UserReviewTags) => ![
    UserReviewTags.UpdatedPhotos,
    UserReviewTags.UpdatedAbout,
    UserReviewTags.IpAddressesReuse
].includes(tag)

export const isReviewPhotoDanger = (tag: UserReviewTags) => [
    UserReviewTags.SensitivePhoto,
    UserReviewTags.CelebrityPhoto,
    UserReviewTags.MinorDetected,
    UserReviewTags.QrCodeInPhoto
].includes(tag)

export const isReviewPhoto = (tag: UserReviewTags) => [
    UserReviewTags.SensitivePhoto,
    UserReviewTags.UpdatedPhotos,
    UserReviewTags.NoFace,
    UserReviewTags.CelebrityPhoto,
    UserReviewTags.MinorDetected,
    UserReviewTags.QrCodeInPhoto,
    UserReviewTags.DuplicateHashPhoto
].includes(tag)


export const getPhotoInfoFromTag = (info: string, tag: UserReviewTags) => {
    if (tag === UserReviewTags.UpdatedPhotos) {
        return {key: info, info: ''}
    }
    const parts = info.split('\n')
    return {key: parts[0], info: parts[1]};
}