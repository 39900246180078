import React, {FC, ReactNode} from "react";

interface Props {
    label: JSX.Element | string
    children?: ReactNode | undefined;
    hasWarning?: boolean
    location?: 'top' | 'middle'
}

export const ModerationSection: FC<Props> = ({label, hasWarning, children, location = 'middle'}) => {
    return <>
        {location !== 'top' && <hr style={{opacity: 0.1}}/>}
        <div className={`row pt-1 pb-1 mx-1 rounded ${hasWarning ? 'alert-warning' : ''}`}>
            <div className="col-12 col-lg-2" style={{fontWeight: 500}}>{label}</div>
            <div className="col-12 col-lg-10">
                {children}
            </div>
        </div>
    </>
}