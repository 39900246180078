import {Apps} from "../enums";
import {request} from "./request";
import {
    convertToSearchBloomToModerationUser,
    convertToSearchFlavaToModerationUser,
    convertToSearchInstinctToModerationUser,
    convertToSearchSparkToModerationUser,
    SearchUserPayloadBloom,
    SearchUserPayloadFlava,
    SearchUserPayloadInstinct,
    SearchUserPayloadSpark
} from "./payloads";
import {EnvSetup, getSupportUrl} from "enums/Envs";

export const tempUnbannedService = {
    getPage: async (env: EnvSetup, skip: number) => {
        if (env.app === Apps.Bloom) {
            const response = await request<{ users: { user: SearchUserPayloadBloom }[] }>({
                method: 'get',
                url: `${getSupportUrl(env)}/temporary-unban-users?skip=${skip}`,
            });

            return response?.users.map(u => convertToSearchBloomToModerationUser(u.user));
        } else if (env.app === Apps.Spark) {
            const response = await request<{ sparkers: { user: SearchUserPayloadSpark }[] }>({
                method: 'get',
                url: `${getSupportUrl(env)}/temporary-unban-users?skip=${skip}`,
            });
            return response?.sparkers.map(
                u => convertToSearchSparkToModerationUser(u.user)
            );
        } else if (env.app === Apps.Instinct) {
            const response = await request<{ users: { user: SearchUserPayloadInstinct }[] }>({
                method: 'get',
                url: `${getSupportUrl(env)}/temporary-unban-users?skip=${skip}`,
            });
            return response?.users.map(
                u => convertToSearchInstinctToModerationUser(u.user)
            );
        } else if (env.app === Apps.Flava) {
            const response = await request<{ profiles: { user: SearchUserPayloadFlava }[] }>({
                method: 'get',
                url: `${getSupportUrl(env)}/temporary-unban-users?skip=${skip}`,
            });
            return response?.profiles.map(
                u => convertToSearchFlavaToModerationUser(u.user)
            );
        }
    }
}
