import {ImagePayloadBloom, ImagePayloadInstinct} from 'services';

export interface PhotoPayloadInstinct {
    image: ImagePayloadInstinct[];
    isSensitive: boolean;
    key: string;
}

export interface PhotoPayloadFlava {
    image: ImagePayloadInstinct[];
    isSensitive: boolean;
    id: string;
}

export interface PhotoPayloadBloom {
    image: ImagePayloadBloom[];
    isSensitive: boolean;
    isPrivate: boolean;
    key: string;
}

export interface AttractivePhotoPayloadFlava {
    id: string;
    isSensitive: boolean;
    isPrivate: boolean;
    image: { height: number, url: string; width: number }[]
}

export interface PhotoPayloadSpark {
    id: string;
    isDelicate: boolean;
    isConfirmed: boolean;
    image: { h: number, url: string; w: number }[]
}

export const convertPhotoPayloadSparkToBloom = (photo: PhotoPayloadSpark) => {
    return {
        key: photo.id,
        image: photo.image.map(i => ({height: i.h, width: i.w, link: i.url})),
        isPrivate: photo.isConfirmed,
        isSensitive: photo.isDelicate,
    } as PhotoPayloadBloom
}

export const convertAttractivePhotoPayloadFlavaToBloom = (photo: AttractivePhotoPayloadFlava) => {
    return {
        key: photo.id,
        image: photo.image.map(i => ({...i, link: i.url})),
        isPrivate: photo.isPrivate,
        isSensitive: photo.isSensitive,
    } as PhotoPayloadBloom
}