import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {useContext, useState} from 'react';
import {Apps, isPatternsManagementSupported, isSupportedRoute, RootRoutes} from 'enums';
import {AppSwitch} from 'components';
import {AuthContext} from 'contexts';
import {useNavigate} from 'react-router-dom';
import {storage} from "../../utils";

export const AppNavbar = () => {
    const {user, app, env} = useContext(AuthContext);

    const [selectedApp, setSelectedApp] = useState(app);
    const [isSwitchModalVisible, setIsSwitchModalVisible] = useState(false);

    const navigate = useNavigate();

    const handleClose = () => {
        setIsSwitchModalVisible(false);
    };

    const handleSwitch = (a: Apps) => {
        setSelectedApp(a);
        setIsSwitchModalVisible(true);
    };

    if (!user) {
        return null;
    }

    return (
        <>
            <Navbar bg="dark" collapseOnSelect variant="dark" expand="sm" sticky="top" style={{maxWidth: '100vw'}}>
                <Container>
                    <Navbar.Brand href="#home">LM</Navbar.Brand>
                    <NavDropdown title={<span
                        className="text-secondary fw-bolder">{app} {storage.getIsTest() ? 'Test' : ''}</span>}
                                 className="d-flex">
                        {Object.values(Apps).map(a => (
                            <NavDropdown.Item key={a} onClick={() => handleSwitch(a)}>
                                {a}
                            </NavDropdown.Item>
                        ))}
                    </NavDropdown>

                    <Navbar.Collapse>
                        <Nav>
                            <NavDropdown title="Администрирование">
                                {isSupportedRoute(RootRoutes.Moderation, app) &&
                                    <NavDropdown.Item
                                        onClick={() => navigate(RootRoutes.Moderation)}>Новые</NavDropdown.Item>}
                                {isSupportedRoute(RootRoutes.TempUnbanned, app) &&
                                    <NavDropdown.Item
                                        onClick={() => navigate(RootRoutes.TempUnbanned)}>Временно
                                        разблокированные</NavDropdown.Item>}
                                {isSupportedRoute(RootRoutes.AutoBanned, app) && user.isSuperAdmin &&
                                    <NavDropdown.Item
                                        onClick={() => navigate(RootRoutes.AutoBanned)}>Автоматически
                                        заблокированные</NavDropdown.Item>}
                                {isSupportedRoute(RootRoutes.Verifications, app) &&
                                    <NavDropdown.Item
                                        onClick={() => navigate(RootRoutes.Verifications)}>Верификация</NavDropdown.Item>}
                                {isSupportedRoute(RootRoutes.Profile, app) &&
                                    <NavDropdown.Item
                                        onClick={() => navigate(RootRoutes.Profile)}>Поиск по ID</NavDropdown.Item>}
                                {isSupportedRoute(RootRoutes.Search, app) && user.isSuperAdmin &&
                                    <NavDropdown.Item
                                        onClick={() => navigate(RootRoutes.Search)}>Поиск</NavDropdown.Item>}
                            </NavDropdown>
                            <NavDropdown title="Аналитика">
                                <NavDropdown.Header>Модерация</NavDropdown.Header>
                                <NavDropdown.Item
                                    onClick={() => navigate(RootRoutes.AdminStatsGraph)}>График
                                    просмотров</NavDropdown.Item>
                                {isSupportedRoute(RootRoutes.AdminStatsTable, app) && user.isSuperAdmin &&
                                    <NavDropdown.Item
                                        onClick={() => navigate(RootRoutes.AdminStatsTable)}>Таблица
                                        просмотров</NavDropdown.Item>}
                                {isSupportedRoute(RootRoutes.ReviewedUsers, app) && user.isSuperAdmin &&
                                    <NavDropdown.Item
                                        onClick={() => navigate(RootRoutes.ReviewedUsers)}>Просмотренные
                                        профили</NavDropdown.Item>}

                                {isSupportedRoute(RootRoutes.ReviewStats, app) && user.isSuperAdmin &&
                                    <>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item
                                            onClick={() => navigate(RootRoutes.ReviewStats)}>Статистика всей
                                            модерации</NavDropdown.Item>
                                    </>}
                                <NavDropdown.Divider/>
                                <NavDropdown.Header>Статистика</NavDropdown.Header>
                                <NavDropdown.Item
                                    onClick={() => navigate(RootRoutes.UsersStats)}>В ожидании
                                    модерации</NavDropdown.Item>
                                {isSupportedRoute(RootRoutes.AbuseStats, app) && user.isSuperAdmin &&
                                    <NavDropdown.Item
                                        onClick={() => navigate(RootRoutes.AbuseStats)}>Статистика Abuse
                                        слов</NavDropdown.Item>}
                            </NavDropdown>
                            <NavDropdown title="Настройки">
                                <NavDropdown.Item
                                    onClick={() => navigate(RootRoutes.Setup)}>Поиск</NavDropdown.Item>
                                {user.isSuperAdmin &&
                                    <>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Header>Модерация</NavDropdown.Header>
                                        {isSupportedRoute(RootRoutes.Admins, app) && user.isSuperAdmin && isPatternsManagementSupported(env) &&
                                            <NavDropdown.Item
                                                onClick={() => navigate(RootRoutes.AbuseWords)}>Abuse
                                                Слова</NavDropdown.Item>}
                                        {isSupportedRoute(RootRoutes.BlockedCounties, app) && user.isSuperAdmin &&
                                            <NavDropdown.Item
                                                onClick={() => navigate(RootRoutes.BlockedCounties)}>Заблокированные
                                                страны</NavDropdown.Item>}
                                    </>}
                                {user.isSuperAdmin &&
                                    <>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Header>Доступ</NavDropdown.Header>
                                        {isSupportedRoute(RootRoutes.Admins, app) && user.isSuperAdmin &&
                                            <NavDropdown.Item
                                                onClick={() => navigate(RootRoutes.Admins)}>Администраторы</NavDropdown.Item>}
                                    </>}
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Text>
                        <span title={user.isSuperAdmin ? 'Супер админ!' : ''}>
                        Привет, {user.name} {user.isSuperAdmin ? '🌟' : ''}</span>
                    </Navbar.Text>
                    <Navbar.Toggle/>
                </Container>
            </Navbar>
            <AppSwitch
                selectedApp={selectedApp}
                isVisible={isSwitchModalVisible}
                onClose={handleClose}
            />
        </>
    );
};
