import {Button, Container, Form, Row} from "react-bootstrap";
import {FormEvent, useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "contexts";
import {GenderName, Genders, RootRoutes} from "enums";
import {setupServices} from "services";
import {useNavigate} from "react-router-dom";


export const SetupPage = () => {
    const filtersRef = useRef<any>();

    const {app} = useContext(AuthContext);


    const [selectedGenders, setSelectedGenders] = useState<Genders[]>([])

    let navigate = useNavigate();

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        if (!app) {
            return;
        }

        event.preventDefault();
        await setupServices.save(app, selectedGenders, filtersRef.current)
        navigate(RootRoutes.Moderation)
    };

    useEffect(() => {
        if (!app) {
            return;
        }

        setupServices.get(app).then(filters => {
            filtersRef.current = filters;
            filters && setSelectedGenders(filters.genders)
        })
    }, [app])

    return <Form onSubmit={handleSubmit} className="m-2 m-lg-3">
        <Container>
            <Row>
                <Form.Label className="col-sm-2 col-form-label">
                    Гендер поиска
                </Form.Label>
                <div className="col-sm-10">
                    {Object.values(Genders).map(gender =>
                        <div key={gender} className="form-check">
                            <input className="form-check-input"
                                   type="checkbox" value="" id={gender}
                                   checked={selectedGenders.includes(gender)}
                                   onChange={() => setSelectedGenders(prev => prev.includes(gender) ? prev.filter(pg => pg !== gender) : [...prev, gender])}/>
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                {GenderName[gender]}
                            </label>
                        </div>)}
                </div>
            </Row>
            <Button disabled={!selectedGenders.length} variant="primary" type="submit"
                    className="mt-3">Сохранить</Button>
        </Container>
    </Form>
}