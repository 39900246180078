import {DateTime} from "luxon";

export const toDateString = (value: Date, hasRelative: boolean = true) => {
    const dateValue = DateTime.fromJSDate(value).setLocale('ru');
    return `${dateValue.toLocaleString(DateTime.DATETIME_SHORT)}${hasRelative ? `, ${dateValue.toRelative()}` : ''}`
}

export const toDayDateString = (value: Date, hasRelative: boolean = true) => {
    const dateValue = DateTime.fromJSDate(value).setLocale('ru');
    return `${dateValue.toLocaleString(DateTime.DATE_MED)}${hasRelative ? `, ${dateValue.toRelative()}` : ''}`
}

export const getMinutesBetweenDates = (startDate: Date, endDate: Date) => {
    const diff = endDate.getTime() - startDate.getTime();  // Convert to milliseconds
    return Math.floor(diff / (1000 * 60));
}

export const getMinutesToNow = (value?: Date | undefined) => {
    if (!value) {
        return undefined;
    }
    const now = new Date();
    return getMinutesBetweenDates(value, now);
}