import {Apps} from "../enums";

enum LocalStorageKeys {
    JWT = 'tokenator',
    Refresh = 'refrigirator',
    UserId = 'my',
    App = 'app',
    IsTest = 'isTest'
}

export const storage = {
    getToken: () => {
        return localStorage.getItem(LocalStorageKeys.JWT);
    },
    setToken: (token: string) => {
        return localStorage.setItem(LocalStorageKeys.JWT, token);
    },
    getUserId: () => {
        return localStorage.getItem(LocalStorageKeys.UserId);
    },
    getIsTest: () => {
        return !!localStorage.getItem(LocalStorageKeys.IsTest);
    },
    setUserId: (id: string) => {
        return localStorage.setItem(LocalStorageKeys.UserId, id);
    },
    getRefresh: () => {
        return localStorage.getItem(LocalStorageKeys.Refresh);
    },
    setRefresh: (token: string) => {
        return localStorage.setItem(LocalStorageKeys.Refresh, token);
    },
    getApp: () => {
        return localStorage.getItem(LocalStorageKeys.App) as Apps;
    },
    setApp: (id: Apps) => {
        return localStorage.setItem(LocalStorageKeys.App, id);
    },
    setIsTest: (isTest: boolean) => {
        if (isTest) {
            localStorage.setItem(LocalStorageKeys.IsTest, 'true')
        } else {
            localStorage.removeItem(LocalStorageKeys.IsTest)
        }
    },
    clearAll: () => {
        localStorage.clear();
    }
}