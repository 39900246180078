import {CountryData} from "interfaces";
import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {countriesService} from "services";
import {EnvSetup} from "enums/Envs";

class SettingsStore {
    @observable
    countries: CountryData[] = []

    @observable
    selectedCountry: CountryData | undefined

    constructor() {
        makeAutoObservable(this)
    }

    @computed
    get countriesSelect() {
        return this.countries?.map(c => ({value: c.countryCode, label: `${c.flag} ${c.name}`}))
    }

    @action
    selectCountry = (country?: string) => {
        this.selectedCountry = country ? this.countries.find(c => c.countryCode === country) : undefined;
    }

    loadCountries = async (env: EnvSetup) => {
        const allItems = await countriesService.getAll(env);
        runInAction(() => {
            this.countries = allItems;
        });
    }
}

export const settingsStore = new SettingsStore()