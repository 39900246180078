import {Apps} from "enums/Apps";

export enum Envs {
    Prod = 'prod',
    Test = 'test'
}

export interface EnvSetup {
    app: Apps
    env: Envs
}

export const getSupportUrl = (setup: EnvSetup) => {
    return 'support'
}

export const getSearchUrl = (setup: EnvSetup) => {
    switch (setup.app) {
        case Apps.Flava:
        case Apps.Instinct:
        case Apps.Spark:
            return 'search'
        case Apps.Bloom:
            return 'newMatches'
        default:
            alert('getSearchUrl not implemented!')
    }
}

export const getBanUrl = (setup: EnvSetup) => {
    return 'ban'
}

export const createUserIdParam = (setup: EnvSetup, id: string) => {
    return {userKey: id}
}

export const createUserIdBanParam = (setup: EnvSetup, id: string) => {
    return {userKey: id}
}

export const createUserIdQueryParam = (setup: EnvSetup) => {
    return "userKey"
}

