import {request} from "./request";
import {UserQuestion} from "interfaces";

export const questionsService = {
    getAll: async () => {
        const response = await request<{ questions: UserQuestion[] }>({
            url: 'me/questions',
            method: 'get',
        });
        return response?.questions ?? []
    }
}