import {request} from './request';
import {DateTime} from 'luxon';
import {Apps, Genders} from '../enums';
import {AdminActivityActions} from "enums/AdminActivityAction";
import {
    convertToSearchFlavaToModerationUser,
    convertToSearchInstinctToModerationUser,
    convertToSearchSparkToModerationUser,
    SearchUserPayloadFlava,
    SearchUserPayloadInstinct,
    SearchUserPayloadSpark,
    toDate
} from "services/payloads";
import {EnvSetup, getSupportUrl} from "enums/Envs";

export interface AbuseStats {
    category: string,
    userCount: 0,
    bannedByReviewCount: 0,
    deletedByReviewCount: 0,
    bannedAfterReviewCount: 0
}

export interface StatsUserDataPayload {
    day: Date;
    dayString: string;
    feedbacksCount: number;
    complaintsCount: number;
    actions?: {
        Feedback?: number;
        Ban?: number;
        Unban?: number;
        TemporaryUnban?: number;
        ForcedVerification?: number;
        PostponeReview?: number;
        FixSensitivePhotos?: number;
    };
    totalCount: number;
}

export interface StatsUserPayload {
    supportUserKey: string;
    name: string;
    stats: StatsUserDataPayload[];
}

export interface StatsPayload {
    users: StatsUserPayload[];
}

export interface AdminActivityGenderStats {
    banBySuperAdmin: number,
    verificationChangeBySuperAdmin: number,
    unban: number,
    verification: number,
    ban: number,
    unbanBySuperAdmin: number,
    feedback: number,
    gender: Genders
}

interface AdminActivityStatsPayload {
    items: AdminActivityGenderStats[]
}

export interface AdminActivityStatsProfilesParams {
    adminUserKey: string
    startDate: Date
    endDate: Date
    gender: number
    action: AdminActivityActions
    skip: number
}

export interface AdminDayReviewGenderStats {
    Female: number
    Male: number
    Unknown: number
}

export interface AdminDayReviewStats {
    date?: Date
    registered: Record<Genders, number>
    sentToReview: Record<Genders, number>
    sentToReviewByTags: Record<string, Record<Genders, number>>
    postponed: Record<Genders, number>
    deleted: Record<Genders, number>
    autoBanned: Record<Genders, number>
}

export const statsService = {
    get: async (startDate: Date, endDate: Date, env: EnvSetup) => {
        const startDateString = DateTime.fromJSDate(new Date(startDate))
            .toFormat('yyyy-MM-dd')
            .toString();

        const endDateString = DateTime.fromJSDate(new Date(endDate))
            .toFormat('yyyy-MM-dd')
            .toString();

        const response = await request<StatsPayload>({
            url: `${getSupportUrl(env)}/stats?startDate=${startDateString}&endDate=${endDateString}`,
            method: 'get',
        });

        return (
            response?.users.map(u => ({
                ...u,
                stats: u.stats.map(
                    s =>
                        ({
                            ...s,
                            day: new Date(s.day),
                            dayString: DateTime.fromJSDate(new Date(s.day), {zone: 'UTC'}).toLocaleString(
                                DateTime.DATE_SHORT,
                            ),
                            totalCount: s.actions
                                ? (s.actions?.Ban ?? 0)
                                + (s.actions.Feedback ?? 0)
                                + (s.actions.ForcedVerification ?? 0)
                                + (s.actions.Unban ?? 0)
                                + (s.actions.PostponeReview ?? 0)
                                + (s.actions.TemporaryUnban ?? 0)
                                : s.feedbacksCount + s.complaintsCount
                        } as StatsUserDataPayload),
                ),
            })) ?? []
        );
    },
    getActivityStats: async (adminId: string, startDate: Date, endDate: Date, env: EnvSetup) => {
        const startDateString = DateTime.fromJSDate(new Date(startDate))
            .toFormat('yyyy-MM-dd')
            .toString();

        const endDateString = DateTime.fromJSDate(new Date(endDate))
            .toFormat('yyyy-MM-dd')
            .toString();

        const response = await request<AdminActivityStatsPayload>({
            url: `${getSupportUrl(env)}/stats/summary?startDate=${startDateString}&endDate=${endDateString}&adminUserKey=${adminId}`,
            method: 'get',
        });

        return response?.items ?? []
    },
    getActivityStatsProfiles: async (params: AdminActivityStatsProfilesParams, env: EnvSetup) => {
        const startDateString = DateTime.fromJSDate(new Date(params.startDate))
            .toFormat('yyyy-MM-dd')
            .toString();

        const endDateString = DateTime.fromJSDate(new Date(params.endDate))
            .toFormat('yyyy-MM-dd')
            .toString();

        const url = `${getSupportUrl(env)}/stats/summary/profiles?startDate=${startDateString}&endDate=${endDateString}&adminUserKey=${params.adminUserKey}&gender=${params.gender}&action=${params.action}&skip=${params.skip}`


        if (env.app === Apps.Instinct) {
            const response = await request<{ users: SearchUserPayloadInstinct[] }>({url, method: 'get'});
            return response ? response.users.map(user => convertToSearchInstinctToModerationUser(user)) : [];
        } else if (env.app === Apps.Flava) {
            const response = await request<{ profiles: SearchUserPayloadFlava[] }>({url, method: 'get'});
            return response ? response.profiles.map(user => convertToSearchFlavaToModerationUser(user)) : [];
        } else if (env.app === Apps.Spark) {
            const response = await request<{ sparkers: SearchUserPayloadSpark[] }>({url, method: 'get'});
            return response ? response.sparkers.map(user => convertToSearchSparkToModerationUser(user)) : [];
        }

        alert("getActivityStatsProfiles Not implemented!")
    },
    getAbuse: async (env: EnvSetup) => {
        const response = await request<{ items: AbuseStats[] }>({
            url: `/${getSupportUrl(env)}/stats/abuse`,
            method: 'get',
        });

        return response?.items ?? []
    },
    getReviewStats: async (startDate: Date, endDate: Date, env: EnvSetup): Promise<AdminDayReviewStats[]> => {
        const startDateString = DateTime.fromJSDate(new Date(startDate))
            .toFormat('yyyy-MM-dd')
            .toString();

        const endDateString = DateTime.fromJSDate(new Date(endDate))
            .toFormat('yyyy-MM-dd')
            .toString();

        const response = await request<{ days: AdminDayReviewStats[] }>({
            url: `${getSupportUrl(env)}/stats/review?startDate=${startDateString}&endDate=${endDateString}`,
            method: 'get',
        });

        return response?.days.map(s => ({...s, date: toDate(s.date?.toString(), env.app)})) ?? []
    },
};
