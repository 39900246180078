import {request} from "services/request";
import {EnvSetup, getSupportUrl} from "enums/Envs";
import {Genders} from "enums";
import {AbuseStats} from "services/statsService";

export enum PatternTypes {
    About = 1,
    Photo = 2,
    PhotoAbout = 3,
    Nickname = 4,
    Chat = 8,
    Question = 16,
    Icebreaker = 32,
    AnyExceptChat = 55,
    AnyText = 61,
    Any = 63
}

export const PatternTypesLabels: Record<PatternTypes, string> = {
    [PatternTypes.About]: 'О себе',
    [PatternTypes.Photo]: 'Фото',
    [PatternTypes.PhotoAbout]: 'Фото + О себе',
    [PatternTypes.Nickname]: 'Имя',
    [PatternTypes.Chat]: 'Сообщение',
    [PatternTypes.Question]: 'Вопрос (Spark)',
    [PatternTypes.Icebreaker]: 'Ice Breaker (Spark)',
    [PatternTypes.AnyExceptChat]: 'Фото + О Себе + Имя + Spark',
    [PatternTypes.AnyText]: 'О Себе + Имя + Сообщение + Spark',
    [PatternTypes.Any]: 'Фото + О Себе + Имя + Сообщение + Spark',
}

export enum ActionTypes {
    None = 1,
    Hide = 2,
    Ban = 3,
    AdminCheck = 4,
    NeedsVerification = 5,
    Suspicious = 6
}

export const ActionTypesLabels: Record<ActionTypes, string> = {
    [ActionTypes.None]: 'В ревью',
    [ActionTypes.Hide]: 'Спрятать',
    [ActionTypes.Ban]: 'Бан',
    [ActionTypes.AdminCheck]: 'Сбросить ревью с тегом',
    [ActionTypes.NeedsVerification]: 'Отправить на верификацию',
    [ActionTypes.Suspicious]: 'Suspicious',
}

export interface AbuseWord {
    tag: string | null;
    language: string | null;

    allowIgnoreCase: boolean;
    ruleName: string;
    allowSeparateWord: boolean;
    patternType: PatternTypes;
    actionType: ActionTypes;
    genders: Genders[];
    pattern: string;
    allowDelimiters: boolean;
    allowMultiline: boolean;
    id: number;
    stats?: AbuseStats;
}

export const getTag = (name: string): string | null => {
    let tag = name.split(':');
    if (tag.length > 1) {
        return tag[0];
    }
    tag = name.split('-');
    if (tag.length > 1) {
        return tag[0]
    }
    return null;
}

export const getLanguage = (name: string): string | null => {
    const language = name.match(/\(([^)]+)\)/);
    if (language && language.length > 1) {
        return language[1];
    }
    return null;
}

export const abuseWordsService = {
    getAll: async (env: EnvSetup) => {
        return request<AbuseWord[]>({
            url: `/${getSupportUrl(env)}/abuse`,
            method: 'GET',
        }).then(r => r?.map(w => ({
            ...w,
            genders: w.genders.map(g => g.toString() as Genders),
            tag: getTag(w.ruleName),
            language: getLanguage(w.ruleName),
            name: w.ruleName
        }) as AbuseWord));
    },
    add: async (word: Partial<AbuseWord>, env: EnvSetup) => {
        return request<AbuseWord[]>({
            url: `/${getSupportUrl(env)}/abuse`,
            method: 'POST',
            data: {...word, genders: word.genders?.map(g => +g)}
        })
    },
    update: async (word: Partial<AbuseWord>, env: EnvSetup) => {
        return request<AbuseWord[]>({
            url: `/${getSupportUrl(env)}/abuse`,
            method: 'PUT',
            data: {...word, genders: word.genders?.map(g => +g)}
        })
    },
    delete: async (id: number, env: EnvSetup) => {
        return request<AbuseWord[]>({
            url: `/${getSupportUrl(env)}/abuse?id=${id}`,
            method: 'DELETE'
        })
    },
}
