import * as amplitude from '@amplitude/analytics-browser';
import {ModerationUser} from "interfaces";
import {GenderEvents, SexualityEvents, VerificationStatusEvents} from "enums";
import {getMinutesToNow} from "utils/toDateString";
import {AdminStat} from "services";
import {ModerationSources} from "enums/ModerationSources";

export enum Events {
    review = 'review',
    profile_open = 'profile_open',
    audit = 'audit',
}

export enum ReviewTypes {
    Ban = 'ban',
    Approve = 'approve',
    Verification = 'verification',
    Postpone = 'postpone',
    ForceVerification = 'force_verification',
    TemporaryUnban = 'temp_unban',
    Unban = 'unban'
}

export enum AuditTypes {
    Unknown = 'unknown',
    Ban = 'ban',
    Approve = 'approve',
    ForceVerification = 'force_verification',
    TemporaryUnban = 'temp_unban',
    Postpone = 'postpone'
}

type EventParamsType = { [key: string]: string | number | undefined | boolean | Date | string[] | null }

export const eventTracker = {
    track: (name: Events | string, params?: EventParamsType) => {
        console.log(name, params);
        amplitude.track(name, params);
    },
    getAuditParams: (moderator: AdminStat, user: ModerationUser, auditType: AuditTypes, source: ModerationSources): EventParamsType => {
        return {
            moderatorKey: moderator.userKey,
            moderatorName: moderator.name,
            type: auditType,
            userKey: user?.id,
            gender: GenderEvents[user?.gender],
            sexuality: SexualityEvents[user.sexuality],
            platform: user?.platform,
            country: user?.countryCode,
            age: user?.age,
            status: user?.status,
            photosCount: user?.photos?.length,
            premiumType: user?.premiumType,
            matchesCount: user?.matchesCount.allTime,
            displaysCount: user?.displaysCount.allTime,
            tags: user.reviewTags.map(rt => rt.tag),
            source
        }
    },
    getReviewParams: (user: ModerationUser, type: ReviewTypes | null, source: ModerationSources): EventParamsType => {
        const reviewStatus = user.statusHistory ? user.statusHistory[user.statusHistory?.length - 1] : undefined;
        return {
            type: type,
            userKey: user?.id,
            gender: GenderEvents[user?.gender],
            sexuality: SexualityEvents[user.sexuality],
            platform: user?.platform,
            country: user?.countryCode,
            age: user?.age,
            status: user?.status,
            photosCount: user?.photos?.length,
            inReviewTimes: user?.statusHistory?.filter(sh => sh.isReviewed).length,
            accountLifeInMinutes: getMinutesToNow(user.registrationDate),
            ...(type === ReviewTypes.Verification ? {
                reviewWaitTimeMinutes: getMinutesToNow(user?.verificationStartDate),
                toReviewDate: user?.verificationStartDate,
                reviewReason: 'verification'
            } : {
                verification: VerificationStatusEvents[user?.verificationStatus],
                reviewWaitTimeMinutes: getMinutesToNow(reviewStatus?.date),
                toReviewDate: reviewStatus?.date,
                reviewReason: reviewStatus?.reason
            }),
            premiumType: user?.premiumType,
            matchesCount: user?.matchesCount.allTime,
            displaysCount: user?.displaysCount.allTime,
            registrationDate: user.registrationDate,
            tags: user.reviewTags.map(rt => rt.tag),
            source
        }
    }
}