import {makeAutoObservable, observable, runInAction} from "mobx";
import {UserQuestion} from "interfaces";
import {questionsService} from "services";

class QuestionsStore {
    @observable
    questions: UserQuestion[] = []

    constructor() {
        makeAutoObservable(this)
    }

    loadQuestions = async () => {
        const allQuestions = await questionsService.getAll();
        runInAction(() => {
            this.questions = allQuestions;
        });
    }
}

export const questionsStore = new QuestionsStore()