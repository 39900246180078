import React, {CSSProperties, FC} from 'react';
import {toDateString} from "utils";

interface Props {
    label: string;
    value?: string | boolean | number | Date | React.ReactNode;
    isInsideTable?: boolean;
    valueConfig?: {
        isBadge?: boolean
        type: 'warning' | 'primary'
    }
    style?: CSSProperties
}

export const ModerationRow: FC<Props> = ({label, value, isInsideTable, valueConfig, style}) => {
    if (value === undefined || value === '' || value === null) {
        return null
    }

    const getValue = () => {
        if (typeof value === 'boolean') {
            return value ? 'Да' : 'Нет'
        } else if (value instanceof Date) {
            return toDateString(value)
        } else {
            return value
        }
    }

    const stringValue = getValue()
    const elementValue = valueConfig?.isBadge ?
        <span
            className={`badge rounded-pill ${valueConfig.type === 'warning' ? "bg-warning" : "bg-success"} ml-1`}>{stringValue}</span> : stringValue;

    if (isInsideTable) {
        return <div style={style}><span style={{fontWeight: 500}}>{label}:</span> <span>{elementValue}</span></div>
    }

    return <>
        <hr style={{opacity: 0.1}}/>
        <div className="row pt-1 pb-1 mx-1" style={style}>
            <div className="col-12 col-lg-2" style={{fontWeight: 500}}>{label}</div>
            <div className="col-12 col-lg-10">{elementValue}</div>
        </div>
    </>
};


