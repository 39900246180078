import React, {FC} from 'react';
import {ModerationUser, ModerationUserTag, UserActor} from "interfaces";
import {getPhotoInfoFromTag, isReviewPhoto, isReviewTagSuspest, UserReviewTags} from "enums";
import {toDateString, useImagePreview} from "utils";
import {getGuid} from "utils/getGuid";
import {ModerationUserActors} from "components/ModerationUserCard/components/ModerationUserActors";


export const ModerationTag: FC<{ tag: ModerationUserTag, user: ModerationUser }> = ({tag, user}) => {
    const isPhoto = isReviewPhoto(tag.tag);
    const photoInfo = isPhoto ? getPhotoInfoFromTag(tag.info ?? '', tag.tag) : null;
    const photo =
        photoInfo?.key && isPhoto ?
            user.photos?.find(p => p.key === photoInfo.key) ?? user.attractiveFeatures?.find(f => f.photo?.key === photoInfo.key)?.photo
            : undefined;
    const imagePreview = useImagePreview();
    const duplicateUsers: UserActor[] = tag.tag === UserReviewTags.DuplicateHashPhoto && photoInfo?.key ? user.photosWithDuplicateHashes.find(p => p.photoKey === photoInfo?.key)?.usersWithDuplicate ?? [] : [];
    const duplicateUsersWithoutMe = duplicateUsers.filter(u => u.key !== user.id);

    return <div className="row my-4">
        <div className="col-12 col-sm-6 col-md-3 g-0 text-wrap">
            {tag.date && <span>{toDateString(tag.date, true)}</span>}
        </div>
        <div className="col-12 col-sm-6 col-md-9 g-0">
            <span className={`badge ${isReviewTagSuspest(tag.tag) ? 'bg-danger' : 'bg-dark'}`}>#{tag.tag}</span>
            {photoInfo?.info && <p className="text-wrap">{photoInfo.info}</p>}
            {!photo &&
                <div className="p-0 text-black mt-1">
                    {isPhoto ? 'Фото удалено' : tag.info?.split('\n').map((m, i) => {
                        const guid = getGuid(m)
                        const isCurrent = user.id === guid;
                        if (isCurrent) {
                            return null
                        }
                        return <div key={m + i}>{guid ?
                            <a href={`profile/${guid}`} target="_blank"
                               rel="noreferrer">{m}</a> : m}</div>
                    })}
                </div>}
            {!!photo?.image.length && !!photo.image[0].link &&
                <div className="p-0 mt-1">
                    <img src={photo.image[0]?.link} alt={photo.image[0]?.link} height={100} width={100}
                         className="rounded"
                         style={{objectFit: 'cover'}}
                         onClick={() => imagePreview.open(photo.image[0]?.link, false)}/>
                    <ModerationUserActors users={duplicateUsersWithoutMe}/>
                </div>}
        </div>
    </div>
}