import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "contexts";
import {adminsService, AdminUser} from "services/adminsService";
import {CreateAdminButton} from "pages/Admins/components/CreateAdminButton";
import {Button} from "react-bootstrap";
import {AiOutlineClose, AiOutlineRedo} from "react-icons/ai";
import Swal from 'sweetalert2';
import withReactContent from "sweetalert2-react-content";
import {isModerationPackAvailable} from "enums";
import {FaRegStar, FaStar} from "react-icons/fa";

const MySwal = withReactContent(Swal);

export const AdminsPage = () => {
    const {env} = useContext(AuthContext);

    const [admins, setAdmins] = useState<AdminUser[]>([]);
    
    const handleBlockAdmin = async (key: string, name: string) => {
        if (!env) {
            return
        }
        const result = await MySwal.fire({
            title: `Заблокировать ${name}?`,
            confirmButtonText: 'Да',
            confirmButtonColor: '#D9292E',
            cancelButtonText: 'Нет',
            showCancelButton: true,
            showConfirmButton: true,
        });
        if (result.isConfirmed) {
            await adminsService.block(key, env)
            reload()
        }
    }

    const handleRegenerateToken = async (key: string, name: string) => {
        if (!env) {
            return
        }
        const result = await MySwal.fire({
            title: `Обновить токен для админа ${name}?`,
            confirmButtonText: 'Да',
            confirmButtonColor: '#D9292E',
            cancelButtonText: 'Нет',
            showCancelButton: true,
            showConfirmButton: true,
        });
        if (result.isConfirmed) {
            await adminsService.regenerate(key, env)
            reload()
        }
    }

    const reload = () => {
        env && adminsService.getAll(env).then(as => setAdmins(as))
    }

    const toggleSuperAdmin = async (key: string, name: string, newIsSuperAdmin: boolean) => {
        if (!env) {
            return
        }

        const result = await MySwal.fire({
            title: `Сделать ${name} ${newIsSuperAdmin ? 'суперадмином' : 'обычным админом'}?`,
            confirmButtonText: 'Да',
            confirmButtonColor: '#D9292E',
            cancelButtonText: 'Нет',
            showCancelButton: true,
            showConfirmButton: true,
        });
        if (result.isConfirmed) {
            await adminsService.update(key, env, {isSuperAdmin: newIsSuperAdmin})
            reload()
        }
    }

    useEffect(() => {
        reload()
    }, [env]);

    return <div className="d-flex flex-column align-items-center w-100 mt-2">
        <div className="w-75 d-flex flex-column align-items-end">
            <div>
                <CreateAdminButton onAdminCreated={reload}/>
            </div>
            <div className="overflow-auto mt-2" style={{height: '90vh'}}>
                <table className="table table-light table-hover">
                    <thead>
                    <tr>
                        <th>Ключ</th>
                        <th>Имя</th>
                        <th>Супер Админ</th>
                        <th>Просмотрено пользователей</th>
                        <th>Токен</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {admins.map(a => <tr key={a.userKey}>
                        <td>{a.userKey}</td>
                        <td>{a.name}</td>
                        <td>{a.isSuperAdmin ? 'Да 🌟' : 'Нет'}</td>
                        <td>{a.reviewedUserCount}</td>
                        <td style={{maxWidth: 200}}>{a.token}</td>
                        <td>
                            <div className="d-flex flex-row">
                                <Button variant="outline-primary mx-1"
                                        title="Пересоздать токен"
                                        onClick={() => handleRegenerateToken(a.userKey, a.name)}
                                        className="d-flex align-items-center">
                                    <AiOutlineRedo size={18} className="mx-1"/> Токен
                                </Button>
                                {isModerationPackAvailable(env) &&
                                    <Button variant="outline-primary mx-1" title="Статус суперадмина"
                                            onClick={() => toggleSuperAdmin(a.userKey, a.name, !a.isSuperAdmin)}
                                            className="d-flex align-items-center">
                                        {a.isSuperAdmin ?
                                            <FaStar size={18} className="mx-1"/> :
                                            <FaRegStar size={18} className="mx-1"/>

                                        }
                                        Статус
                                    </Button>
                                }
                                <Button variant="outline-danger" title="Заблокировать"
                                        onClick={() => handleBlockAdmin(a.userKey, a.name)}
                                        className="d-flex align-items-center">
                                    <AiOutlineClose size={18} className="mx-1"/> Заблокировать
                                </Button>
                            </div>
                        </td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}