import React, {useContext, useEffect, useState} from 'react';
import {statsService, StatsUserPayload} from 'services';
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import {DatePicker} from 'components';
import {AuthContext} from 'contexts';

export const AdminsStatsGraph = () => {
    const {env} = useContext(AuthContext);

    const now = new Date(Date.now());
    const [users, setUsers] = useState<StatsUserPayload[]>([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [startDate, setStartDate] = useState(
        new Date(now.getFullYear(), now.getMonth(), 1),
    );
    const [endDate, setEndDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1));

    useEffect(() => {
        if (!env) {
            return;
        }

        setUsers([]);
        statsService.get(startDate, endDate, env).then(setUsers)
    }, [env, endDate, startDate]);

    const days = users
        .reduce(
            (prevV, currV) => [
                ...prevV,
                ...currV.stats.map(s => ({day: s.day, dayString: s.dayString})),
            ],
            [] as { day: Date; dayString: string }[],
        )
        .filter(
            (date, i, self) =>
                self.findIndex(d => d.dayString === date.dayString) === i,
        )
        .sort((a, b) => a.day.getTime() - b.day.getTime())
        .map(x => x.dayString);

    const names = users.map(u => u.name);
    const data = [] as any[];
    for (let i in days) {
        const day = days[i];
        const item = {
            name: day,
        } as any;
        for (let userIndex in users) {
            const user = users[userIndex];
            const stats = user.stats.find(
                s => s.dayString === day,
            );
            item[user.name] = stats?.totalCount;
        }
        data.push(item);
    }

    const colors = [
        '#4a80d5',
        '#e6983f',
        '#50b5b5',
        '#d85858',
        '#7fb35c',
        '#9b6dd9',
        '#e67d3f',
        '#4a9fd5',
        '#d85f87',
        '#57a187',
        '#b366cc',
        '#d4934a',
        '#4b8ba6',
        '#cc5252',
        '#6aa64d',
        '#8f67a6'
    ];

    const CustomTooltip = ({active, payload, label}: TooltipProps<any, string>) => {
        if (!active || !payload || !payload.length) {
            return null
        }

        return (
            <div className="bg-white border border-light rounded p-3">
                <p className="label">{label}</p>
                <table className="table table-sm table-hover">
                    <thead>
                    <tr>
                        <th scope="col">Имя</th>
                        <th scope="col">Просмотренных</th>
                        <th scope="col">Заблокированных</th>
                        <th scope="col">Отложенных</th>
                        <th scope="col">Разблокированных</th>
                        <th scope="col">На верификацию</th>
                        <th scope="col">Временно разблокированных</th>
                    </tr>
                    </thead>
                    <tbody>
                    {payload.map((pld, i) => {
                        const user = users.find(u => u.name === pld.name);
                        const stats = user?.stats.find(
                            s => s.dayString === label,
                        );
                        return <tr key={i.toString() + (pld.dataKey ?? "")}
                                   style={{color: pld.color}}>
                            <td className="d-flex flex-row align-items-center">
                                {pld.dataKey}
                                <span className="badge mx-1" style={{backgroundColor: pld.color}}>
                                    {pld.value}
                                </span>
                            </td>
                            {stats?.actions &&
                                <>
                                    <td>{stats?.actions?.Feedback ?? "-"}</td>
                                    <td>{stats?.actions?.Ban ?? "-"}</td>
                                    <td>{stats?.actions?.PostponeReview ?? "-"}</td>
                                    <td>{stats?.actions?.Unban ?? "-"}</td>
                                    <td>{stats?.actions?.ForcedVerification ?? "-"}</td>
                                    <td>{stats?.actions?.TemporaryUnban ?? "-"}</td>
                                </>
                            }
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        );

    };

    const isNewStats = !!users.find(u => u.stats.find(s => s.actions))

    return (
        <div className="vh-100 d-flex flex-column m-2 m-lg-3">
            <div className="row mb-3">
                <div className="col flex align-items-end">
                    <DatePicker
                        label="Начало периода"
                        value={startDate}
                        onChange={setStartDate}
                    />
                </div>
                <div className="col">
                    <DatePicker
                        label="Конец периода"
                        value={endDate}
                        onChange={setEndDate}
                    />
                </div>
            </div>

            {users.length ? (
                <>
                    <ResponsiveContainer width="100%" height="70%">
                        <ComposedChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 30,
                                right: 30,
                                left: 30,
                                bottom: 5,
                            }}>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="name"/>
                            <YAxis/>
                            <Tooltip content={isNewStats ? <CustomTooltip/> : undefined}/>
                            <Legend
                                layout={'horizontal'}
                                align={'center'}
                                wrapperStyle={{paddingTop: 10, paddingLeft: '10%', paddingRight: '10%'}}
                                formatter={(value, entry, index) => {
                                    const count = data.reduce(
                                        (previousValue, currentValue) =>
                                            previousValue + (currentValue[value] ?? 0),
                                        0,
                                    );
                                    return `${value}, ${count}`
                                }}
                                onClick={d => setSelectedUser(prev => prev === d.dataKey ? '' : d.dataKey)}
                            />
                            {names.map((n, i) => (
                                <Bar
                                    stackId="day"
                                    key={n}
                                    fill={colors[i % colors.length]}
                                    type="monotone"
                                    dataKey={n}
                                    stroke={colors[i % colors.length]}
                                    hide={selectedUser ? n !== selectedUser : false}
                                />
                            ))}
                        </ComposedChart>
                    </ResponsiveContainer>
                    <div></div>
                </>
            ) : (
                <div>Загружается...</div>
            )}
        </div>
    );
};
