import React, {CSSProperties, useContext, useEffect, useState} from "react";
import {AuthContext} from "contexts";
import {BlockedCountriesPayload, setupServices} from "services";
import {Button, Container, Form, Modal, Row} from "react-bootstrap";
import {settingsStore} from "stores";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";

const override: CSSProperties = {
    borderColor: "white",
    marginRight: 4
};

export const BlockedCountiesPage = () => {
    const {env} = useContext(AuthContext);

    const [isSaving, setIsSaving] = useState(false);
    const [selectedCounties, setSelectedCountries] = useState<string[]>([]);
    const [blockedState, setBlockedState] = useState<BlockedCountriesPayload>();
    const [isCountriesAddModalVisible, setIsCountriesAddModalVisible] = useState(false);
    const [selectedCountryInAdd, setSelectedCountryInAdd] = useState<string>();

    const selectedCountry = selectedCountryInAdd ? settingsStore.countries.find(c => c.countryCode === selectedCountryInAdd) : undefined

    const handleSubmit = async () => {
        if (!env) {
            return
        }
        setIsSaving(true)
        const currentCountriesResponse = await setupServices.getBlockedCountries(env);

        const countriesToDelete = currentCountriesResponse?.countries.filter(c => !selectedCounties.find(sc => sc === c.countryCode)).map(c => c.countryCode) ?? []
        await Promise.all(countriesToDelete.map(c => setupServices.deleteBlockedCounty(env, c)))

        const countriesToAdd = selectedCounties.filter(sc => !currentCountriesResponse?.countries.find(c => sc === c.countryCode))
        await Promise.all(countriesToAdd.map(sc => setupServices.addBlockedCounty(env, sc)))

        setTimeout(async () => {
            await reloadCounties()
            setIsSaving(false)
        }, 10000)
    }

    const handleAddCountry = () => {
        selectedCountryInAdd && setSelectedCountries(prev => ([...prev, selectedCountryInAdd]))
        selectedCountry && setBlockedState(st => ({
            ...st,
            countries: [...(st?.countries ?? []), {
                name: selectedCountry?.name,
                countryCode: selectedCountry?.countryCode
            }]
        }))
        setSelectedCountryInAdd(undefined)
        setIsCountriesAddModalVisible(false)
    }

    const reloadCounties = async () => {
        if (!env) {
            return;
        }

        await setupServices.getBlockedCountries(env).then(countries => {
            countries && setBlockedState(countries)
            countries && setSelectedCountries(countries.countries.map(c => c.countryCode))
        })
    }

    useEffect(() => {
        if (!env) {
            return;
        }
        settingsStore.loadCountries(env)
        reloadCounties()
    }, [env])

    return <>
        <Form className="m-2 m-lg-3">
            <Container>
                <Row>
                    <Form.Label className="col-sm-2 col-form-label">
                        Заблокированные страны
                    </Form.Label>
                    <div className="col-sm-10 mt-3">
                        {blockedState?.countries.map(c =>
                            <div key={c.countryCode} className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                                       checked={!!selectedCounties?.find(sc => sc === c.countryCode)}
                                       onChange={e => setSelectedCountries(prev => e.target.checked ? [...prev, c.countryCode] : prev?.filter(p => p !== c.countryCode))}/>
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    {c.name}
                                </label>
                            </div>)}
                        <Button variant="outline-success" size='sm' className="mt-3"
                                onClick={() => setIsCountriesAddModalVisible(true)}>Добавить страну</Button>
                    </div>
                </Row>
                <Button disabled={isSaving} variant="primary" onClick={handleSubmit}
                        className="mt-3 d-flex align-items-center">
                    {isSaving && <ClipLoader color="#3459e6" cssOverride={override} size={15}/>}
                    {isSaving ? "Сохраняется..." : "Сохранить"}
                </Button>
            </Container>
        </Form>
        <Modal show={isCountriesAddModalVisible} onHide={() => setIsCountriesAddModalVisible(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Добавить страну</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Страна</Form.Label>
                        <Select options={settingsStore.countriesSelect}
                                defaultValue={selectedCountry ? {
                                    label: `${selectedCountry.flag} ${selectedCountry.name}`,
                                    value: selectedCountry.countryCode
                                } : undefined}
                                isOptionSelected={i => i.value === settingsStore.selectedCountry?.countryCode}
                                placeholder="Все страны"
                                isClearable={true}
                                onChange={i => setSelectedCountryInAdd(i?.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setIsCountriesAddModalVisible(false)}>
                    Закрыть
                </Button>
                <Button disabled={!selectedCountry || isSaving}
                        variant="primary"
                        onClick={handleAddCountry}>
                    Добавить
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}