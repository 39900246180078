import React, {FC} from 'react';
import {BucketInfo} from "interfaces";
import {Accordion} from "react-bootstrap";
import {toDateString, toDayDateString} from "utils";
import {ModerationSection} from "components/ModerationSection";

type GroupByDateResult = {
    [date: string]: number;
};

interface Props {
    label: string;
    buckets: BucketInfo<Date>[];
    type: "sparks" | "boosts";
}

export const ModerationBuckets: FC<Props> = ({label, buckets, type}) => {
    if (!buckets.length) {
        return null
    }

    const hasDuration = type === "boosts";

    const groupByDate = (dates: Date[]) => {
        return dates.reduce((acc: GroupByDateResult, date: Date) => {
            // Format the date to ignore time
            const dateStr = toDayDateString(date, false) ?? 'unknown';
            // If the date is already in the accumulator, increment its count
            if (acc[dateStr]) {
                acc[dateStr] += 1;
            } else {
                // Otherwise, add the date to the accumulator with a count of 1
                acc[dateStr] = 1;
            }
            return acc;
        }, {});
    };

    const tdStyle = {
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10
    }

    return <ModerationSection label={label}>
        <Accordion key={label} flush alwaysOpen={false} className={'w-100'}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>История</Accordion.Header>
                <Accordion.Body>
                    <table className="table-light w-100">
                        <thead>
                        <tr>
                            <td className="fw-semibold" style={{paddingRight: 10}}>Тип</td>
                            {hasDuration &&
                                <td className="fw-semibold" style={{paddingRight: 10}}>Доступно до</td>}
                            <td className="fw-semibold" style={{paddingRight: 10}}>Доступно</td>
                            <td className="fw-semibold" style={{paddingRight: 10}}>Использования</td>
                            {hasDuration &&
                                <td className="fw-semibold" style={{paddingRight: 10}}>Длительность
                                    (минут)</td>}
                        </tr>
                        </thead>
                        <tbody>
                        {buckets.map((h, i) => {
                                const minsDiff = Math.round(h.activeTimeInSeconds / 60);
                                const groupedUsageDates = groupByDate(h.usageTimes);

                                return <tr key={h.date?.toString() + `${i}`} className={'border-top'}>
                                    <td style={{paddingRight: 10, paddingTop: 10, paddingBottom: 10}}>{h.type}</td>
                                    {hasDuration &&
                                        <td style={tdStyle}>{h.expiresDate && toDateString(h.expiresDate, false)}</td>}
                                    <td style={tdStyle}>{h.unusedCount}/{h.totalCount}</td>
                                    <td style={tdStyle}>
                                        {Object.keys(groupedUsageDates).map(k => (`${k} (${groupedUsageDates[k]})`)).join(', ')}
                                    </td>
                                    {hasDuration && <td style={tdStyle}>{minsDiff}</td>}
                                </tr>
                            }
                        )}
                        </tbody>
                    </table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </ModerationSection>
}
