import React, {FC, useContext} from 'react';
import {ModerationUser} from "interfaces";
import {toDateString} from "utils";
import {toStatusString} from "enums";
import {ModerationRow} from "components/ModerationRow";
import {ModerationUserStatus} from "components/ModerationUserCard/components/ModerationUserInfo";
import {AuthContext} from "contexts";
import {FaCheck} from "react-icons/fa";

interface Props {
    user: ModerationUser
}

export const StatusHistory: FC<Props> = ({user}) => {
    const {user: myUser} = useContext(AuthContext);
    if (!user.statusHistory?.length) {
        return null;
    }

    return <>
        {user.reviewer && user.reviewer.key !== myUser?.id &&
            <>
                <ModerationRow label={"Модератор"}
                               value={<ModerationUserStatus value={user.reviewer}/>}
                               isInsideTable={true}/>
                <hr/>
            </>}
        <div className="table-responsive" style={{maxHeight: 600, overflow: 'auto'}}>
            <table className="table-light w-100">
                <thead className="">
                <tr>
                    <td className="fw-semibold" style={{paddingRight: 10}}>Статус</td>
                    <td className="fw-semibold" style={{paddingRight: 10}}>Причина</td>
                    <td className="fw-semibold" style={{paddingRight: 10}}>Дата</td>
                    <td className="fw-semibold" style={{paddingRight: 10}}>Инициатор</td>
                    <td className="fw-semibold" style={{paddingRight: 10}}>Ревью пройдено</td>
                    <td className="fw-semibold" style={{paddingRight: 10}}>Верификация пройдена</td>
                </tr>
                </thead>
                <tbody>
                {user.statusHistory?.map((h, i) => {
                        return <tr key={user.id + i}>
                            <td className="fw-semibold" style={{paddingRight: 10}}>{toStatusString(h.status)}</td>
                            <td style={{paddingRight: 10}}>{h.reason}</td>
                            <td style={{paddingRight: 10}}>{toDateString(h.date, false)}</td>
                            <td style={{paddingRight: 10}}>{h.initiator?.name} {h.initiator ? `(${h.initiator.status})` : ''}</td>
                            <td style={{paddingRight: 10}}>{h.isReviewed && <FaCheck/>}</td>
                            <td style={{paddingRight: 10}}>{h.isVerified && <FaCheck/>}</td>
                        </tr>
                    }
                )}
                </tbody>
            </table>
        </div>
    </>
}

