export enum Sexualities {
    Unknown = '0',
    Straight = '1',
    Gay = '2',
    Bi = '3',
    Queer = '4',
    Asexual = '5'
}

export const SexualityLabel: Record<Sexualities, string> = {
    [Sexualities.Unknown]: '❓Неизвестно',
    [Sexualities.Straight]: '👩‍❤️‍👨 Гетеро',
    [Sexualities.Gay]: '🏳️‍🌈 Гей/Лесби',
    [Sexualities.Bi]: '🤔 Би',
    [Sexualities.Queer]: '🏳️‍⚧️ Queer',
    [Sexualities.Asexual]: '🦢 Асексуальность',
}

export const SexualityEvents: Record<Sexualities, string> = {
    [Sexualities.Unknown]: 'unknown',
    [Sexualities.Straight]: 'straight',
    [Sexualities.Gay]: 'gay',
    [Sexualities.Bi]: 'bi',
    [Sexualities.Queer]: 'queer',
    [Sexualities.Asexual]: 'asexual',
}