import {Apps} from "enums";
import {request} from "services/request";
import {createUserIdParam, EnvSetup} from "enums/Envs";

export interface AdminUser {
    userKey: string;
    reviewedUserCount: number;
    isSuperAdmin: boolean;
    token: string;
    name: string;
}

export const adminsService = {
    getAll: async (env: EnvSetup): Promise<AdminUser[]> => {
        if (env.app === Apps.Instinct) {
            const response = await request<{ users: AdminUser[] }>({
                url: "manage/admins",
                method: 'post',
                data: {}
            });
            return response?.users ?? []
        }
        if (env.app === Apps.Flava) {
            const response = await request<{ profiles: AdminUser[] }>({
                url: "manage/admins",
                method: 'post',
                data: {}
            });
            return response?.profiles ?? []
        }
        if (env.app === Apps.Spark) {
            const response = await request<{ sparkers: AdminUser[] }>({
                url: "manage/admins",
                method: 'post',
                data: {}
            });
            return response?.sparkers ?? []
        }
        alert("adminsService.getAll Not implemented")
        return []
    },
    create: async (name: string, app: Apps) => {
        return request<AdminUser>({
            url: "manage/create",
            method: 'post',
            data: {name}
        });
    },
    block: async (key: string, env: EnvSetup) => {
        return request({
            url: "manage/block",
            method: 'put',
            data: createUserIdParam(env, key)
        });
    },
    regenerate: async (key: string, env: EnvSetup) => {
        return request({
            url: "manage/regenerate-token",
            method: 'put',
            data: createUserIdParam(env, key)
        });
    },
    update: async (key: string, env: EnvSetup, data: { isSuperAdmin: boolean }) => {
        return request({
            url: `manage/admins/${key}`,
            method: 'put',
            data: data
        });
    }
}