import {
    AttractivePhotoPayloadFlava,
    convertAttractivePhotoPayloadFlavaToBloom,
    convertPhotoPayloadSparkToBloom,
    PhotoPayloadBloom,
    PhotoPayloadFlava,
    PhotoPayloadInstinct,
    PhotoPayloadSpark
} from "./photoPayloadInstinct";
import {
    BucketInfo,
    CountPayload,
    DeviceActivity,
    DuplicatePhoto,
    MetaTagGroup,
    ModerationReport,
    ModerationUser,
    StatusHistoryItem,
    SubscriptionHistory,
    UserActor,
    UserQuestion
} from "interfaces";
import {Apps, MoodName, Moods, UserReviewTags, UserStatuses, VerificationStatus} from "enums";
import {ImagePayloadBloom, ImagePayloadInstinct} from "./imagePayloadInstinct";
import {InterestDto} from "./interfaceDto";
import {VerificationRejectReasons} from "enums/VerificationRejectReasons";
import {InterestGroups} from "enums/InterestGroups";

const toCount = (count: number | CountPayload) => {
    return typeof count === 'number' ? {allTime: count, current: count} : count
}

export const toDate = (date: string = '', app: Apps) => {
    if (date) {
        const dateObj = new Date(date);
        return new Date(!dateObj && (app === Apps.Instinct && date[date.length - 1] !== 'Z') ? `${date}Z` : date)
    }

    return undefined
};

interface CommonSearchPayload {
    photosWithDuplicateHashes: DuplicatePhoto[];
    devices: DeviceActivity<string>[];
    premiumType: string;
    wasReviewed: boolean;
    reviewedDate: string;
    reviewTags: Array<{
        date: string;
        info?: string;
        tag: UserReviewTags
    }>;
    reports: ModerationReport[];
    aboutAnswers: UserQuestion[];
    version: string;
    verificationStatus: VerificationStatus;
    verificationPhoto?: ImagePayloadInstinct;
    verificationDenyReason?: string;
    outgoingReports: ModerationReport[];
    outgoingReportsCount: number;
    subscriptionHistory: {
        date: string;
        expiresDate: string;
        provider: string;
    }[];
    countryFlag: string;
    countryName: string;
    platform: string;
    loginMethods?: string[];
    statusHistory?: {
        status: UserStatuses;
        reason: string;
        date: string;
        initiator: UserActor;
        isReviewed: boolean;
        isVerified: boolean;
    }[];
    reviewer?: UserActor;
    verifier?: UserActor;
    maxDistanceFilter?: number;
    boostBuckets: Array<BucketInfo<string>>;
    greetingBuckets: Array<BucketInfo<string>>;

    verificationStartDate?: string;
    verificationEndDate?: string;
    verificationHistory: {
        verificationEndDate: string;
        verificationPhoto?: ImagePayloadInstinct;
        verificationStartDate: string;
        verificationStatus: VerificationStatus
        verificationType: number
        verifier: UserActor
        verificationDenyReason: string
    }[]
    metaTags: {
        groupId: InterestGroups;
        key: string;
        metaTag: string;
        tag: string;
    }[]
    isCouple: boolean
    discoveryCity: string
    ipAddresses: {
        countryCode: string;
        ipAddress: string;
        lastUsageDate: string;
        users: UserActor[];
    }[]
}

const convertCommonParts = (payload: CommonSearchPayload, app: Apps): Partial<ModerationUser> => {
    return {
        isCouple: payload.isCouple,
        metaTags: payload.metaTags?.reduce((previousValue, currentValue) => {
            if (previousValue.findIndex(pv => pv.groupId === currentValue.groupId) >= 0) {
                return previousValue.map(pv => {
                    if (pv.groupId === currentValue.groupId) {
                        return {...pv, interests: [...pv.interests, currentValue]}
                    } else {
                        return pv
                    }
                })
            } else {
                return [...previousValue, {groupId: currentValue.groupId, interests: [currentValue]}]
            }
        }, [] as MetaTagGroup[]) ?? [],
        premiumType: payload.premiumType,
        aboutAnswers: payload.aboutAnswers ?? [],
        wasReviewed: payload.wasReviewed,
        reviewedDate: toDate(payload.reviewedDate, app),
        reviewTags: payload.reviewTags?.map(t => ({date: toDate(t.date, app), tag: t.tag, info: t.info})) ?? [],
        reports: payload.reports,
        outgoingReports: payload.outgoingReports,
        outgoingReportsCount: payload.outgoingReportsCount,
        version: payload.version,
        verificationStatus: payload.verificationStatus,
        verificationPhoto: payload.verificationPhoto ? {...payload.verificationPhoto, ...({link: payload.verificationPhoto.url})} : undefined,
        subscriptionHistory: payload.subscriptionHistory?.map(s => ({
            date: toDate(s.date, app),
            expiresDate: toDate(s.expiresDate, app),
            provider: s.provider
        } as SubscriptionHistory)) ?? [],
        countryFlag: payload.countryFlag,
        countryName: payload.countryName,
        platform: payload.platform,
        loginMethods: payload.loginMethods,
        statusHistory: payload.statusHistory?.map(sh => ({
            ...sh,
            date: toDate(sh.date, app),
        }) as StatusHistoryItem) ?? [],
        reviewer: payload.reviewer,
        verifier: payload.verifier,
        maxDistanceFilter: payload.maxDistanceFilter,
        boostBuckets: payload.boostBuckets?.map(bb => ({
            ...bb,
            date: toDate(bb.date, app),
            expiresDate: toDate(bb.expiresDate, app),
            usageTimes: bb.usageTimes.map(ut => toDate(ut, app)).filter(ut => ut) as Date[]
        })) ?? [],
        greetingBuckets: payload.greetingBuckets?.map(bb => ({
            ...bb,
            date: toDate(bb.date, app),
            expiresDate: toDate(bb.expiresDate, app),
            usageTimes: bb.usageTimes.map(ut => toDate(ut, app)).filter(ut => ut) as Date[]
        })) ?? [],
        verificationRejectReason: payload.verificationDenyReason as VerificationRejectReasons,
        verificationHistory: payload.verificationHistory?.map(h => ({
            verificationEndDate: toDate(h.verificationEndDate, app),
            verificationPhoto: h.verificationPhoto ? {...h.verificationPhoto, ...({link: h.verificationPhoto.url})} : undefined,
            verificationStartDate: toDate(h.verificationStartDate, app),
            verificationStatus: h.verificationStatus,
            verificationType: h.verificationType,
            verifier: h.verifier,
            rejectReason: h.verificationDenyReason as VerificationRejectReasons
        })) ?? [],
        verificationStartDate: toDate(payload.verificationStartDate, app),
        verificationEndDate: toDate(payload.verificationEndDate, app),
        devices: payload.devices?.map(d => ({
            device: d.device,
            lastActivityDate: toDate(d.lastActivityDate, app)
        })) ?? [],
        photosWithDuplicateHashes: payload.photosWithDuplicateHashes,
        ipAddresses: payload.ipAddresses?.map(a => ({...a, lastUsageDate: toDate(a.lastUsageDate, app)}))
    }
}

export interface SearchUserPayloadBloom extends CommonSearchPayload {
    id: string;
    name: string;
    gender: number;
    sexuality: number;
    about: string;
    birthday: string;
    distance: 0;
    age: 0;
    userStatusDate: string;
    avatarV2: PhotoPayloadBloom;
    photosV2: PhotoPayloadBloom[];
    moods: number[];

    isBanned: boolean;
    reportsCount: number;
    hasPremium: boolean;
    isPaused: boolean;
    isVerified: boolean;
    scamMessages: string[];

    status: string;
    latitude: number;
    longitude: number;
    ipCountryCode: string;
    countryCode: string;

    likesCount: number | CountPayload;
    chatsCount: number | CountPayload;
    displaysCount: number | CountPayload;
    matchesCount: number | CountPayload;
    outgoingDislikesCount: number | CountPayload;
    outgoingLikesCount: number | CountPayload;

    verificationType?: number;
    startDate?: string;
    previousEndDate?: string;
    temporaryUnbannedDate?: string;
    city?: string;
    registrationDate?: string;
    premiumActivationDate?: string;
    premiumRenewCount: number;
    sessionNumber: number;
    isPushNotificationEnabled: boolean;
    energy: { likes: number, lastRefillDate: string }
}

export const convertToSearchBloomToModerationUser = (user: SearchUserPayloadBloom) => {
    return {
        id: user.id,
        name: user.name,
        gender: user.gender?.toString(),
        sexuality: user.sexuality.toString(),
        about: user.about,
        distance: user.distance,
        age: user.age,
        onlineDate: new Date(user.userStatusDate),
        photos: [...(user.avatarV2 ? [user.avatarV2] : []), ...user.photosV2],
        status: user.status,
        moods: user.moods.map(m => m.toString()),
        interests: user.moods.map(m => MoodName[m.toString() as '1' | '2' | '3']),
        hasPremium: user.hasPremium,
        isBanned: user.isBanned,
        isPaused: user.isPaused,
        isVerified: user.isVerified,
        reportsCount: user.reportsCount,
        scamMessages: user.scamMessages,
        birthday: new Date(user.birthday),
        latitude: user.latitude,
        longitude: user.longitude,
        countryCode: user.countryCode,
        ipCountryCode: user.ipCountryCode,
        displaysCount: toCount(user.displaysCount),
        likesCount: toCount(user.likesCount),
        chatsCount: toCount(user.chatsCount),
        outgoingDislikesCount: toCount(user.outgoingDislikesCount),
        outgoingLikesCount: toCount(user.outgoingLikesCount),
        verificationType: user.verificationType,
        startDate: user.startDate ? new Date(user.startDate) : null,
        previousEndDate: user.previousEndDate ? new Date(user.previousEndDate) : null,
        matchesCount: user.matchesCount,
        temporaryUnbannedDate: user.temporaryUnbannedDate ? new Date(user.temporaryUnbannedDate) : undefined,
        city: user.city,
        registrationDate: user.registrationDate ? toDate(user.registrationDate, Apps.Bloom) : undefined,
        premiumStartDate: user.premiumActivationDate ? toDate(user.premiumActivationDate, Apps.Bloom) : undefined,
        renewCount: user.premiumRenewCount,
        sessionNumber: user.sessionNumber,
        isPushNotificationEnabled: user.isPushNotificationEnabled,
        energy: {
            refillDate: user.energy.lastRefillDate ? new Date(user.energy.lastRefillDate) : undefined,
            amount: user.energy.likes
        },
        ...convertCommonParts(user, Apps.Bloom)
    } as ModerationUser
}

export interface SearchUserPayloadSpark extends CommonSearchPayload {
    maxDistanceFilter: number;
    id: string;
    name: string;
    bio: string;
    connectedTime: string;
    isConnected: boolean;
    theme: number;
    feedbackDate: string;
    isVerified: boolean;
    gender: number;

    status: string;
    reportsCount: number;
    isBanned: boolean;
    isPaused: boolean;
    scamMessages: string[];
    hasPremium: boolean;
    latitude: number;
    longitude: number;
    ipCountryCode: string;
    countryCode: string;

    likesCount: number | CountPayload;
    chatsCount: number | CountPayload;
    displaysCount: number | CountPayload;
    matchesCount: number | CountPayload;
    outgoingDislikesCount: number | CountPayload;
    outgoingLikesCount: number | CountPayload;

    verificationType?: number;
    startDate?: string;
    previousEndDate?: string;

    temporaryUnbannedDate?: string;
    city?: string;
    registrationDate?: string;
    sexuality: number;
    premiumActivationDate?: string;
    premiumRenewCount: number;
    sessionNumber: number;
    isPushNotificationEnabled: boolean;
    energy: { previousDemandDate: Date, volume: number },
    icon?: PhotoPayloadSpark
    images: PhotoPayloadSpark[]
    awaitings: number[];
}

export const convertToSearchSparkToModerationUser = (user: SearchUserPayloadSpark) => {
    return {
        id: user.id,
        name: user.name,
        gender: user.gender?.toString(),
        sexuality: user.sexuality?.toString(),
        about: user.bio,
        distance: user.maxDistanceFilter,
        onlineDate: new Date(user.connectedTime),
        hasPremium: user.hasPremium,
        isBanned: user.isBanned,
        isPaused: user.isPaused,
        isVerified: user.isVerified,
        reportsCount: user.reportsCount,
        scamMessages: user.scamMessages,
        latitude: user.latitude,
        longitude: user.longitude,
        countryCode: user.countryCode,
        ipCountryCode: user.ipCountryCode,
        displaysCount: toCount(user.displaysCount),
        likesCount: toCount(user.likesCount),
        chatsCount: toCount(user.chatsCount),
        outgoingDislikesCount: toCount(user.outgoingDislikesCount),
        outgoingLikesCount: toCount(user.outgoingLikesCount),
        status: user.status,
        verificationType: user.verificationType,
        startDate: user.startDate ? toDate(user.startDate, Apps.Spark) : null,
        previousEndDate: user.previousEndDate ? toDate(user.previousEndDate, Apps.Spark) : null,
        matchesCount: user.matchesCount,
        temporaryUnbannedDate: user.temporaryUnbannedDate ? toDate(user.temporaryUnbannedDate, Apps.Spark) : undefined,
        city: user.city,
        registrationDate: user.registrationDate ? toDate(user.registrationDate, Apps.Spark) : undefined,
        premiumStartDate: user.premiumActivationDate ? toDate(user.premiumActivationDate, Apps.Spark) : undefined,
        renewCount: user.premiumRenewCount,
        sessionNumber: user.sessionNumber,
        isPushNotificationEnabled: user.isPushNotificationEnabled,
        energy: {
            refillDate: user.energy.previousDemandDate ? new Date(user.energy.previousDemandDate) : undefined,
            amount: user.energy.volume
        },
        photos: [...(user.icon ? [convertPhotoPayloadSparkToBloom(user.icon)] : []), ...user.images.map(i => convertPhotoPayloadSparkToBloom(i))],
        interests: user.awaitings.map(m => MoodName[m.toString() as Moods]),
        ...convertCommonParts(user, Apps.Spark),
    } as ModerationUser
}

export interface SearchUserPayloadFlava extends CommonSearchPayload {
    firstname: string
    age: number
    id: string
    distance: number
    birthday: string;
    description: string
    gender: string
    sexuality: string
    picV2: PhotoPayloadFlava
    pics: PhotoPayloadFlava[]
    wasOnlineDate: string
    isOnline: boolean
    isFeedback: boolean
    interests: { text: string }[]
    isVerified: boolean

    likesCount: number | CountPayload;
    chatsCount: number | CountPayload;
    displaysCount: number | CountPayload;
    matchesCount: number | CountPayload;
    outgoingDislikesCount: number | CountPayload;
    outgoingLikesCount: number | CountPayload;

    verificationType?: number;
    startDate?: string;
    previousEndDate?: string;

    temporaryUnbannedDate?: string;
    reportsCount: number;
    latitude: number;

    longitude: number;
    countryCode: string;
    ipCountryCode: string;

    status: string;

    hasPremium: boolean;
    isBanned: boolean;
    isPaused: boolean;
    scamMessages: string[]

    attractedToFeatures: { text: string }[]
    attractiveFeatures: { id: number, text: string, photo: AttractivePhotoPayloadFlava }[]
    city?: string;
    cityName?: string;
    registrationDate?: string;
    premiumActivationDate?: string;
    premiumRenewCount: number;
    sessionNumber: number;
    isPushNotificationEnabled: boolean;
    manualCity: { cityName: string, countryFlag: string, countryName: string }
}

export const convertToSearchFlavaToModerationUser = (user: SearchUserPayloadFlava) => {
    return {
        discoveryCity: user.manualCity,
        id: user.id,
        name: user.firstname,
        gender: user.gender?.toString(),
        about: user.description,
        distance: user.distance,
        onlineDate: new Date(user.wasOnlineDate),
        sexuality: user.sexuality.toString(),
        age: user.age,
        birthday: new Date(user.birthday),
        hasPremium: user.hasPremium,
        isBanned: user.isBanned,
        isPaused: user.isPaused,
        isVerified: user.isVerified,
        moods: [],
        interests: user.interests.map(i => i.text),
        reportsCount: user.reportsCount,
        scamMessages: user.scamMessages,
        latitude: user.latitude,
        longitude: user.longitude,
        countryCode: user.countryCode,
        ipCountryCode: user.ipCountryCode,
        displaysCount: toCount(user.displaysCount),
        likesCount: toCount(user.likesCount),
        chatsCount: toCount(user.chatsCount),
        outgoingDislikesCount: toCount(user.outgoingDislikesCount),
        outgoingLikesCount: toCount(user.outgoingLikesCount),
        matchesCount: toCount(user.matchesCount),
        status: user.status,
        verificationType: user.verificationType,
        startDate: user.startDate,
        previousEndDate: user.previousEndDate,
        photos: [
            ...(user.picV2 ? [{
                ...user.picV2,
                key: user.picV2.id,
                isPrivate: false,
                image: user.picV2?.image?.map(
                    i => ({...i, link: i.url} as ImagePayloadBloom),
                ),
            }] : []),
            ...user.pics.map(p => ({
                ...p,
                key: p.id,
                isPrivate: false,
                image: p.image.map(i => ({...i, link: i.url} as ImagePayloadBloom)),
            }))
        ],
        temporaryUnbannedDate: user.temporaryUnbannedDate ? new Date(user.temporaryUnbannedDate) : undefined,
        attractiveFeatures: user.attractiveFeatures.map(a => ({
            ...a,
            photo: a.photo && convertAttractivePhotoPayloadFlavaToBloom(a.photo)
        })),
        attractedToFeatures: user.attractedToFeatures.map(f => ({text: f.text})),
        city: user.city ?? user.cityName,
        registrationDate: toDate(user.registrationDate, Apps.Spark),
        premiumStartDate: toDate(user.premiumActivationDate, Apps.Spark),
        renewCount: user.premiumRenewCount,
        sessionNumber: user.sessionNumber,
        isPushNotificationEnabled: user.isPushNotificationEnabled,
        ...convertCommonParts(user, Apps.Flava)
    } as ModerationUser
}

export interface SearchUserPayloadInstinct extends CommonSearchPayload {
    id: string;
    about: string;
    distance: number;
    age: number;
    birthday: Date;
    name: string;
    avatarV2: PhotoPayloadInstinct;
    photos: PhotoPayloadInstinct[];
    onlineDate: Date;
    interests: InterestDto[];
    isBanned: boolean;
    reportsCount: number;
    hasPremium: boolean;
    isPaused: boolean;
    isVerified: boolean;
    scamMessages: string[];
    gender: number;

    lat: number;
    latitude: number;
    longitude: number;
    long: number;
    ipCountryCode: string;
    country: string;
    countryCode: string;

    likesCount: number | CountPayload;
    chatsCount: number | CountPayload;
    displaysCount: number | CountPayload;
    matchesCount: number | CountPayload;
    outgoingDislikesCount: number | CountPayload;
    outgoingLikesCount: number | CountPayload;
    status: string;

    verificationType?: number;
    startDate?: string;
    previousEndDate?: string;

    temporaryUnbannedDate?: string;
    cityName?: string;
    registrationDate?: string;
    sexuality: number;
    premiumActivationDate?: string;
    premiumRenewCount: number;
    sessionNumber: number;
    isPushNotificationEnabled: boolean;
    discoveryCity: string
}

export const convertToSearchInstinctToModerationUser = (user: SearchUserPayloadInstinct) => {
    const photos: PhotoPayloadBloom[] = [
        ...(user.avatarV2 ? [{
            ...user.avatarV2,
            isPrivate: false,
            image: user.avatarV2.image?.map(
                i => ({...i, link: i.url} as ImagePayloadBloom),
            ),
        }] : []),
        ...user.photos.map(p => ({
            ...p,
            isPrivate: false,
            image: p.image.map(i => ({...i, link: i.url} as ImagePayloadBloom)),
        }))
    ];
    return {
        discoveryCity: user.discoveryCity,
        id: user.id,
        name: user.name,
        gender: user.gender?.toString(),
        sexuality: user.sexuality.toString(),
        about: user.about,
        distance: user.distance,
        age: user.age,
        birthday: new Date(user.birthday),
        onlineDate: new Date(user.onlineDate),
        interests: user.interests.map(i => i.tag),
        photos,
        status: user.status,
        moods: [],
        hasPremium: user.hasPremium,
        isBanned: user.isBanned,
        isPaused: user.isPaused,
        isVerified: user.isVerified,
        reportsCount: user.reportsCount,
        scamMessages: user.scamMessages,
        latitude: user.lat ?? user.latitude,
        longitude: user.long ?? user.longitude,
        countryCode: user.country ?? user.countryCode,
        ipCountryCode: user.ipCountryCode,
        displaysCount: toCount(user.displaysCount),
        likesCount: toCount(user.likesCount),
        chatsCount: toCount(user.chatsCount),
        outgoingDislikesCount: toCount(user.outgoingDislikesCount),
        outgoingLikesCount: toCount(user.outgoingLikesCount),
        verificationType: user.verificationType,
        startDate: user.startDate ? toDate(user.startDate, Apps.Instinct) : null,
        previousEndDate: user.previousEndDate ? toDate(user.previousEndDate, Apps.Instinct) : null,
        matchesCount: user.matchesCount,
        temporaryUnbannedDate: user.temporaryUnbannedDate ? toDate(user.temporaryUnbannedDate, Apps.Instinct) : undefined,
        city: user.cityName,
        registrationDate: user.registrationDate ? toDate(user.registrationDate, Apps.Instinct) : undefined,
        premiumStartDate: user.premiumActivationDate ? toDate(user.premiumActivationDate, Apps.Instinct) : undefined,
        renewCount: user.premiumRenewCount,
        sessionNumber: user.sessionNumber,
        isPushNotificationEnabled: user.isPushNotificationEnabled,
        ...convertCommonParts(user, Apps.Instinct)
    } as ModerationUser
}
