import InstinctPic1 from "assets/verification/instinct/pic1.jpg";
import InstinctPic2 from "assets/verification/instinct/pic2.jpg";
import FlavaPic1 from "assets/verification/flava/pic1.jpg";
import FlavaPic2 from "assets/verification/flava/pic2.jpg";
import FlavaPic3 from "assets/verification/flava/pic3.jpg";
import FlavaPic4 from "assets/verification/flava/pic4.jpg";
import FlavaPic5 from "assets/verification/flava/pic5.jpg";
import React, {FC, useContext} from "react";
import {VerificationUser} from "interfaces";
import {AuthContext} from "contexts";
import {Apps} from "enums";
import {useImagePreview} from "utils";

interface Props {
    verification: VerificationUser
}


export const VerificationImage: FC<Props> = ({verification}) => {
    const {app} = useContext(AuthContext);
    const imagePreview = useImagePreview();

    const getImage = () => {
        if (app === Apps.Instinct) {
            switch (verification.verificationType) {
                case  1:
                    return InstinctPic1;
                case  2:
                    return InstinctPic2;
            }
        } else if (app === Apps.Flava) {
            switch (verification.verificationType) {
                case  0:
                    return FlavaPic1;
                case  1:
                    return FlavaPic2;
                case  2:
                    return FlavaPic3;
                case  3:
                    return FlavaPic4;
                case  4:
                    return FlavaPic5;
            }
        } else {
            return null;
        }
    }

    const image = getImage()

    const openImagePreview = () => {
        imagePreview.open(verification.photo, false)
    }

    return <div className="d-flex flex-row rounded overflow-hidden" style={{gap: 10}}>
        <img
            src={verification.photo}
            alt="user pics"
            style={{objectFit: 'contain'}}
            width={250}
            height={360}
            onClick={openImagePreview}
            className="rounded bg-secondary"
        />
        {image &&
            <img
                src={image}
                alt="user pics"
                style={{objectFit: 'cover'}}
                width={250}
                height={360}
                className="rounded"
            />}
    </div>
}