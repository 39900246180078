import React, {useContext, useEffect, useState} from 'react';
import {PendingCountsByGender, userStatsService} from "services";
import {AuthContext} from "contexts";
import {GenderName, Genders} from "enums";


export const UserStatsPage = () => {
    const {env} = useContext(AuthContext);
    const [count, setCount] = useState<PendingCountsByGender | null>();

    useEffect(() => {
        if (!env) {
            return;
        }

        setCount(null)
        userStatsService.getPendingCount(env).then(setCount)
    }, [env])

    if (!count) {
        return <div>Загружается...</div>
    }

    return <div className="d-flex w-100 align-items-center flex-column mt-4 overflow-auto" style={{minWidth: 400}}>
        <table className="table table-hover" style={{width: '98%'}}>
            <thead>
            <tr className="table-dark">
                <th scope="col"></th>
                <th scope="col" className="text-lowercase text-capitalize">Всего</th>
                {count.countByGenders.map(g => <th key={g.gender}
                                                   className="text-lowercase text-capitalize">{GenderName[g.gender.toString() as Genders]}</th>)}
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row" className="fw-bold">Модерация</th>
                <th>{count.users}</th>
                {count.countByGenders.map(g => <th key={g.gender}>{g.users}</th>)}
            </tr>
            <tr>
                <th scope="row" className="text-lowercase text-capitalize">Временно разблокированные</th>
                <th>{count.temporaryUnbannedUsers}</th>
                {count.countByGenders.map(g => <th key={g.gender}>{g.temporaryUnbannedUsers}</th>)}
            </tr>
            <tr>
                <th scope="row" className="text-lowercase text-capitalize">Верификации</th>
                <th>{count.verifications}</th>
                {count.countByGenders.map(g => <th key={g.gender}>{g.verifications}</th>)}
            </tr>
            <tr>
                <th scope="row" className="text-lowercase text-capitalize">Премиум Верификации</th>
                <th>{count.premiumVerifications}</th>
                {count.countByGenders.map(g => <th key={g.gender}>{g.premiumVerifications}</th>)}
            </tr>
            </tbody>
        </table>
    </div>;
}