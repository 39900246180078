import React, {FC, useContext} from 'react';
import {ModerationUser} from "interfaces";
import {UserReviewTags, UserStatuses, VerificationStatus, VerificationStatusEmoju} from "enums";
import {toDateString, useImagePreview} from "utils";
import {Accordion, Button} from "react-bootstrap";
import {AuthContext} from "contexts";
import {ModerationHint} from "components/ModerationHint";
import {ModerationUserStatus} from "components/ModerationUserCard/components/ModerationUserInfo";
import {ModerationRow} from "components/ModerationRow";
import {VerificationRejectReasons, VerificationRejectReasonsLabels} from "enums/VerificationRejectReasons";
import {useVerificationProcessing} from "hooks/useVerificationProcessing";
import {getGuid} from "utils/getGuid";
import {
    ModerationVerificationPhoto
} from "components/ModerationUserCard/components/ModerationVerification/ModerationVerificationPhoto";

interface Props {
    user: ModerationUser
    onUserChange: (user: ModerationUser) => void;
    hideVerificationPhoto?: boolean;
}

export const ModerationVerification: FC<Props> = ({user, hideVerificationPhoto, onUserChange}) => {
    const {env, user: adminUser} = useContext(AuthContext);

    const {openVerifyDialog} = useVerificationProcessing(env, user)
    const imagePreview = useImagePreview();
    const multipleVerificationsTag = user.reviewTags?.find(t => t.tag === UserReviewTags.OnePersonInMultipleVerifications);
    const sameUsersGuides = multipleVerificationsTag?.info?.split('\n').filter(g => getGuid(g)).filter(g => g !== user?.id) ?? [];

    const onPicPreview = async (link?: string) => {
        if (!link) {
            return
        }
        imagePreview.open(link, false);
    }

    const verify = async (isAccepted: boolean) => {
        if (!env) {
            return;
        }
        const result = await openVerifyDialog(isAccepted)
        if (result.isSuccess) {
            user?.id && onUserChange({
                ...user,
                verificationRejectReason: result.reason,
                verificationStatus: isAccepted ? VerificationStatus.Verified : VerificationStatus.Denied,
                verifier: adminUser ? {
                    key: adminUser.id,
                    status: UserStatuses.Support,
                    name: adminUser.name
                } : undefined,
                isVerified: isAccepted
            })
        }
    };

    const hasCurrentVerification = !hideVerificationPhoto && user.verificationPhoto && user.verificationStatus !== VerificationStatus.None;
    const hasVerificationHistory = !!user.verificationHistory.length;

    if (!hasCurrentVerification && !hasVerificationHistory) {
        return null
    }

    return <>
        <hr style={{opacity: 0.1}} className="mx-2"/>
        <div className="small bg-light rounded mx-2 p-2">
            <h5>Верификация</h5>
            {hasCurrentVerification && user.verificationPhoto && <>
                <div className="d-flex flex-row">
                    <ModerationVerificationPhoto
                        link={user.verificationPhoto.link}
                        onClick={() => onPicPreview(user.verificationPhoto?.link)}
                    />
                    <div className="mx-3">
                        <ModerationRow label="Статус"
                                       value={VerificationStatusEmoju[user.verificationStatus]}
                                       isInsideTable/>
                        {user.verificationStartDate &&
                            <ModerationRow label="Отправлена"
                                           value={toDateString(user.verificationStartDate)}
                                           isInsideTable/>}
                        {user.verificationEndDate &&
                            <ModerationRow label="Пройдена"
                                           value={toDateString(user.verificationEndDate)}
                                           isInsideTable/>}
                        {user.verificationRejectReason && user.verificationStatus === VerificationStatus.Denied &&
                            <ModerationRow label="Причина"
                                           value={VerificationRejectReasonsLabels[user.verificationRejectReason] ?? user.verificationRejectReason}
                                           isInsideTable/>}
                        {user.verifier?.key && user.verificationStatus !== VerificationStatus.Pending &&
                            <ModerationRow label={"Верифицировал"}
                                           value={user.verifier?.key === user.id ? 'Автоматически' :
                                               <ModerationUserStatus value={user.verifier}/>}
                                           isInsideTable={true}/>}
                        <div className="mt-4">
                            {(user.verificationStatus === VerificationStatus.Pending || user.verificationStatus === VerificationStatus.Denied) &&
                                <><Button variant={'outline-success'}
                                          onClick={() => verify(true)}>Верифицировать</Button> <br/></>}
                            {(user.verificationStatus === VerificationStatus.Pending || user.verificationStatus === VerificationStatus.Verified) &&
                                <Button variant={'outline-danger'} onClick={() => verify(false)}
                                        className="mt-2">Отклонить</Button>}
                            {multipleVerificationsTag &&
                                <ModerationHint className={'alert-danger me-2'}>
                                    В системе верификаций найдены другие пользователи с этим лицом:
                                    <div>
                                        {sameUsersGuides.map((guid, i) =>
                                            <div key={guid + i}>
                                                {guid ? <a href={`profile/${guid}`} target="_blank"
                                                           rel="noreferrer">{guid}</a> : guid}
                                            </div>)
                                        }
                                    </div>
                                </ModerationHint>}
                        </div>
                    </div>
                </div>
            </>}
            {hasVerificationHistory &&
                <Accordion key={user.id} className="mt-2" flush>
                    <Accordion.Header>История верификаций
                        ({user.verificationHistory.length})</Accordion.Header>
                    <Accordion.Body>
                        {user.verificationHistory.map(h => (
                            <div key={h.verificationPhoto?.link} className="d-flex flex-row">
                                {h.verificationPhoto &&
                                    <ModerationVerificationPhoto
                                        link={h.verificationPhoto.link}
                                        onClick={() => onPicPreview(h.verificationPhoto?.link)}
                                    />}
                                <div className="mx-3">
                                    <ModerationRow label="Статус"
                                                   value={VerificationStatusEmoju[h.verificationStatus]}
                                                   isInsideTable/>
                                    {h.rejectReason && h.rejectReason !== VerificationRejectReasons.Unknown &&
                                        <ModerationRow label="Причина"
                                                       value={VerificationRejectReasonsLabels[h.rejectReason] ?? h.rejectReason}
                                                       isInsideTable/>}
                                    {h.verificationStartDate &&
                                        <ModerationRow label="Отправлена"
                                                       value={toDateString(h.verificationStartDate)}
                                                       isInsideTable/>}
                                    {h.verificationEndDate &&
                                        <ModerationRow label="Пройдена"
                                                       value={toDateString(h.verificationEndDate)}
                                                       isInsideTable/>}
                                    {h.verifier &&
                                        <ModerationRow label={"Верифицировал"}
                                                       value={h.verifier.key === user.id ? 'Автоматически' :
                                                           <ModerationUserStatus value={h.verifier}/>}
                                                       isInsideTable={true}/>}
                                </div>
                            </div>
                        ))}
                    </Accordion.Body>
                </Accordion>}
        </div>
    </>
};
