import React, {useCallback, useEffect, useState} from "react";

import type {IAfterGuiAttachedParams, IDoesFilterPassParams,} from "ag-grid-community";
import type {CustomFilterProps} from "ag-grid-react";
import {useGridFilter} from "ag-grid-react";
import {Button} from "react-bootstrap";

export const AbuseWordsLanguageFilter = ({model, onModelChange, api}: CustomFilterProps) => {
    const [closeFilter, setCloseFilter] = useState<(() => void) | undefined>();
    const [selectedValues, setSelectedValues] = useState<string[] | null>(model);
    const [allValues, setAllValues] = useState<string[]>([]);

    const doesFilterPass = useCallback((params: IDoesFilterPassParams) => {
        return !!selectedValues && selectedValues.some(item => params.data.language === item);
    }, [selectedValues]);

    const afterGuiAttached = useCallback(
        ({hidePopup}: IAfterGuiAttachedParams) => {
            setCloseFilter(() => hidePopup);
        },
        [],
    );

    // register filter handlers with the grid
    useGridFilter({
        doesFilterPass,
        afterGuiAttached,
    });

    useEffect(() => {
        setSelectedValues(model ?? []);
    }, [model]);

    useEffect(() => {
        const rowData: string[] = [];
        api.forEachNode((node) => {
            if (node.data?.language) {
                rowData.push(node.data.language);
            }
        });
        setAllValues([...new Set(rowData)])
    }, [api]);


    const onClick = () => {
        if (closeFilter) {
            closeFilter();
        }
    };

    const sortedValues = allValues.sort((a, b) => a.toString().localeCompare(b.toString()));
    return (
        <div className="p-3 d-flex flex-column bg-white">
            <label className={'fw-bold'}>Языки</label>
            <div className="col-sm-10 mt-2">
                {sortedValues.map(v =>
                    <div key={v} className="form-check">
                        <input className="form-check-input"
                               type="checkbox" value="" id={v}
                               checked={selectedValues?.includes(v)}
                               onChange={() => {
                                   const newValue = selectedValues?.includes(v) ? selectedValues.filter(pg => pg !== v) : [...(selectedValues ?? []), v];
                                   const agNewValue = newValue.length ? newValue : null;
                                   setSelectedValues(agNewValue);
                                   onModelChange(agNewValue);
                               }}/>
                        <span className="badge bg-secondary">{v}</span>
                    </div>)}
            </div>
            <Button size={'sm'} variant={'secondary'} onClick={onClick} className={'mt-2'}>Готово</Button>
        </div>
    );
};