import React, {FC} from 'react';
import {ModerationHint, ModerationRow} from "components";
import {Device, ModerationUser} from "interfaces";
import {ModerationSection} from "components/ModerationSection";
import {toDateString} from "utils";
import {Genders} from "enums";

interface Props {
    user: ModerationUser
}

const isOlderThaniPhoneX = (deviceType: string) => {
    const regex = /^iPhone\s(\d+)/;
    const match = deviceType.match(regex);

    if (match) {
        const modelNumber = parseInt(match[1], 10);
        return modelNumber <= 8;
    }

    return false;
}

const isOlderThanVersion16 = (os: string) => {
    const [major] = os.split('.').map(Number);
    return major < 16;
}

export const ModerationDev: FC<Props> = ({user}) => {
    const devices = user.devices;

    const hasDeviceWarning = (device: Device) => {
        return user.platform === 'IOS' && isOlderThanVersion16(device.os) && device.carrier === 'Unknown' && isOlderThaniPhoneX(device.deviceType)
    }

    const hasCarrierWarning = (device: Device) => {
        const isFemale = user.gender === Genders.Female;
        if (!isFemale) {
            return false;
        }

        return ['ng', 'ci', 'gh', 'ma', 'Unknown', 'MTN', 'MegaFon', 'MOOV AFRICA BJ', 'MTN Nigeria', 'MTN Benin', 'cellcard', 'MTN NG', 'MOOV BENIN', 'Airtel Nigeria', 'Metfone', 'EE'].findIndex(v => v === device.carrier) >= 0
    }

    return <ModerationSection label="Приложение">
        <div className="row">
            <div className="col-12 col-xl-6">
                <ModerationRow label="Сессий" value={user.sessionNumber} isInsideTable/>
                <ModerationRow label="Версия" value={user.version} isInsideTable/>
                <ModerationRow label="Платформа" value={user.platform} isInsideTable/>
                <ModerationRow label="Логин" value={user.loginMethods?.join(', ')} isInsideTable/>
                <ModerationRow label="Нотификации" value={user.isPushNotificationEnabled ? 'Включены' : '-'}
                               isInsideTable/>
            </div>
            {devices && devices.length > 0 &&
                <div className="col-12 col-xl-6">
                    <div style={{fontWeight: 500}}>Утройства</div>
                    {devices.map((d, i) => {
                            const hasSuspiciousDeviceWarning = hasDeviceWarning(d.device);
                            const hasCarrierCountryWarning = hasCarrierWarning(d.device);
                            return <div key={d.device.deviceId + i}>
                                <ModerationRow
                                    label={d.lastActivityDate ? `${i + 1}. ${toDateString(d.lastActivityDate, false)}` : `(${i + 1})`}
                                    value={`${d.device.deviceType} (OS: ${d.device.os || '-'}, Сеть: ${d.device.carrier || '-'}, Локаль: ${d.device.regionCode || '-'})`}
                                    isInsideTable/>
                                {(hasSuspiciousDeviceWarning || hasCarrierCountryWarning) &&
                                    <ModerationHint>
                                        {hasSuspiciousDeviceWarning &&
                                            <div>Подозрительный девайс: старый, без сети и старая ось</div>}
                                        {hasCarrierCountryWarning &&
                                            <div>Подозрительная сеть <span className="fw-bold">{d.device.carrier}</span>:
                                                отсюда много скамеров
                                            </div>}
                                    </ModerationHint>}

                            </div>
                        }
                    )}
                </div>
            }
        </div>
    </ModerationSection>
};

