import React, {FC} from 'react';
import {getPhotoInfoFromTag, isReviewTagSuspest, UserReviewTags} from "enums";
import {ModerationUserTag} from "interfaces";
import {
    ModerationPhotoTag
} from "components/ModerationUserCard/ModerationPhotos/ModerationPhotoTags/ModerationPhotoTag";


interface Props {
    reviewTags?: ModerationUserTag[]
    isSensitivePhoto: boolean
    textClassName?: string
}


export const ModerationPhotoTags: FC<Props> = ({reviewTags, isSensitivePhoto, textClassName}) => {
    const photoReviewInfos = reviewTags?.map(tag => ({
        tag: tag.tag,
        info: getPhotoInfoFromTag(tag.info ?? '', tag.tag)?.info
    }) ?? []).filter(t => isReviewTagSuspest(t.tag))
    const sensitiveTags = photoReviewInfos?.filter(t => t.tag === UserReviewTags.SensitivePhoto) ?? [];

    if (!photoReviewInfos) {
        return <></>
    }

    return <div className="d-flex flex-column align-items-start w-100 flex-wrap" style={{gap: 2}}>
        {photoReviewInfos.filter(t => t.tag !== UserReviewTags.SensitivePhoto).map((t, i) =>
            <ModerationPhotoTag key={t + i.toString()} tag={t} isSensitivePhoto={isSensitivePhoto}
                                textClassName={textClassName}/>
        )}
        {sensitiveTags.map((t, i) =>
            <ModerationPhotoTag key={t + i.toString()} tag={t} isSensitivePhoto={isSensitivePhoto}
                                textClassName={textClassName}/>
        )}
        {!sensitiveTags.length && isSensitivePhoto &&
            <ModerationPhotoTag tag={{tag: UserReviewTags.SensitivePhoto, info: 'Ручное проставление'}}
                                isSensitivePhoto={isSensitivePhoto}
                                textClassName={textClassName}/>}
    </div>
};


