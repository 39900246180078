import React, {FC, useContext, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import {AuthContext} from "contexts";
import {adminsService} from "services/adminsService";
import {Loader} from "components/Loader";

interface Props {
    onAdminCreated: () => void
}

export const CreateAdminButton: FC<Props> = ({onAdminCreated}) => {
    const {app} = useContext(AuthContext);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [name, setName] = useState('');


    const handleClose = () => {
        setName('');
        setIsModalVisible(false);
    }

    const handleCreateAdmin = async () => {
        if (!app) {
            return;
        }
        setIsCreating(true)
        const createdUser = await adminsService.create(name, app)
        if (createdUser) {
            onAdminCreated();
            handleClose();
        }
        setIsCreating(false)
    }

    if (!app) {
        return null;
    }

    return <>
        <Button
            variant="primary"
            className="w-100 mt-3"
            onClick={() => setIsModalVisible(true)}>
            Создать Администратора
        </Button>
        <Modal show={isModalVisible} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Создать Администратора</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Имя</Form.Label>
                        <Form.Control placeholder="Имя Ф." onChange={e => setName(e.target.value)}/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Закрыть
                </Button>
                <Button disabled={!name || isCreating} variant="primary" onClick={handleCreateAdmin}>
                    <Loader isLoading={isCreating}/>
                    Создать
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

