import React, {FC} from 'react';
import {ModerationUser} from "interfaces";
import {ModerationHint} from "components";
import {UserStatuses} from "enums";
import {StatusHistory} from "components/ModerationUserCard/components/StatusHistory";
import {ModerationSection} from "components/ModerationSection";

interface Props {
    user: ModerationUser
}

export const ModerationStatus: FC<Props> = ({user}) => {
    const wasBanned = user.statusHistory?.find(h => h.status === UserStatuses.Banned);
    const wasSuspecious = user.statusHistory?.find(h => h.status === UserStatuses.Suspicious);
    return <ModerationSection label="Статусы">
        {wasBanned && <ModerationHint>Пользователь уже был в бане</ModerationHint>}
        {wasSuspecious &&
            <ModerationHint className={'alert-warning'}>Пользователь уже был под подозрением</ModerationHint>}
        <StatusHistory user={user}/>
    </ModerationSection>

}
