import React, {FC} from "react";
import {AiOutlineLeft, AiOutlineRight} from "react-icons/ai";

interface Props {
    page: number;
    hasMore: boolean;
    onNext: () => void;
    onPrevious: () => void;
}

export const Pager: FC<Props> = ({page, hasMore, onNext, onPrevious}) => {
    return <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
        <div className="btn-group me-2" role="group" aria-label="First group">
            <button type="button" className="btn btn-secondary" disabled={page === 0} onClick={onPrevious}>
                <AiOutlineLeft className={page === 0 ? "opacity-25" : ""}/>
            </button>
            <button type="button" className="btn btn-secondary">{page + 1}</button>
            <button type="button" className="btn btn-secondary" disabled={!hasMore} onClick={() => onNext()}>
                <AiOutlineRight className={hasMore ? "" : "opacity-25"}/>
            </button>
        </div>
    </div>
}