import React from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useWindowDimensions} from "hooks";

const MySwal = withReactContent(Swal)

export const useImagePreview = () => {
    const {height: wHeight, width} = useWindowDimensions();

    const open = (url: string, canDelete: boolean) => {
        return MySwal.fire({
            html: <img
                src={url}
                alt={`Pic ${url}`}
                style={{objectFit: 'scale-down'}}
                height={wHeight - 550}
            />,
            title: canDelete ? 'Удалить фотографию?' : 'Просмотр',
            confirmButtonText: 'Да',
            confirmButtonColor: '#D9292E',
            cancelButtonText: canDelete ? 'Нет' : 'Закрыть',
            showCancelButton: true,
            showConfirmButton: canDelete,
            width: width > 1000 ? width * 0.5 : width * 0.8
        })
    }

    return {open}
}