import {Apps, Genders} from "enums";
import {request} from "./request";
import {EnvSetup, getSupportUrl} from "enums/Envs";


interface FiltersPayloadAll {
    genders: number[]
    sexes: Genders[]
    sex?: Genders
}


interface Filters {
    genders: Genders[]
}

interface BlockedCountryPayload {
    countryCode: string,
    name: string
}

export interface BlockedCountriesPayload {
    countries: BlockedCountryPayload[]
}

const getFiltersLink = (app: Apps) => {
    switch (app) {
        case Apps.Instinct:
            return 'setup'
        case Apps.Bloom:
        case Apps.Flava:
            return 'filters'
        case Apps.Spark:
            return 'preferences'
    }
}

export const setupServices = {
    get: async (app: Apps) => {
        const response = await request<FiltersPayloadAll>({
            url: getFiltersLink(app),
            method: 'GET',
        });

        if (response?.sexes) {
            return {...response, genders: response?.sexes.map(s => s.toString() as Genders) ?? []} as Filters;
        } else if (response?.sex) {
            return {...response, genders: [response.sex.toString() as Genders]} as Filters;
        } else {
            return {...response, genders: response?.genders?.map(s => s.toString() as Genders) ?? []} as Filters;
        }
    },
    save: async (app: Apps, genders: Genders[], filters: any) => {
        await request<Filters>({
            url: getFiltersLink(app),
            method: 'PUT',
            data: {
                ...filters,
                genders: genders.map(g => +g),
                gender: +genders[0],
                sex: +genders[0],
                sexes: genders.map(g => +g),
            }
        });
    },
    getBlockedCountries: async (env: EnvSetup) => {
        return await request<BlockedCountriesPayload>({
            url: `/${getSupportUrl(env)}/countries/blocked`,
            method: 'GET',
        });
    },
    addBlockedCounty: async (env: EnvSetup, countryCode: string) => {
        return await request({
            url: `/${getSupportUrl(env)}/countries/blocked?countryCode=${countryCode}`,
            method: 'PUT',
            //data: {countryCode}
        });
    },
    deleteBlockedCounty: async (env: EnvSetup, countryCode: string) => {
        return await request({
            url: `/${getSupportUrl(env)}/countries/blocked?countryCode=${countryCode}`,
            method: 'DELETE'
        });
    },
    getAllReviewTags: async (env: EnvSetup) => {
        return await request<string[]>({
            url: `/${getSupportUrl(env)}/reviewTags`,
            method: 'GET',
        });
    },
}