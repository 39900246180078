import React, {FC} from 'react';
import {Form} from 'react-bootstrap';
import {DateTime} from 'luxon';

interface DatePickerProps {
  value: Date;
  label: string;
  type?: 'form' | 'control';
  onChange: (d: Date) => void;
}

export const DatePicker: FC<DatePickerProps> = ({
  label,
  value,
  type = 'control',
  onChange,
}) => {
  const defaultValue = DateTime.fromJSDate(new Date(value))
    .toFormat('yyyy-MM-dd')
    .toString();

  const control = (
    <Form.Control
      type="date"
      placeholder="Дата"
      defaultValue={defaultValue}
      onChange={e => {
        e.target.value && onChange(new Date(e.target.value));
      }}
    />
  );

  if (type === 'control') {
    return control;
  }

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      {control}
    </Form.Group>
  );
};
