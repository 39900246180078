import {Dropdown, DropdownButton} from 'react-bootstrap';
import {Apps} from 'enums';
import {FC} from 'react';

interface AppDropdownProps {
    app: Apps;
    onSelect: (app: Apps) => void;
}

export const AppDropdown: FC<AppDropdownProps> = ({app, onSelect}) => {
    return (
        <DropdownButton
            title={app}
            variant="light"
            className="w-100"
            defaultValue={app}
            onSelect={e => onSelect(e as Apps)}>
            {Object.values(Apps).map(a => (
                <Dropdown.Item eventKey={a} key={a}>
                    {a}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
};
