import React, {FC, useContext, useEffect, useRef, useState} from "react";
import {ModerationUserCard} from "components";
import {AuthContext} from "contexts";
import {tempUnbannedService, usersService} from "services";
import {ModerationUser} from "interfaces";
import {Button} from "react-bootstrap";
import {EventsPerModerationSource, ModerationSources} from "enums/ModerationSources";
import {eventTracker, ReviewTypes} from "utils/eventTracker";

export const TempUnbannedPage: FC = () => {
    const hasMore = useRef(true);

    const {env} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [index, setIndex] = useState(0)
    const [users, setUsers] = useState<ModerationUser[]>([]);

    const user = users[index];

    const next = async () => {
        if (!env) {
            return;
        }

        setIndex(i => i + 1)
        if (index <= users.length - 3 && hasMore.current) {
            setIsLoading(true);
            const newUsers = await tempUnbannedService.getPage(
                env,
                users.length
            );
            hasMore.current = !!newUsers?.length && newUsers.length > 20;
            setUsers(prev => [...prev, ...(newUsers ?? [])]);
            setIsLoading(false);
        }
    };

    const unblock = async () => {
        if (!env) {
            return;
        }
        eventTracker.track(EventsPerModerationSource[ModerationSources.TempUnbanned], eventTracker.getReviewParams(user, ReviewTypes.Unban, ModerationSources.TempUnbanned))
        user?.id && (await usersService.unban(user?.id, env));
        setUsers(us => us.filter(u => u.id !== user?.id))
    };

    useEffect(() => {
        if (env) {
            setIsLoading(true);
            setTimeout(async () => {
                const newUsers = await tempUnbannedService.getPage(env, 0);
                newUsers && setUsers(newUsers);
                setIsLoading(false);
            }, 300);
        }
    }, [env]);

    return user ? (
        <ModerationUserCard
            user={user}
            greenButton={{text: 'Разблокировать', onClick: unblock}}
            buttons={<Button
                onClick={next}
                variant="light"
                className="mx-1">
                Следующий
            </Button>}
            onUserChange={u => u.isBanned ? next() : undefined}
            moderationSource={ModerationSources.TempUnbanned}
        />
    ) : (
        <div className="w-100 align-items-center">
            {isLoading ? 'Загружается...' : 'Это все'}
        </div>
    );
}