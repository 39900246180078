import {Events} from "utils/eventTracker";

export enum ModerationSources {
    Moderation = "moderation",
    Verification = "verification",
    Audit = "audit",
    TempUnbanned = 'temp_unbanned',
    Profile = 'profile'
}

export const EventsPerModerationSource: Record<ModerationSources, string> = {
    [ModerationSources.Moderation]: Events.review,
    [ModerationSources.Verification]: Events.review,
    [ModerationSources.Audit]: Events.audit,
    [ModerationSources.TempUnbanned]: Events.review,
    [ModerationSources.Profile]: Events.review,
};