import React, {FC} from "react";
import {ModerationUser, ModerationUserTag} from "interfaces";
import {UserReviewTags, UserStatuses} from "enums";
import {UserStateChangeReason} from "enums/UserStatusChangeReason";
import Highlighter from "react-highlight-words";

interface Props {
    user: ModerationUser
}

export const AboutInTable: FC<Props> = ({user: u}) => {
    if (u.status !== UserStatuses.Banned) {
        return <>{u.about}</>;
    }

    const lastStatusChangeReason = u.statusHistory && u.statusHistory.length > 0 && u.statusHistory[u.statusHistory.length - 1].reason;
    const wasBannedBecauseOfAbout = !u.about && lastStatusChangeReason === UserStateChangeReason.DetectAbuseInAbout;


    let aboutAbuseReviewTag: ModerationUserTag | undefined = u.reviewTags.find(r => {
        const infoParts = r.info?.split(', text: ') ?? [];
        return infoParts.length > 0 ? infoParts[1] === u.about : false;
    });

    if (!aboutAbuseReviewTag && wasBannedBecauseOfAbout) {
        const aboutTags = u.reviewTags.filter(t => t.tag === UserReviewTags.BannedAbout)
        aboutAbuseReviewTag = aboutTags.length ? aboutTags[aboutTags.length - 1] : undefined;
    }

    const parts = aboutAbuseReviewTag?.info ? aboutAbuseReviewTag.info.split(', text:') : []
    const abuseReason = parts && parts.length > 0 ? parts[0].replace('Abuse reason: ', '') : ''
    const abuseText = parts && parts.length > 0 ? parts[1] : ''

    return <>
        {abuseReason &&
            <div className="mb-2">
                Abuse Reason: <span className="fw-bold">{abuseReason}:</span>
            </div>}
        <Highlighter
            highlightClassName="bg-white fw-bold"
            searchWords={[abuseReason]}
            autoEscape={true}
            textToHighlight={abuseText ? abuseText : u.about}
        />
    </>
}